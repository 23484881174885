import React, { useEffect, useState } from "react";
import {Alert, Button, Icon, Spacer, Theme, Toggle, RadioBox } from "@dspworkplace/ui";
import { Action } from "../../Common/UI";
import { useAuth, getCompanyId, getUserId } from "../../../../Auth";
import Tooltip from "../../../../components/Tooltip/tooltip";
import Loading from "../../../../components/Loading";
import Modal from "../../../../components/Modal";
import { dialogPromise } from "../../../../components/Dialog";
import { toast } from "../../../../components/Toasts";

const SelectForm = ({ success, cancel, isCurrentWeek}) => {
    const [copyOption1, setCopyOption1] = useState(true);
    const [copyOption2, setCopyOption2] = useState(false);
    const [selectedCopyOption, setSelectedCopyOption] = useState(null)
    const [postAsUnpublish, setPostAsUnpublish] = useState(true)
    const [copyShiftShowFlag, setCopyShiftShowFlag] = useState(1);
    const [copyUnpublishedChanges, setCopyUnpublishedChanges] = useState(false);
    const copyHandler = () => {
        success({selectedCopyOption, postAsUnpublish, copyShiftShowFlag, copyUnpublishedChanges})
    }

    useEffect(()=>{
        if(copyOption1 === true){
            setSelectedCopyOption("1");
            setCopyOption2(false);
        }
    },[copyOption1]);

    useEffect(()=>{
        if(copyOption2 === true){
            setSelectedCopyOption("2");
            setCopyOption1(false);
        }
    },[copyOption2]); 

    useEffect(()=>{
        if(copyOption1 === false && copyOption2 === false){
            setSelectedCopyOption(null);
        }
    },[copyOption1, copyOption2]);

    return (
        <Modal width={"700px"} visible={true} title="Copy Previous Week Options" closeOnOverlay={false} setVisible={cancel}>
            <Spacer bottom={2}>
                    <Toggle
                        name='copyUnpublishedChanges'
                        singleText='Copy Unpublished Changes'
                        on={copyUnpublishedChanges}
                        onChange={() => setCopyUnpublishedChanges(!copyUnpublishedChanges)}
                    />
                    <Spacer bottom={2}/>
                    <Toggle
                        name='postAsUnpublish'
                        singleText='Post but do not publish'
                        on={postAsUnpublish}
                        onChange={() => setPostAsUnpublish(!postAsUnpublish)}
                    />
                    <Spacer bottom={2}/>
                    <Toggle
                        on={copyOption1}
                        singleText='Copy but do not overwrite conflicts'
                        onChange={() => setCopyOption1(!copyOption1)}
                        help={<div>
                            This option will replicate the prior week’s schedule, but will not overwrite any already published shifts.
                        </div>}
                    />
                    <Spacer bottom={2}/>
                    {isCurrentWeek == false &&
                    <Toggle
                        on={copyOption2}
                        singleText='Copy and ignore conflicts'
                        onChange={() => setCopyOption2(!copyOption2) }
                        help={<div>
                        This option will replicate the prior week’s schedule entirely and overwrite any existing published shifts--including any overtime.
                    </div>}
                    />}
                    <Spacer bottom={2} />
                    <RadioBox
                        name="copy_shift_show_flag"
                        options={[
                            { label: "Copy shift as shown", value: "1" },
                            { label: "Copy but restore to original shift type", value: "2" },
                        ]}
                        default={copyShiftShowFlag}
                        onChange={(e) => setCopyShiftShowFlag(e.target?.value)}
                        Required={true}
                    />
                </Spacer>
            {selectedCopyOption !== null && <Button type={"primary"} Type={"button"} onClick={copyHandler}>Copy</Button>}
        </Modal>
    );
};
 
 const openSelectForm = (isCurrentWeek) => {
     return dialogPromise((success, cancel)=>{
         return <SelectForm
             success={success}
             cancel={cancel}
             isCurrentWeek={isCurrentWeek}
         />
     })
 }

const CopyPreviousWeekShifts= ({week,year,isCurrentWeek,stationId, ...props}) =>{
    const [loading, setLoading] = useState(false);
    const {api} = useAuth();

    const clickHandler = async () => {
        setLoading(true);
        try{
            const formRes = await openSelectForm(isCurrentWeek);
            if(formRes){
                const params = {
                    actions: {
                        response: {
                            DriverRoute: {
                                custom: {
                                    functionName: "callCopyPreviousWeekShiftsWorker",
                                    get: "copyPreviousWeekShifts",
                                    excludes: [],
                                    criteria: {
                                        week: week,
                                        year:year,
                                        companyId: parseInt(getCompanyId()),
                                        userId: parseInt(getUserId()),
                                        copyOption:formRes.selectedCopyOption,
                                        stationId:parseInt(stationId),
                                        postAsUnpublish:formRes.postAsUnpublish,
                                        copyShiftShowFlag: formRes.copyShiftShowFlag,
                                        copyUnpublishedChanges: formRes.copyUnpublishedChanges,
                                    },
                                },
                            },
                        },
                    },
                };
                const response = await api.post("/api/lazy/manage/data", params);
                toast({
                    type: "info",
                    title: "Copying previous week shifts…",
                    content: 'Please allow up to 15 minutes to copy and be visible in the Scheduler. We will notify you once the copy of shifts be completed.'
                });
            }
        }catch(e){
            console.log(e.message);
        }
        setLoading(false);
        return true;
    }

    return (
        <Action
            type="button"
            disabled={loading}
        >
            <Spacer left={1} right={1}>
                {loading ? (
                    <Loading style={{width: 20}}/>
                ) : (
                    <Tooltip direction="bottom-start" content={<div>Copy previous week's shifts</div>}>
                        <Icon.Copy size="25px" color={Theme.colors.secondary} onClick={clickHandler} />
                    </Tooltip>
                )}
            </Spacer>
        </Action>
    );
}

export default CopyPreviousWeekShifts;