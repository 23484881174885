import React,{useCallback,useState,forwardRef, useEffect} from 'react';
import styled from 'styled-components';
import { DndProvider } from "react-dnd";
import MultiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";

import {ScrollyOverlayStyle,ScrollyModalStyle} from "../../../../components/Modal";
import {Button, Input, Spacer, SubTitle, ThirdTitle, Icon,CustomDropdown,Text} from "@dspworkplace/ui";
import ReactDOM from "react-dom";
import {useForm} from "react-hook-form";

import {SlideEditor,plugins} from "./Editor";
import {SlideDraggable} from './drag';
import {API} from './apiHandler';
import APITemplate from '../Templates/api';

import Loading, {LoadingWrapper} from "../../../../components/Loading";

import IphoneMock from '../../../../assets/SamsumgMock.png';

import Editor, {composeDecorators} from 'draft-js-plugins-editor';
import { EditorState, convertFromRaw } from "draft-js";
import {Table, TableData, TableFooter, TableHeader, TableRow} from "../../../../components/Table";
import editorStyle from "./Editor/style/editor.module.css";

const modalRoot = document.getElementById('modal-root');

const Slide = ({slide,success,cancel,metricVideo}) => {
    const [state,setState] = useState({status:slide && slide.id ? 'pending' : 'ready'});
    const [update, setUpdate] = useState(false);
    const {register,handleSubmit,errors,reset,getValues} = useForm({
        defaultValues: !slide ? {} : {
            title:slide.name,
            isRequired:slide.isRequired > 0 ? slide.isRequired : null,
            content:slide.content
        }
    });

    const form = getValues();

    const onSubmit = (data) => {
        if(!data.isRequired) data.isRequired = -1;
        data.isRequired = parseInt(data.isRequired);
        success(data);
    }

    useEffect( () => {
        if(slide && slide.id && !slide.name){
            API.fetchSlide(slide.id).then(
                resolve => {
                    const slide = resolve.data.data.AnncSlide;
                    reset({
                        title:slide.name,
                        isRequired:slide.isRequired,
                        content:slide.content
                    })
                    setState({...state,status:'ready'})
                },
                cancel => {

                }
            )
        }else{
            setState({...state,status:'ready'})
        }

    },[slide])

    return(
        <ScrollyOverlayStyle
          zIndex={99}
        >
            <ScrollyModalStyle width={'440px'} onClick={e => e.stopPropagation()}>
                <header>
                    <SubTitle>{slide ? 'Edit Slide' : 'Create Slide'}</SubTitle>
                    <Button type="cancel" onClick={cancel}>
                        Cancel
                    </Button>
                </header>
                {state.status === 'pending' ?
                    <LoadingWrapper>
                        <Loading style={{height: 80}}/>
                    </LoadingWrapper>
                    :
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Spacer top={4}>
                            <Input
                                name={'title'}
                                label={'Title'}
                                ref={register({required:'Field Required'})}
                                error={errors.title && errors.title.message}
                            />
                        </Spacer>
                        <Spacer top={4} bottom={4}>
                            <Input
                                type={'number'}
                                name={'isRequired'}
                                ref={register}
                                label={'Required reading time (In seconds)'}
                                size={'small'}
                            />
                        </Spacer>
                        <div style={{display:'flex'}}>
                            <SlideEditor ref={register} name={'content'} width={'750px'} defaultValue={form.content} metricVideo={metricVideo}  update={update}/>
                        </div>
                        <Spacer top={5} style={{display:'flex',justifyContent:'flex-end'}}>
                            <Button
                                // style={{float:'right'}}
                                type={'primary'}
                                Type={'submit'}
                                onClick={() => {setUpdate(true);}}
                            >
                                {slide ?  'Update Slide' : 'Create Slide'}
                            </Button>
                        </Spacer>
                    </form>
                </div>}
            </ScrollyModalStyle>
        </ScrollyOverlayStyle>
    )
}


const SlideList = forwardRef(({name,data,includeTemplate=false,metricVideo},ref) => {
    if(data){
        data = data.sort((a,b)=> a.slideOrder - b.slideOrder);
    }
    const [slides,setSlide] = useState(data||[]);
    if(!name) name = 'slides';

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        const dragCard = slides[dragIndex];
        const copySlide = [...slides];
        copySlide.splice(dragIndex,1);
        copySlide.splice(hoverIndex,0,dragCard);
        setSlide(copySlide);

    }, [slides]);

    return (
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
            <ThirdTitle>Slides</ThirdTitle>
            <Table width={'100%'}>
                <TableHeader
                    headers={[
                        {label: 'Name', width: '80%'},
                        {label: '', width: '20%'},
                ]} />

                {slides.map((s,k)=> (
                    <SlideDraggable key={k} index={k} moveCard={moveCard}>{({ dragRef, previewRef,opacity,isOverCurrent }) => (
                        <TableRow ref={previewRef} style={{flexWrap: 'wrap',display: s.delete ? 'none' : 'inline-flex',opacity,background:isOverCurrent ? '#f3f3f3' : 'inherit'}}>
                            <TableData width='80%'>
                                <div ref={dragRef} style={{width:16,cursor:'pointer',marginRight:8}}>
                                    <Icon.Move size={'16px'} />
                                </div>
                                <span>{includeTemplate ? s.name : s.name}</span>
                            </TableData>
                            <TableData width='20%' style={{justifyContent:s.template ? 'flex-end': 'space-between'}}>
                                {!s.template && <Icon.Edit
                                    size={'20px'}
                                    style={{cursor:'pointer'}}
                                    onClick={()=>{
                                        openSlideForm(s, metricVideo).then(
                                            resolve => {
                                                if(resolve){
                                                    const newSlides = [...slides];
                                                    newSlides[k] = {id:s.id ? s.id : "",name:resolve.title,slideOrder:k,content:resolve.content,isRequired:resolve.isRequired};
                                                    setSlide(newSlides);
                                                }
                                            }
                                        )
                                    }}
                                />}

                                <Icon.Times
                                    size={'20px'}
                                    style={{cursor:'pointer'}}
                                    onClick={()=>{
                                        const newSlides = [...slides];
                                        if(s.id || s.template){
                                            newSlides[k]['delete'] =  true;
                                        }else{
                                            newSlides.splice(k,1);
                                        }
                                        setSlide(newSlides);
                                    }}
                                />
                            </TableData>
                            {s.template
                                ?
                                <>
                                    <input type={'hidden'} name={`${name}[${k}].content`} defaultValue={""} ref={ref} />
                                    <input type={'hidden'} name={`${name}[${k}].name`} defaultValue={""} ref={ref} />
                                    <input type={'hidden'} name={`${name}[${k}].id`} defaultValue={""} ref={ref} />
                                    <input type={'hidden'} name={`${name}[${k}].isRequired`} defaultValue={""} ref={ref} />
                                    <input type={'hidden'} name={`${name}[${k}].delete`} defaultValue={s.delete ? true : false} ref={ref} />
                                    <input type={'hidden'} name={`${name}[${k}].template`} defaultValue={s.template} ref={ref} />
                                    <input type={'hidden'} name={`${name}[${k}].isNew`} defaultValue={s.isNew ? true : false} ref={ref} />
                                </>
                                :
                                <>
                                    <input type={'hidden'} name={`${name}[${k}].content`} defaultValue={s.content} ref={ref} />
                                    <input type={'hidden'} name={`${name}[${k}].name`} defaultValue={s.name} ref={ref} />
                                    <input type={'hidden'} name={`${name}[${k}].id`} defaultValue={s.id||""} ref={ref} />
                                    <input type={'hidden'} name={`${name}[${k}].isRequired`} defaultValue={s.isRequired  ? s.isRequired : -1} ref={ref} />
                                    <input type={'hidden'} name={`${name}[${k}].delete`} defaultValue={s.delete ? true : false} ref={ref} />
                                    <input type={'hidden'} name={`${name}[${k}].template`} defaultValue={""} ref={ref} />
                                    <input type={'hidden'} name={`${name}[${k}].isNew`} defaultValue={""} ref={ref} />
                                </>
                            }
                        </TableRow>
                    )}
                    </SlideDraggable>
                ))}
                <TableFooter sticky={true} style={{justifyContent:'flex-end'}}>
                    <Button
                        size={'small'}
                        Type={'button'}
                        onClick={()=>{
                            openSlideForm({}, metricVideo).then(
                                resolve => {
                                    if(resolve){
                                        setSlide([...slides,{content:resolve.content,name:resolve.title,isRequired:resolve.isRequired,slideOrder:slides.length + 1}])
                                    }
                                }
                            )}}
                    >
                        Add Slide
                    </Button>
                    {includeTemplate &&
                        <Button
                            Type={'button'}
                            size={'small'}
                            style={{marginLeft:8}}
                            onClick={()=>{
                                const templatesId = slides.reduce((array,s) => {
                                    if(s.template){
                                        array.push(s.template);
                                    }
                                    return array;
                                },[]);
                                openSlideTemplateForm(templatesId).then(
                                    resolve => {
                                        if(resolve){
                                            const newSlides = resolve.map((s,k)=>({name:s.name,slideOrder:slides.length + (k + 1),template:s.template,isNew:true}));
                                            setSlide([...slides,...newSlides])
                                        }
                                    }
                                )
                            }}
                        >Add Slide Template</Button>
                    }
                </TableFooter>
            </Table>
        </DndProvider>
    )
})

const openSlideForm = (slide, metricVideo = []) => {
    return portal((success, cancel)=>{
        return <Slide
            success={success}
            cancel={cancel}
            slide={slide}
            metricVideo={metricVideo}
        />
    })
}

const SlideTemplateForm = ({defaultValues,success,cancel}) => {
    const [state,setState] = useState({templates:[],status:'pending'});
    console.log(state);
    const {register,handleSubmit,errors} = useForm();

    const onSubmit = (data) => {
        console.log(data);
        if(data.templates.length < 1) return;
        let templates = state.templates.filter((t,k)=> data.templates.includes(t.value.toString()) ? t : false);
        const templateSlide = templates.map((t)=>({
            name:t.name,
            template:t.value
        }));

        success(templateSlide);
    }

    useEffect(()=>{
        APITemplate.fetchTemplatesWithSlides().then(
            resolve => {
                const data = resolve.data.data.AnncTemplate;
                const templates = data.map((t,k)=>({value:t.id,name:`Template: ${t.name} (${t.slidesQuantity} slides)`}))
                setState({...state,templates:templates,status:'ready'});
            }
        )
    },[])

    return (
        <ScrollyOverlayStyle
            zIndex={99}
        >
            <ScrollyModalStyle width={'340px'} onClick={e => e.stopPropagation()}>
                <header>
                    <SubTitle>Templates</SubTitle>
                    <Button type="cancel" onClick={cancel}>
                        Cancel
                    </Button>
                </header>
                {state.status === 'pending' ?
                    <LoadingWrapper>
                        <Loading style={{height: 80}}/>
                    </LoadingWrapper>
                    :
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <CustomDropdown
                            name={'templates'}
                            ref={register}
                            options={state.templates}
                            defaultValue={defaultValues}
                            multiple={true}
                        />
                        <Spacer top={4} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button type={'primary'}>Add Templates</Button>
                        </Spacer>
                    </form>
                }
            </ScrollyModalStyle>
        </ScrollyOverlayStyle>
    )
}

const openSlideTemplateForm = (defaultValues) => {
    return portal((success, cancel)=>{
        return <SlideTemplateForm
            success={success}
            cancel={cancel}
            defaultValues={defaultValues}
        />
    })
}

const portal = async render => {
    const el = document.createElement('div');
    el.setAttribute('id',Date.now());
    modalRoot.appendChild(el);

    const hideDialog = ()=> setTimeout( ()=> {
        ReactDOM.unmountComponentAtNode( el );
        modalRoot.removeChild(el);
    }, 1 );

    return new Promise( ( resolve, reject ) => {
        const success = data => hideDialog() && resolve( data );
        const cancel = data => hideDialog() &&  resolve( false );

        setTimeout(()=>
            ReactDOM.render( (
                render(success, cancel)
            ), el )
        , 10);
    });
}

const openSlidePreview = (params) => {
    const {slides, templateId, messageId,metricVideo} = params;
    return portal((success, cancel)=>{
        return <SlidePreview
            success={success}
            cancel={cancel}
            slides={slides}
            templateId={templateId}
            messageId={messageId}
            metricVideo={metricVideo}
        />
    })
}

const MockWrapper = styled.div`
    padding: 20px;
    border-radius: 2px;
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 90vh;
    position: relative;
    max-height: none;
    margin: auto;
    overflow: visible;

    .bg {
        background-image:url(${IphoneMock});
        background-size:contain;
        background-repeat:no-repeat;
        height:890px;
       
        width:auto;
        padding:90px 45px 140px 35px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
`

const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === 'header-one') {
        return editorStyle.title;
    }
    if (type === 'header-two') {
        return editorStyle.subtitle;
    }
}

const SlidePreview = ({success,cancel,slides,templateId,messageId,metricVideo = []}) => {
    const [slideList,setSlideList] = useState({slides:slides || [], status:slides ? 'READY' : 'PENDING',position:0});
    useEffect(()=>{
        if(!slides){
            if(templateId){
                API.fetchSlidesTemplate(templateId).then(
                    resolve => {
                        const slideResult = resolve.data.data.AnncSlide;
                        setSlideList({...slideList,slides:slideResult,status:'READY'});
                    }
                )
            }else if(messageId){
                API.fetchSlidesMessage(messageId).then(
                    resolve => {
                        const slideResult = resolve.data.data.AnncSlide;
                        const templateResult = resolve.data.data.AnncMessageTemplates;
                        const slideMerge = [...slideResult,...templateResult];
                        const slide = slideMerge.sort((a,b)=> a.slideOrder - b.slideOrder).reduce((acc,slide)=>{
                            if(!slide.template){
                                acc.push(slide);
                            }else if(slide.template.slides){
                                acc.push(...slide.template.slides);
                            }
                            return acc;

                        },[]);
                        setSlideList({...slideList,slides:slide,status:'READY'});
                    }
                )
            }
        }
    },[slides]);

    const resolveSlide = (direction) => {
        const index = slideList.position;
        const newIndex = index + (direction === 'next' ? 1 : -1);

        if(slideList.slides[newIndex]) {
            setSlideList({...slideList,position:newIndex})
        }
    }

    const SlidePositionPreview = ({index}) => {
        const s = slideList.slides[index];
        if(!s){
            return (
                <SubTitle style={{marginTop:10,textAlign:'center'}}>There is no slides</SubTitle>
            )
        }
        let newDefaultValue = JSON.parse(s.content);
        let entityMap = newDefaultValue?.entityMap;
        if (entityMap) {
            for (let key in entityMap) {
                if (entityMap[key].data.type === "metricVideo") {
                    let videoObj = metricVideo.find((video) => video.metricKey == entityMap[key].data.src);
                    if (videoObj != undefined) {
                        let vimeoUrl = videoObj?.videoUrl ? videoObj?.videoUrl + "?h=" + videoObj.hasKey : "";
                        entityMap[key].data.src = vimeoUrl;
                    }
                }
            }
        }
        
        const editorState = s.content ? EditorState.createWithContent(convertFromRaw(newDefaultValue)) : EditorState.createEmpty();
        return (
            <div  style={{border:'none',boxShadow:'none',background:'transparent'}} className={editorStyle.editor}>
                <Editor
                    editorState={editorState}
                    readOnly={true}
                    blockStyleFn={myBlockStyleFn}
                    plugins={plugins}
                />
            </div>
        )
    }

    const SlideInfoPreview = ({index}) => {
        const s = slideList.slides[index];
        if(!s){
            return null;
        }
        return (
            <div style={{padding:10,textAlign:'center'}}>
                <Text>This slide has a time requirement of {s.isRequired > 0 ? s.isRequired : 0} seconds.</Text>
            </div>
        )
    }

    return (
        <ScrollyOverlayStyle
            onClick={()=>{cancel()}}
            zIndex={100}
        >
            <MockWrapper
                style={{width:480}}
                onClick={e => e.stopPropagation()}>
                <div className={'bg'}>
                    {
                        slideList.status === 'PENDING'
                            ?
                                <Loading style={{height: 80, margin:'25% auto',display:'block'}}/>
                            :
                            <SlidePositionPreview index={slideList.position} />
                    }

                    {slideList.status === "READY" &&
                        <div>
                            <div style={{display:'flex',justifyContent:'space-between',marginBottom:20,zIndex:2}}>
                                {slideList.slides[slideList.position -1] ?
                                    <Icon.ArrowLeft style={{cursor:'pointer'}} onClick={()=>{resolveSlide('preview')}} />
                                    :
                                    <div />
                                }
                                {slideList.slides[slideList.position + 1] ?
                                    <Icon.ArrowRight style={{cursor:'pointer'}} onClick={()=>{resolveSlide('next')}} />
                                    :
                                    <div />
                                }
                            </div>
                            <SlideInfoPreview index={slideList.position} />
                        </div>
                    }
                </div>
            </MockWrapper>
        </ScrollyOverlayStyle>
    )
}


export {openSlideForm,SlideList,openSlidePreview};