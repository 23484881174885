import React, {useState} from "react";
import {Button, Password, Spacer, Title,Alert} from "@dspworkplace/ui";
import {useForm} from "react-hook-form";
import { getUserId, useAuth, getEmail} from "../../../Auth";
import toast from "../../../components/Toasts/toast";
import { validatePassword } from "../../../patterns";

const App = () => {
    const {api} = useAuth();
    const {register, handleSubmit, getValues, errors} = useForm();
    const { password, confirmPassword } = getValues();
    const [status,setStatus] = useState('loaded');

    const onSubmit = data => {
        setStatus('submitting');
        if((!data.password || !data.confirmPassword) || data.password != data.confirmPassword){
            setStatus('error');
            return false;
        }

        const param = {
            "key": "id",
            "value": getUserId(),
            "password": data.password,
            "isChangePassword": false,
        };

        api.post('/api/user/update_password/web', param,{
            cache: {
                ignoreCache: true
            }
        }).then(
            response => {
                if (response.data.success) {
                    toast({
                        type: 'success',
                        title: 'New password saved with success',
                        useClose:true,
                        timeout: false
                    })
                    setStatus('loaded')
                    localStorage.setItem("isResetPassword",'false');
                } else {
                    let messageTxt = (response.data?.message) ? response.data?.message : 'There was some error to update the password, try again latter.'; 
                    toast({
                        type: "Error",
                        title: "Error",
                        content: messageTxt
                    });
                    setStatus('loaded')
                }

            },
            error => {
                toast({
                    type: 'Error',
                    title: 'Error',
                    content: 'There was some error to update the password, try again latter.'
                })
                setStatus('loaded')
            }
        )
    }

    return (
        <Spacer bottom={10} id='password'>
            <Title>Change Password</Title>
            <Spacer bottom={5}/>
            {status === 'error' &&
                <Alert.Error
                    content={'Are differences between the passwords typed'}
                    style={{width:340}}
                />
            }
            <form onSubmit={handleSubmit(onSubmit)}>
                <Password
                    name='password'
                    label='New Password'
                    ref={register({
                        required: true,
                        validate: (value) => {
                            if (value) {
                                return validatePassword(value, getEmail(), []);
                            }
                        }
                    })}
                    error={
                        errors.password &&
                        errors.password.message
                    }
                    valid={!errors.password && password && password.length > 0}
                />
                <Spacer top={3}/>
                <Password
                    name='confirmPassword'
                    label='Confirm Password'
                    ref={register({
                        required: true,
                        validate: (value) => {
                            if (value) {
                                return validatePassword(value, getEmail(), []);
                            }
                        }
                    })}
                    error={
                        errors.confirmPassword &&
                        errors.confirmPassword.message
                    }
                    valid={!errors.confirmPassword && confirmPassword && confirmPassword.length > 0}
                />
                <Spacer bottom={5}/>
                <Button type='primary' disabled={status === 'submitting' ? true : false}>{status === 'submitting' ? 'Saving Data...'  : 'Save'}</Button>
            </form>
        </Spacer>
    )
}

export default App;
