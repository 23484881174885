import { useAuth } from "../../../../Auth";
import moment from "moment";
import toast from "../../../../components/Toasts/toast";

const weekReducer = (state,action) => {
    const {type, payload} = action;

    const addChange = (station, ids, mode, items) => {
        let updateIds;
        if (mode === 'multiSelectedMove' || mode === 'multiSelectedCopy') {
            updateIds = items.map(item => item.id);
        } else {
            updateIds = ids;
        }

        let stationId = station ? station : state.selectedStation;

        state.changes[stationId] = state.changes[stationId] || [];

        updateIds.forEach(function (id) {
            if (state.changes[stationId].length > 0) {
                if (!state.changes[stationId].includes(id?.toString())) {
                    state.changes[stationId].push(id?.toString());
                }
            } else {
                state.changes[stationId].push(id?.toString());
            }
        });
    }

    switch (type) {
        case 'ADD':
            state.changes = [];
            let orderValue = JSON.parse(localStorage.getItem('weekOrderValue'));
            if (typeof orderValue == "undefined" || orderValue == null) {
                localStorage.setItem('weekOrderValue', JSON.stringify({ "mainFields": [] }));
                orderValue = JSON.parse(localStorage.getItem("weekOrderValue"));
            }
            let orderedState = order(payload.stateData, orderValue);
            if (state.filter.length > 0) {
                const ordered_filter = order(state.filter, orderValue);
            }
            let f = Filter(orderedState,state.filterValue);
            if(payload.changes){
                const stationPublishId = Object.keys(payload.changes);
                if(stationPublishId[0] > 0){
                    addChange(stationPublishId[0],payload.changes[stationPublishId[0]]);
                }
            }
            
            return {
                ...state,
                state: payload.stateData,
                filterOptions: payload.filterOptions,
                schedulerFooterData: payload.schedulerFooterData,
                filter: f,
                filterValue:state.filterValue,
                selectedStation:payload.selectedStation,
                loading:false
            }
        case 'REF':
            return {...state,refTable:payload}
        case 'MOVE_OR_COPY':
            if (state.filter.length > 0) {
                moveShift(state.filter, payload.source, [payload.rowIndex, payload.weekIndex], payload.item, payload.hasAlert, payload.mode, payload.dragTargets)
            } else {
                moveShift(state.state, payload.source, [payload.rowIndex, payload.weekIndex], payload.item, payload.hasAlert, payload.mode, payload.dragTargets)
            }

            state.lastModified = new Date().getTime();
            addChange(payload?.item?.stationId,[payload?.item?.id],payload.mode,payload.item);

            return {...state};

        case 'UPDATE':
            var driver = ( state.filter.length ? state.filter : state.state )
                .find( d => d.id == payload.from.driverId );
            let iSource = driver[`w${payload.from.week}`].findIndex( shift => shift.id == payload.from.id );

            moveShift(
                state.filter.length ? state.filter : state.state,
                [
                    payload.from.driverId,
                    `w${payload.from.week}`,
                    iSource
                ],
                [
                    payload.to.driverId,
                    `w${payload.to.week}`
                ],
                payload.item,
                payload.hasAlert
            );

            // if (payload.item.hasAlert)
                //state.changes++;

            state.lastModified = new Date().getTime();

            if (payload.addChange)
                addChange(payload.item.stationId,[payload.item.id]);

            return {...state}

        case 'UPDATE_RESCUER':
            var driver = ( state.filter.length ? state.filter : state.state )
                .find( d => d.id == payload.from.driverId );

            let iSourceIndex1 = driver[`w${payload.from.week}`].findIndex( shift => shift.id == payload.from.id );
            let iSource1 = driver[`w${payload.from.week}`][iSourceIndex1];
            iSource1.isRescuer = false;
            iSource1.dateTimeEnded = false;
            iSource1.hour = payload.item.hour;
            iSource1.startTime = payload.item.startTime;
            iSource1.endTime = payload.item.endTime;
            iSource1.utcStartTime = payload.item.utcStartTime;
            iSource1.utcEndTime = payload.item.utcEndTime;

            moveShift(
                state.filter.length ? state.filter : state.state,
                [
                    payload.from.driverId,
                    `w${payload.from.week}`,
                    iSourceIndex1
                ],
                [
                    payload.to.driverId,
                    `w${payload.to.week}`
                ],
                iSource1,
                payload.hasAlert
            );

            if (payload.hasAlert)
                state.changes++;

            state.lastModified = new Date().getTime();

            return {...state}

        case 'UPDATE_RESCUED':
            var driver = ( state.filter.length ? state.filter : state.state )
                .find( d => d.id == payload.from.driverId );

            let iSourceIndex2 = driver[`w${payload.from.week}`].findIndex( shift => shift.id == payload.from.id );
            let iSource2 = driver[`w${payload.from.week}`][iSourceIndex2];
            iSource2.isRescued = false;
            moveShift(
                state.filter.length ? state.filter : state.state,
                [
                    payload.from.driverId,
                    `w${payload.from.week}`,
                    iSourceIndex2
                ],
                [
                    payload.to.driverId,
                    `w${payload.to.week}`
                ],
                iSource2,
                payload.hasAlert
            );

            if (payload.hasAlert)
                state.changes++;

            state.lastModified = new Date().getTime();


            return {...state}

        case 'UPDATED':
            return {...state}

        case 'CLEAN_CHANGES':

            if(payload.qty > 0){
                const newV = state.changes[state.selectedStation].filter((d,k)=> k > payload.qty)
                state.changes[state.selectedStation] = newV;
            }else{
                state.changes[state.selectedStation] = [];
            }
            return {...state}

        // Event Source
        case 'MOVED':
            moveShift(
                state.filter.length ? state.filter : state.state,
                [
                    payload.from.driverId,
                    `w${payload.from.week}`,
                    payload.from.indexSource||null,
                    payload.from.id
                ],
                [
                    payload.to.driverId,
                    `w${payload.to.week}`
                ],
                payload.item,
                payload.hasAlert
            );

            state.lastModified = new Date().getTime();

            if (payload.item.hasAlert)
                addChange(payload.item.stationId,[payload.item.id]);

            return {...state};

        case 'REMOVED':
            payload.item.routeId = payload.from.id;
            moveShift(
                state.filter.length ? state.filter : state.state,
                [
                    payload.from.driverId,
                    `w${payload.from.week}`,
                    payload.from.indexSource||null,
                    payload.from.id
                ],
                [],
                payload.item,
                payload.hasAlert
            );

            state.lastModified = new Date().getTime();

            if (payload.item.hasAlert)
                addChange(payload.item.stationId,[payload.item.id]);

            return {...state};
        case 'LOADING':
            return {...state,loading:payload.status}

        case 'ADD_DRIVER_WITH_SHIFT':
                var targetState = state.filter;
                var driverIndex = targetState.findIndex( d => d.id == payload.to.driverId );
                if(driverIndex <= 0) { 
                    var driverStateData = state.state.find( d => d.id == payload.to.driverId );
                    targetState = targetState.push(driverStateData);
                    var driverIndex = targetState.findIndex( d => d.id == payload.to.driverId );   
                } 
                targetState[driverIndex][`w${payload.to.week}`].push( payload.item );
                state.lastModified = new Date().getTime();

                if (payload.item.hasAlert)
                    addChange(payload.item.stationId,[payload.item.id]);

                return {...state};
        case 'ADDED':
            var targetState = ( state.filter.length ? state.filter : state.state );
            var driverIndex = targetState.findIndex( d => d.id == payload.to.driverId );
            if(driverIndex >= 0) {
                targetState[driverIndex][`w${payload.to.week}`].push( payload.item );

                state.lastModified = new Date().getTime();

                if (payload.item.hasAlert)
                    addChange(payload.item.stationId,[payload.item.id]);
            }

            return {...state};
        case 'FILTER':
            const filtered = Filter(state.state,payload);
            // if(filtered.length === 1 && filtered[0].id === '0.0.0') return {...state,filter:[]};
            return {...state,filter:filtered,filterValue:payload};
        case 'ORDER':
            localStorage.setItem('weekOrderValue', JSON.stringify(payload));
            const ordered = order(state.state,payload);
            if(state.filter.length > 0){
               const ordered_filter = order(state.filter,payload);
                return { ...state, state: ordered, filter: ordered_filter};
            }

            return { ...state, state: ordered };
        case 'DELETE_OPENSHIFT':
            DeleteOpenShifts(payload.routeIds, payload.week, state);
            return { ...state };
        case 'UNSCHEDULE':
              CallWeekDayData(payload.week, state, payload.status, true, payload.type,payload.data);
            return state;
        case 'HOLLIDAY_WEEK':
              CallWeekDayData(payload.week, state,false, false, false,{});
            return state;
        case 'EXPAND_OPEN_SHIFTS':
            state.state[0].status = state.state[0].status === 1 ? 0 : 1;
            if(state.filter.length > 0){
                const filterStatus = state.filter[0].status;
                state.filter[0].status = filterStatus;
            }
            return {...state}
        case "SET_PUBLISH_COUNT":
            if(action.api)
                state.changes = {};
            addChange(action.station, action.ids);
            return {...state}
        case "REMOVE_CHANGES":
            let ch = state.changes[action.station];
            if(ch){
                ch = ch.filter(function(item) {
                    return item !== action.id.toString()
                });
                state.changes[action.station] = ch;
            }

            return {...state}
        case "SET_INCIDENT_TYPES":
            return { ...state, incidentTypes: payload };
        case "SET_NOTES":
            const driverCurrIndex1 = state.state.findIndex((v)=> payload.driver === v.driverId);
            if(driverCurrIndex1 >= 0){
                state.state[driverCurrIndex1]['notes'] = payload.notes;
            }
            return { ...state};
        case "UPDATE_SINGLE_SHIFT":
            const driverCurrIndex = state.state.findIndex((v)=> payload.shift.driverId === v.driverId);
            if(driverCurrIndex >= 0){
                state.state[driverCurrIndex][payload.weekNum][payload.weekIdx] = payload.shift;
            }

            const driverFilterIndex = state.filter.findIndex((v)=> payload.shift.driverId === v.driverId);
            if(driverFilterIndex >= 0) {
                state.filter[driverFilterIndex][payload.weekNum][payload.weekIdx] = payload.shift;
            }
            return {...state}

        case "EMPTY_BOX_SELECT":
            return {...state, isEmptyBoxSelected: payload}

        case "REFRESH":
            return { ...state };

        default: return state

    }
}

const Filter = (state, filter) => {
    let open;
    let drivers = [...state];
    /*if ( drivers[0].id === '0.0.0' ) {
        open = drivers.shift();
    }*/

    if(filter.driver && filter.driver.length > 0){
        drivers = drivers.filter(item => {
            if (!item.driverId)
                return false;

            return filter.driver.includes(item.driverId.toString());
        })
    }

    /*if(filter.stations && filter.stations.length > 0){
        drivers = drivers.filter(item => {
            if (!item.stationId)
                return false;

            return filter.stations.includes(item.stationId.toString());
        })
    }*/

    if(filter.schedules && filter.schedules.length > 0){
        drivers = drivers.filter(item => {
            if (!item.scheduledId)
                return false;

            return filter.schedules.includes(item.scheduledId.toString());
        })
    }

    if (filter.overtime && filter.overtime.length > 0) {
        drivers = drivers.filter(item => {
            let avoidOvertime = [];
            filter.overtime.map(typeOvertime => {
                switch(typeOvertime){
                    case 'in_overtime':
                        avoidOvertime.push(item.workHours > 40);
                        break;
                    case 'scheduled':
                        avoidOvertime.push(item.estimatedWorkHours > 40);
                        break;
                    case 'not_scheduled_overtime':
                        avoidOvertime.push(item.estimatedWorkHours <= 40);
                        break;
                }
            })

            return avoidOvertime.includes(true);
        })
    }

    if (filter.skills && filter.skills.length > 0) {

        drivers = drivers.filter(item => {
            let skillExist = [];
            filter.skills.forEach(skill => {
                skillExist.push(item?.skills?.includes(parseInt(skill)));
            })

            return skillExist.includes(true);
        })
    }

    let filtered = open !== undefined ? [open].concat(drivers) : [...drivers];
    if(filter.shift_type && filter.shift_type.length > 0){
        filtered = filtered.map(item => {
            const weeks = Object.entries(item)
                .filter(([key, value]) => Array.isArray(value))
                .reduce((acc, [key, week]) => {
                    return {
                        ...acc,
                        [key]: week.filter(w => {
                            if ( !w.typeId ) return true;

                            return filter.shift_type.includes(w.typeId.toString())
                        }) || []
                    }
                }, {})
            return {
                ...item,
                ...weeks
            }
        })
    }

    if (!filter.unscheduled) {
        filtered = filtered.filter(item => {
            if ( item.id === '0.0.0' )
                return true;

            let allShifts = item.w0.concat(item.w1, item.w2, item.w3, item.w4, item.w5, item.w6);
            return allShifts.length > 0;
        })
    }

    filtered = filtered.map(item => {
        const n = solveMax(item);
        item.biggerWeek = n;
        if(item.id === '0.0.0'){
            item.groupQty = solveMaxOpenShift(item);
        }else{
            item.groupQty = solveMax(item);
        }

        return item;
    })
    return filtered;
}

const order = (array, fields) => {
    const {mainFields, childFields, dataKey} = fields;
    let mainResult = array.sort((a,b)=>{
        const sortstr1 = mainFields.reduce((str,key) => {
            if(a.id === '0.0.0') {return str}
            let c = '';
            let r = `${str}~${a[key]}`;
            if(childFields){
                if(childFields[0] === 'routeCode'){
                    c = a[dataKey].reduce((string,data)=> {
                        if(data[childFields[0]].length > 0){
                            return `${string}~${data[childFields[0]].sort((a,b)=> b.localeCompare(a,undefined,{numeric:true,sensitivity: 'base'})).join('~')}`
                        }else{
                            return null
                        }
                    },'')
                }else{
                    c = a[dataKey].reduce((string,data)=> `${string}~${data[childFields[0]]}`,'')
                }
            }
            return c+r;
        },'')
        const sortstr2 = mainFields.reduce((str,key) =>{
            if(b.id === '0.0.0') {return str}
            let c = ''
            let r = `${str}~${b[key]}`
            if(childFields){
                if(childFields[0] === 'routeCode') {
                    c = b[dataKey].reduce((string, data) => {
                        if(data[childFields[0]].length > 0){
                            return `${string}~${data[childFields[0]].sort((a,b)=> b.localeCompare(a,undefined,{numeric:true,sensitivity: 'base'})).join('~')}`
                        }else{
                            return null;
                        }
                    },'')
                }else{
                    c = b[dataKey].reduce((string, data) => `${string}~${data[childFields[0]]}`, '')
                }
            }
            return c+r;
        },'')
        return sortstr1.localeCompare(sortstr2,undefined,{numeric:true,sensitivity: 'base'})
    })

    if(childFields){
        mainResult = mainResult.map(item=>{

            let cc= item[dataKey].sort((c,d)=>{
                const str1 = childFields.reduce((string,fieldName) => `${string}~${c[fieldName]}`,'')
                const str2 = childFields.reduce((string,fieldName) => `${string}~${d[fieldName]}`,'')
                return str1.localeCompare(str2,undefined,{numeric:true,sensitivity: 'base'});
            })

            return item;
        })
    }
    return mainResult;
}

const moveShift = (array,source,target,data,hasAlert,mode,dragTargets) => {

    let [idSource, weekSource, indexSource, sourceId] = source;
    const [idTarget, weekTarget] = target;
    const targetRow = array.filter(item => {
        return item.id ==  idTarget;
    });

    let sourceRow;
    if(idTarget === idSource || mode === 'multiSelectedCopy' || mode === 'multiSelectedMove'){
        sourceRow = targetRow;
    }else{
        sourceRow = array.filter(item => {
            return item.id ==  idSource;
        });
    }

    if (indexSource == null) {
        if (sourceId == null) {
            indexSource = sourceRow[0][weekSource]?.findIndex(route => route.routeId.toString() === data?.routeId?.toString())
        } else {
            indexSource = sourceRow[0][weekSource]?.findIndex(route => route.id.toString() === sourceId.toString())
        }
    }
    if (mode === 'multiSelectedMove') {
        let dataIds = data.map(item => item.routeId);
        array.forEach(item => {
            for (let i = 0; i <= 6; i++) {
                let key = 'w' + i;
                item[key] = item[key]?.filter(obj => !dataIds.includes(obj.routeId));
            }
        });
    }

    if (mode === 'multiSelectedCopy' || mode === 'multiSelectedMove') {
        let index = parseInt(weekTarget?.charAt(1));
        for (let i = 0; i < dragTargets?.length; i++) {
            sourceRow[0]["w" + (index + i)].push(dragTargets[i]);
        }
    }

    if(mode !== 'copy' && mode !== 'multiSelectedCopy' && indexSource != -1 && sourceRow[0] && sourceRow[0][weekSource]) sourceRow[0][weekSource].splice(indexSource,1);
    if(idTarget !== idSource){
        sourceRow[0].biggerWeek = solveMax(sourceRow[0]);
    }

    if(!targetRow.length) return false;
    if (data?.alert) data.alert = hasAlert;
    if (mode !== 'multiSelectedCopy' && mode !== 'multiSelectedMove') targetRow[0][weekTarget].push(data);
    if(idTarget === '0.0.0'){
        targetRow[0].groupQty = solveMaxOpenShift(targetRow[0]);
        targetRow[0].biggerWeek = solveMax(targetRow[0]);
    }else{
        targetRow[0].biggerWeek = solveMax(targetRow[0]);
    }
    return true;
}

const DeleteOpenShifts = (routeIds, week, state) => {
    const { api } = useAuth();
    if (routeIds && routeIds.length > 0) {
        let params = {
            "actions": {
                "response": {
                    "DriverRoute": {
                        "custom": {
                            "functionName": "deleteOpenShift",
                            "get": "driverRoute",
                            "criteria": {
                                "routeIds": routeIds,
                            }
                        }
                    }
                }
            }
        }


        week = week.slice(1);

        routeIds.map((routeId) => {
            
            weekReducer(state,{
                type: "REMOVED",
                payload: {
                    from: {
                        driverId: "0.0.0",
                        week: week,
                        id: routeId,
                    },
                    item: {
                        hasAlert: false,
                    },
                },
            });
            weekReducer(state, { type: "REMOVE_CHANGES", id: routeId, station: state.selectedStation });
        })

        try {
            api.post('/api/lazy/manage/data', params).then(() => {
                toast({
                    type: 'Success',
                    title: "All Open shifts are successfully deleted"
                });
            });
        } catch (error) {
            if (error.response.status == '401') {
                window.location = "/logout";
            }
        }
    }
}

const CallWeekDayData = (week, state,status, isUnschedule, type,data) => {
   let routeIds = [];
   let driverIds =[];
   let punchedRoute =[];
   const { api } = useAuth();

   let schedueleData = state.state;
//    if (isUnschedule) {
//       schedueleData.shift();
//    }

   let shiftTodayData = [];
   schedueleData.forEach(function(driverRouts){
        if(driverRouts.w0.length > 0 && week == 'w0'){shiftTodayData = shiftTodayData.concat(driverRouts.w0); let tempRoute1 = checkFilterShiftRouteData(driverRouts.w0,'route'); if(tempRoute1.length > 0){ routeIds.push(tempRoute1); }}
        if(driverRouts.w1.length > 0 && week == 'w1'){shiftTodayData = shiftTodayData.concat(driverRouts.w1); let tempRoute2 = checkFilterShiftRouteData(driverRouts.w1,'route'); if(tempRoute2.length > 0){ routeIds.push(tempRoute2); }}
        if(driverRouts.w2.length > 0 && week == 'w2'){shiftTodayData = shiftTodayData.concat(driverRouts.w2); let tempRoute3 = checkFilterShiftRouteData(driverRouts.w2,'route'); if(tempRoute3.length > 0){ routeIds.push(tempRoute3); }}
        if(driverRouts.w3.length > 0 && week == 'w3'){shiftTodayData = shiftTodayData.concat(driverRouts.w3); let tempRoute4 = checkFilterShiftRouteData(driverRouts.w3,'route'); if(tempRoute4.length > 0){ routeIds.push(tempRoute4); }}
        if(driverRouts.w4.length > 0 && week == 'w4'){shiftTodayData = shiftTodayData.concat(driverRouts.w4); let tempRoute5 = checkFilterShiftRouteData(driverRouts.w4,'route'); if(tempRoute5.length > 0){ routeIds.push(tempRoute5); }}
        if(driverRouts.w5.length > 0 && week == 'w5'){shiftTodayData = shiftTodayData.concat(driverRouts.w5); let tempRoute6 = checkFilterShiftRouteData(driverRouts.w5,'route'); if(tempRoute6.length > 0){ routeIds.push(tempRoute6); }}
        if(driverRouts.w6.length > 0 && week == 'w6'){shiftTodayData = shiftTodayData.concat(driverRouts.w6); let tempRoute7 = checkFilterShiftRouteData(driverRouts.w6,'route'); if(tempRoute7.length > 0){ routeIds.push(tempRoute7); }}

        if(driverRouts.w0.length > 0 && week == 'w0'){shiftTodayData = shiftTodayData.concat(driverRouts.w0); let tempDriver1 = checkFilterShiftRouteData(driverRouts.w0,'driver'); if(tempDriver1.length > 0){ driverIds.push(tempDriver1); }}
        if(driverRouts.w1.length > 0 && week == 'w1'){shiftTodayData = shiftTodayData.concat(driverRouts.w1); let tempDriver2 = checkFilterShiftRouteData(driverRouts.w1,'driver'); if(tempDriver2.length > 0){ driverIds.push(tempDriver2); }}
        if(driverRouts.w2.length > 0 && week == 'w2'){shiftTodayData = shiftTodayData.concat(driverRouts.w2); let tempDriver3 = checkFilterShiftRouteData(driverRouts.w2,'driver'); if(tempDriver3.length > 0){ driverIds.push(tempDriver3); }}
        if(driverRouts.w3.length > 0 && week == 'w3'){shiftTodayData = shiftTodayData.concat(driverRouts.w3); let tempDriver4 = checkFilterShiftRouteData(driverRouts.w3,'driver'); if(tempDriver4.length > 0){ driverIds.push(tempDriver4); }}
        if(driverRouts.w4.length > 0 && week == 'w4'){shiftTodayData = shiftTodayData.concat(driverRouts.w4); let tempDriver5 = checkFilterShiftRouteData(driverRouts.w4,'driver'); if(tempDriver5.length > 0){ driverIds.push(tempDriver5); }}
        if(driverRouts.w5.length > 0 && week == 'w5'){shiftTodayData = shiftTodayData.concat(driverRouts.w5); let tempDriver6 = checkFilterShiftRouteData(driverRouts.w5,'driver'); if(tempDriver6.length > 0){ driverIds.push(tempDriver6); }}
        if(driverRouts.w6.length > 0 && week == 'w6'){shiftTodayData = shiftTodayData.concat(driverRouts.w6); let tempDriver7 = checkFilterShiftRouteData(driverRouts.w6,'driver'); if(tempDriver7.length > 0){ driverIds.push(tempDriver7); }}

        if(driverRouts.w0.length > 0 && week == 'w0'){shiftTodayData = shiftTodayData.concat(driverRouts.w0); let punchedDriver1 = checkFilterShiftRouteData(driverRouts.w0,'punched'); if(punchedDriver1.length > 0){ punchedRoute.push(punchedDriver1); }}
        if(driverRouts.w1.length > 0 && week == 'w1'){shiftTodayData = shiftTodayData.concat(driverRouts.w1); let punchedDriver2 = checkFilterShiftRouteData(driverRouts.w1,'punched'); if(punchedDriver2.length > 0){ punchedRoute.push(punchedDriver2); }}
        if(driverRouts.w2.length > 0 && week == 'w2'){shiftTodayData = shiftTodayData.concat(driverRouts.w2); let punchedDriver3 = checkFilterShiftRouteData(driverRouts.w2,'punched'); if(punchedDriver3.length > 0){ punchedRoute.push(punchedDriver3); }}
        if(driverRouts.w3.length > 0 && week == 'w3'){shiftTodayData = shiftTodayData.concat(driverRouts.w3); let punchedDriver4 = checkFilterShiftRouteData(driverRouts.w3,'punched'); if(punchedDriver4.length > 0){ punchedRoute.push(punchedDriver4); }}
        if(driverRouts.w4.length > 0 && week == 'w4'){shiftTodayData = shiftTodayData.concat(driverRouts.w4); let punchedDriver5 = checkFilterShiftRouteData(driverRouts.w4,'punched'); if(punchedDriver5.length > 0){ punchedRoute.push(punchedDriver5); }}
        if(driverRouts.w5.length > 0 && week == 'w5'){shiftTodayData = shiftTodayData.concat(driverRouts.w5); let punchedDriver6 = checkFilterShiftRouteData(driverRouts.w5,'punched'); if(punchedDriver6.length > 0){ punchedRoute.push(punchedDriver6); }}
        if(driverRouts.w6.length > 0 && week == 'w6'){shiftTodayData = shiftTodayData.concat(driverRouts.w6); let punchedDriver7 = checkFilterShiftRouteData(driverRouts.w6,'punched'); if(punchedDriver7.length > 0){ punchedRoute.push(punchedDriver7); }}
   });

   let paramsRouteIds = '';
   if (routeIds.length > 0) {
            if (isUnschedule) {
                paramsRouteIds={
                    "actions": {
                            "response": {
                                "DriverRoute": {
                                "custom": {
                                    "functionName": "unscheduleAllShiftByDriverRoute",
                                    "get": "driverRoute",
                                    "excludes": [ "skill", "vehicle", "device", "driver", "station", "shiftType", "schedule", "vehicleDriverRecord", "status", "tempDriverRoutes", "kickoffLog", "returnToStation", "oldDriver", "oldDriverRoute", "driverRoutes", "oldTempDriverRoutes"],
                                    "criteria": {
                                        "routeId" : [].concat.apply([],routeIds),
                                        "type":type,
                                        "status": status,
                                        "message": typeof data.msg === "string" ? data.msg : null,
                                        "sms": data.sendMessage === "true" ? true : null,
                                        "driverIds": [].concat.apply([],driverIds),
                                    }
                                }
                            }
                        }
                    }
                }

          } else {
                paramsRouteIds={
                    "actions": {
                            "response": {
                             "DriverRoute": {
                              "custom": {
                                  "functionName": "setHolidayAndNonBusinessDayByDriverRoute",
                                  "get": "driverRoute",
                                  "excludes": [ "skill", "vehicle", "device", "driver", "station", "shiftType", "schedule", "vehicleDriverRecord", "status", "tempDriverRoutes", "kickoffLog", "returnToStation", "oldDriver", "oldDriverRoute", "driverRoutes", "oldTempDriverRoutes"],
                                  "criteria": {
                                        "routeId" : [].concat.apply([],routeIds),
                                        "type":type,
                                        "status": status,
                                        "message": typeof data.msg === "string" ? data.msg : null,
                                        "sms": data.sendMessage === "true" ? true : null,
                                        "driverIds": [].concat.apply([],driverIds),
                                  }
                              }
                          }
                      }
                   }
                }
          }
        try {
            const response = api.post('/api/lazy/manage/data', paramsRouteIds);
            if(type == 'Unassign') {
                    let isTempArray = [];
                    shiftTodayData.forEach(function(currentShift){
                        if(currentShift.isBreakPunchIn == false && currentShift.isBreakPunchOut == false && currentShift.isPunchedIn == false && currentShift.isPunchedOut == false){
                            if(!isTempArray.includes(currentShift.id)){
                                isTempArray.push(currentShift.id);
                                let from = {
                                    driverId: currentShift.driverId,
                                    week: moment(currentShift.shiftDate).weekday(),
                                    indexSource: 0
                                };
                                let to = {
                                    driverId: "0.0.0",
                                    week: moment(currentShift.shiftDate).weekday()
                                };
                                let payload = {
                                    from: from,
                                    to: to,
                                    item: currentShift,
                                    hasAlert: false
                                };
                                let action = {};
                                action.type = "MOVED";
                                action.payload = payload;
                                weekReducer(state,action);
                                state.refTable.current.recomputeRowHeights();
                            }
                        }
                    });

                    toast({
                        type: 'Success',
                        title: "All shifts are successfully unassigned"
                    });
            } else {
                let isTempArray = [];
                shiftTodayData.forEach(function(currentShift){
                    if(currentShift.isBreakPunchIn == false && currentShift.isBreakPunchOut == false && currentShift.isPunchedIn == false && currentShift.isPunchedOut == false){
                        if(!isTempArray.includes(currentShift.id)){
                            isTempArray.push(currentShift.id);
                            let from = {
                                driverId: currentShift.driverId,
                                week: moment(currentShift.shiftDate).weekday(),
                                id: currentShift.id
                            };
                            let payload = {
                                from: from,
                                item: { hasAlert: false },

                            };
                            let action = {};
                            action.type = "REMOVED";
                            action.payload = payload;
                            weekReducer(state,action);
                            state.refTable.current.recomputeRowHeights();
                        }
                    }
                });

                toast({
                    type: 'Success',
                    title: "All shifts are successfully deleted"
                });
            }
            } catch(error) {
                if (error.response.status == '401') {
                    window.location = "/logout";
                }
            }
    }

    if (punchedRoute.length > 0) {
        paramsRouteIds={
            "actions": {
                    "response": {
                     "DriverRoute": {
                      "custom": {
                          "functionName": "addEventOfPunchedInDrivers",
                          "get": "driverRoute",
                          "criteria": {
                                "routeId" : [].concat.apply([],punchedRoute),
                                "type":type
                          }
                      }
                  }
              }
           }
        }
        try{
            api.post('/api/lazy/manage/data', paramsRouteIds);
        } catch(error) {
            if (error.response.status == '401') {
                window.location = "/logout";
            }
        }

    }
    return true;

}

const checkFilterShiftRouteData = (shiftData,type) => {
      let newRouteId = [];
      let punchedRoutes = [];
      shiftData.forEach(function(shift){
            if(shift.isBreakPunchIn == false && shift.isBreakPunchOut == false && shift.isPunchedIn == false && shift.isPunchedOut == false){
                if(type == 'driver'){
                    newRouteId.push(shift.driverId);
                } else {
                    newRouteId.push(shift.routeId);
                }
            } else {
                punchedRoutes.push(shift.routeId);
            }
      });
      if(type == 'punched'){
        return punchedRoutes;
      } else {
        return newRouteId;
      }

}

const solveMax = (row) => {

   let dataLengths = [];
   for(let i=0;i<=6;++i){
       dataLengths.push(row[`w${i}`]?.length);
   }

   return Math.max.apply(null,dataLengths);
}

const solveMaxOpenShift = (row) => {
    let dataLengths = [];
    for(let i=0;i<=6;++i){
        const reduced = row[`w${i}`].reduce((newArray,item)=>{
            const key = `${item.typeId}.${item.startTime}.${item.endTime}`;

            if(!newArray[key]){
                newArray[key] = {id:1};
            }
            return newArray
        },[])
        dataLengths.push(Object.values(reduced).length);
    }

    return Math.max.apply(null,dataLengths);
}

export default weekReducer;
