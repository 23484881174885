import React, {useState, useRef, useEffect, useMemo} from "react";
import styled from "styled-components";
import {Icon, Spacer, Text, Theme} from "@dspworkplace/ui";
import Modal from "../Modal";
import Loading, {LoadingWrapper} from "../Loading";
import SimpleScroll from "../SimpleScroll";
import moment from "moment";
import Empty from "../Empty";
import {GetTimeLineEntity} from "../../domain/event/api/TimelineEntity";
import {useIntersection} from "../../hooks/useIntersect";
import {toast} from "../Toasts";
import {portalPromise} from "../Dialog";

const DateContent = styled.span`
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.extraSmall.lineHeight};
    font-family: ${Theme.font.main};
    font-weight: bold;
    color: ${Theme.colors.neutrals.medium};
`;

const HistContent = styled.div`
    > .hasPadding {
        cursor:pointer;

        &:last-child {
            padding-bottom:0;

            .info {
                &:before {
                    min-height:0;
                }
            }

            .hidden {
                &:before {
                    top: -8px;
                    height: calc(100% + 18px);
                }
            }
        }

        .info {
            flex: 1;
            justify-content: flex-start;

            &:before {
                content: ' ';
                border-left: 1px dashed ${Theme.colors.info.border};
                display: block;
                min-height: 30px;
                top: 50%;
                position: absolute;
                margin-left: 5px;
            }
        }

    }
`;

export const FlexScroll = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 135px);

    //&::-webkit-scrollbar {
    //    width:0;
    //}

    &::-webkit-scrollbar-track-piece {
        background: #EAF0F6;
    }
    
    &::-webkit-scrollbar-thumb {
        background: #7C98B6
    }

    // height: auto;
    width: auto;
`;

const Status = styled.span`
    background: ${Theme.colors.info.border};
    height: 8px;
    width: 8px;
    border-radius: 100%;
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
    position: relative;
    z-index: 2;
    box-sizing: border-box;

    &.open {
        background: #fff;
        border: 2px solid ${Theme.colors.info.border};
        width: 12px;
        height: 12px;
        margin-left: 0;
        margin-right: 0;
    }

    &.error {
        background:${Theme.colors.error.border};
        border-color: ${Theme.colors.error.border};
    }
`;

const StatusContainer = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    position:relative;
    flex-wrap:wrap;
    border-color: ${Theme.colors.info.border};

    &.hasPadding {
        padding-bottom:8px;
    }
`;

const StatusTitle = styled.h5`
    color: ${Theme.colors.info.text};
    font-size: ${props => (props.fontSize ? props.fontSize : Theme.font.medium.size)};
    line-height: ${Theme.font.medium.lineHeight};
    font-family: ${Theme.font.main};
    font-weight: normal;
    display: inline-block;
    position: relative;
    left: 6px;

    &.error {
        color:${Theme.colors.error.text};
    }
`;

const HiddenContent = styled.div`
    flex-basis: 100%;
    margin-left: 20px;
    padding: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: inherit;
    position:relative;
    border-radius:4px;
    margin-top:4px;

    &:before {
        content: ' ';
        border-left: 1px dashed ${Theme.colors.info.border};
        position: absolute;
        left: -16px;
        top: 13px;
        height: calc(100% + 12px);
    }
`;

const ExtraInfos = styled.div`
    > p {
        color: ${Theme.colors.neutrals.medium};
        display:inline-block;
        font-family: ${Theme.font.body};
    }
    
    > p.title {
        color:#707070;
        width:100px;
    }
`;

const Header = styled.div`
    display:flex;
    justify-content:space-between;
`;

const solveIcon = (type, color) => {
    let icon = Icon[type] ? Icon[type] : Icon.Info;
    return React.createElement(
        icon,
        {
            size: '16px',
            color: color || Theme.colors.info.text,
            style: {
                verticalAlign: 'middle'
            }
        }
    )
};

const HistComponent = ({data}) => {
    const [open, setOpen] = useState([]);

    const handleHiddenContent = (index) => {
        if (open.includes(index)) {
            const copy = open.filter((item) => item !== index);

            setOpen(copy);
        } else {
            setOpen([...open, index]);
        }
    }

    return (
        <HistContent>
            {data.line && Array.isArray(data.line) && data.line.map((info, i) => (
                <StatusContainer className='hasPadding' onClick={() => {
                    handleHiddenContent(i)
                }} key={i}>
                    <StatusContainer className='info'>
                        <Status
                            className={[info.status === 'incident' ? 'error' : '', open.includes(i) ? 'open' : '']}/>
                        <Spacer left={2}>
                            {solveIcon(info.iconType, info.status === 'incident' ? Theme.colors.error.text : null)}
                            {typeof info.title === "string" && info.title.length > 54 &&
                            <StatusTitle className={[info.status === 'incident' ? 'error' : '']} fontSize={'11px'}>
                                {info.title}
                            </StatusTitle>}
                            {typeof info.title === "string" && info.title.length <= 53 &&
                            <StatusTitle className={[info.status === 'incident' ? 'error' : '']}>
                                {info.title}
                            </StatusTitle>}
                        </Spacer>
                    </StatusContainer>
                    {typeof info.date === "string"?
                        <DateContent>{moment(info.date).local().format('h:mma')}</DateContent>
                        :
                        <DateContent>{moment.utc(info.date).local().format('h:mma')}</DateContent>}
                    {open.includes(i) && <ExtraData info={info}/>}
                </StatusContainer>
            ))}
        </HistContent>
    )
}

const ExtraData = ({info}) => {
    return (
        <HiddenContent className='hidden'>
            <Spacer bottom={1}>
                <Text dangerouslySetInnerHTML={{__html: info.information}}/>
                {info.entries && info.entries.map((e, k) =>
                    <Spacer top={2} key={k}>
                        <ExtraInfos>
                            <Text className='title'>{e.name}</Text>
                            <Text>{e.value}</Text>
                        </ExtraInfos>
                    </Spacer>
                )}
            </Spacer>
        </HiddenContent>
    )
}

export const TimelineSmart = ({
   visible = false,
   setVisible = () => {},
   drawer = 'right',
   apiParams = {},
   title
}) => {
    const [status, setStatus] = useState('pending')
    const [data, setData] = useState([])
    const [date, setDate] = useState()

    const LoaderRef = React.useRef(null);
    const entry = useIntersection(LoaderRef,{
        threshold :1
    })
    const isLoadingVisible = !!entry?.isIntersecting

    const timeLineHandler = (params) => {
        GetTimeLineEntity(params).then(resolve => {
            setData(oldData => {
                return [...oldData,...resolve.data.events]
            })
            setDate(oldDate => {
                if(oldDate === resolve.data.nextDate) return null
                return resolve.data.nextDate
            })
        }).catch(err => {
            toast({
                type: "error",
                title: 'Unable to load the timeline',
                content: err.message,
                timeout: 5000,
                useClose: true
            })
        }).finally(() => {
            setStatus('ready')
        })
    }

    useEffect(()=>{
        timeLineHandler(apiParams)
    },[apiParams])

    useEffect(()=>{
        if(!isLoadingVisible) return
        if(!date) return

        timeLineHandler({...apiParams,date:date})
    },[apiParams, date, isLoadingVisible])

    return (
        <Modal
            width='506px'
            visible={visible}
            setVisible={setVisible}
            drawer={drawer}
            removeHeader={true}
        >
            <Header>
                {title}
                <div>
                    <Spacer inline left={4}>
                        <Icon.Times
                            color={Theme.colors.neutrals.silver}
                            size='28px'
                            onClick={()=>setVisible(false)}
                        />
                    </Spacer>
                </div>
            </Header>
            <Spacer top={5}/>
            <div style={{width: '100%', height: '1px', background: '#F5F5F5', display: 'block'}}/>
            <Spacer top={5}/>

            {status === 'pending' && (
                <LoadingWrapper>
                    <Loading style={{height: '80px', marginTop: '20px'}}/>
                </LoadingWrapper>
            )}

            {status === 'ready' && data.length < 1 &&
                <Empty/>
            }

            {status === 'ready' && data.length > 0 &&
                <>
                    {
                        data.map(event =>{
                            return (
                                <div key={event.date}>
                                    <DateContent>{moment(event.date).format('dddd, MMM Do Y')}</DateContent>
                                    <Spacer bottom={2}/>
                                    <HistComponent data={event}/>
                                    <Spacer bottom={5}/>
                                </div>
                            )
                        })
                    }
                </>
            }
            <div ref={LoaderRef}>
                {date && <Loading style={{height:25, display: 'block', marginLeft:'auto', marginRight:'auto',padding: '10px 0'}} />}
            </div>
        </Modal>
    )
}

export const openTimeline = (params) => {
    return portalPromise((success) => {
        const setVisible = () => {
            success()
        }
        return <TimelineSmart
            visible={true}
            setVisible={setVisible}
            apiParams={params}
        />
    });
}

const Timeline = ({
                      visible = false,
                      setVisible = () => {
                      },
                      loading = true,
                      title,
                      actions,
                      /**
                       * Format:
                       * [
                       *     // each object represents a day
                       *     {
                       *         id: int
                       *         date: valid moment value - should be UTC
                       *         line: [
                       *             // each object represents an event of that day
                       *             {
                       *                 date: valid moment value - should be UTC
                       *                 iconType: any Icon element
                       *                 title: str,
                       *                 type: any,
                       *                 // optional - show details when clicked
                       *                 entries: [
                       *                     { name: string-like, value: string-like }
                       *                 ]
                       *                 // optional - title of "entries" when clicked
                       *                 information: str
                       *             }
                       *         ]
                       *     }
                       * ]
                       * */
                      data = [],
                      onLoadMore = () => {
                      } // todo - when we scroll to the bottom of the list call this
                  }) => {
    const [isMoreData, setIsMoreData] = useState(false);
    const [filtered, setFiltered] = useState([]);
    const [allFiltered, setAllFiltered] = useState([]);
    const scrollEl = useRef(null);
    const loadMoreData = async (data) => {
        let itemCount = 0;
        let listData = [];
        if(data && data.length > 0){
            data = await data.sort(function(a,b){return new Date(b.dateDay) - new Date(a.dateDay);}).filter((item, key) => {
                if(itemCount < 100){
                    listData.push(item);
                    itemCount = itemCount + item.line.length;
                    return false;
                }else{
                    return true;
                }
            });
            await setFiltered([...filtered,...listData]);
            await setAllFiltered(data);
        } else{
            await setAllFiltered([]);
        }

        if(data && data.length > 0) {
            setIsMoreData(true);
        }else{
            setIsMoreData(false);
        }
    };
    const scrollChange = () => {
        if(isMoreData){
            if(scrollEl.current.scrollTop + scrollEl.current.offsetHeight > scrollEl.current.scrollHeight - 5){
                loadMoreData(allFiltered)
            }
        }
    };

    useEffect(() => {
        loadMoreData(data ? data.filter(i => i.line.some(e => e.date !== null)) : []);
    },[data]);

    return (
        <Modal
            width='506px'
            visible={visible}
            setVisible={setVisible}
            drawer='right'
            removeHeader={true}
        >
            {loading && (
                <LoadingWrapper>
                    <Loading style={{height: '80px', marginTop: '20px'}}/>
                </LoadingWrapper>
            )}
            {!loading && (
                <>
                    <Header>
                        {title}
                        <div>
                            {actions}
                            <Spacer inline left={4}>
                                <Icon.Times
                                    color={Theme.colors.neutrals.silver}
                                    size='28px'
                                    onClick={() => setVisible(false)}
                                />
                            </Spacer>
                        </div>
                    </Header>
                    <Spacer top={5}/>
                    <div style={{width: '100%', height: '1px', background: '#F5F5F5', display: 'block'}}/>
                    <Spacer top={5}/>
                    <FlexScroll  as={'li'} onScroll={()=>scrollChange()} ref={scrollEl}>
                        {!filtered.length && <Empty/>}
                        {filtered.map((item, index) => (
                            <div key={index}>
                                    <DateContent>{moment.utc(item.date).local().format('dddd, MMM Do Y')}</DateContent>
                                <Spacer bottom={2}/>
                                <HistComponent data={item}/>
                                <Spacer bottom={5}/>
                            </div>
                        ))}
                        {isMoreData && isMoreData == true && (<center>
                            <Loading style={{height: '80px', marginTop: '20px', left: '43%'}}/>
                        </center>)}
                    </FlexScroll>
                </>
            )}
        </Modal>
    );
};

export default Timeline;


