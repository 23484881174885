import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Analytics from "./Utilities/Analytics";
import Menu, { MenuItemBadge } from "./components/Menus/Main";
import { ToastContainer } from "./components/Toasts";
import Login from "./pages/Login";
import ForgotPassword from "./pages/Login/ForgotPassword";
import Register from "./pages/Register";
import Scheduler from "./pages/Scheduler/Week";
import SchedulerView from "./pages/Scheduler/Day";
import SchedulerLoadOut from "./pages/SchedulerLoadOut";
import Stations from "./pages/Stations";
import Employees from "./pages/Employees";
import VehicleManagement from "./pages/Vehicles/VehicleManagement";
import VanAffinity from "./pages/Vehicles/VanAffinity";
import VehicleImageCategories from "./pages/Vehicles/VehicleImageCategories";
import VanAutoAssignment from "./pages/Vehicles/VanAutoAssignment";
import VehicleImageNew from "./pages/Vehicles/VehicleImage";
import Vehicles from "./pages/Vehicles";
import StationNavigation from "./pages/Stations/Navigator";
import Skills from "./pages/Skills";
import Devices from "./pages/Devices";
import Dashboard from "./pages/Dashboard";
import RouteCommitment from "./pages/Routes/RouteCommitment";
import ScheduleBalance from "./pages/Routes/ScheduleBalance";
import EmployeeBalance from "./pages/Routes/Employee";
import Settings from "./pages/Settings";
import Incidents from "./pages/Incidents";
import Reports, { DynamicRoute } from "./pages/Reports";
import Dynamic from "./pages/Reports/Dynamic";
import VanNavigator from "./pages/Reports/VanNavigator";
import Mileage from "./pages/Reports/VanNavigator/Mileage";
import Insurance from "./pages/Reports/VanNavigator/Insurance";
import IncidentForm from "./pages/Incidents/form";
import { Token } from "./hooks/useGlobalData";
import { AuthContext, getChatIdentifier, getToken, useAuth } from "./Auth";
import { engine, resetApp } from "./Utilities/index";
import { useSelectedStation } from "./components/StationSelector";
import EditProfile from "./pages/EditProfile";
import { alert } from "./components/Alert";
import { DevWarning } from "./components/UI";
import toast from "./components/Toasts/toast";
import { AccessCheck, IsGranted } from "./security";
import * as SettingsSections from "./pages/Settings/Sections";
import { Icon, Theme } from "@dspworkplace/ui";
import Announcements from "./pages/Announcements";
import * as AnnouncementsSections from "./pages/Announcements/Sections";
import Template from "./pages/Reports/Scorecards/SortTemplate";
import WorkFlowBuilder from "./pages/WorkFlowBuilders";
import Forms from "./pages/WorkFlowBuilders/Forms";
import Workflow from "./pages/WorkFlowBuilders/Workflow";

import { Provider as ChatProvider } from "./Chat/store";
import ChatApp from "./Chat/AppChat";
import CrispChat from "./Utilities/Crisp";

import VehicleMaintenanceTemplate from "./pages/Vehicles/Maintenance/template";
import VehicleMaintenanceTasks from "./pages/Vehicles/Maintenance/tasks";


// Scorecard
import ScorecardOverall from "./pages/Scorecard/Sections/overall";
import ScorecardDrivers from "./pages/Scorecard/Sections/drivers";
import ScorecardTeams from "./pages/Scorecard/Sections/teams";
import ScorecardCoaching from "./pages/Scorecard/Sections/coaching";
import ScorecardDocuments from "./pages/Scorecard/Sections/documents";
import CompanyDocuments from "./pages/CompanyDocuments/documents";
import ScorecardDriverApp from "./pages/Scorecard/Sections/app";

export const ROUTES = [
    {
        path: "/login",
        component: Login,
        useMenu: false,
        isAuthorized: false,
    },
    {
        path: "/forgot-password",
        component: ForgotPassword,
        useMenu: false,
        isAuthorized: false,
    },
    {
        path: "/register",
        component: Register,
        useMenu: false,
        isAuthorized: false,
    },
    // {
    //     name: "Home",
    //     path: "/home",
    //     component: Home,
    //     renderMenu: false,
    //     isAuthorized: true
    // },
    {
        name: "Dashboard",
        path: "/dashboard",
        component: Dashboard,
        isAuthorized: true,
        isResetPasswordMenu: true,
        feature: AccessCheck.features.DASHBOARD,
    },
    {
        name: "Scheduler",
        path: "/scheduler",
        isAuthorized: true,
        isStation: true,
        isResetPasswordMenu: true,
        routes: [
            {
                name: "Week",
                path: "/week",
                component: Scheduler,
                isAuthorized: false,
                renderMenu: false,
            },
            {
                name: "Day",
                path: "/day",
                component: SchedulerView,
                isAuthorized: false,
                renderMenu: false,
            },
            {
                name: "Load Out",
                path: "/loadout",
                isAuthorized: false,
                renderMenu: false,
                component: SchedulerLoadOut,
            },
        ],
    },
    {
        name: "Routes",
        path: "/routes",
        isAuthorized: true,
        isStation: true,
        isResetPasswordMenu: true,
        routes: [
            {
                name: "Route Commitment",
                path: "/route-commitment",
                component: RouteCommitment,
                feature: AccessCheck.features.ROUTE_COMMITMENT,
            },
            {
                name: "Schedule Balance",
                path: "/schedule-balanace",
                component: ScheduleBalance,
                feature: AccessCheck.features.SCHEDULE_BALANCE,
            },
            {
                name: "Employee Balance",
                path: "/employee-balance",
                component: EmployeeBalance,
            },
        ],
    },
    {
        name: "Incident Form",
        path: "/incidents/:id",
        matchPath: "/incidents/:id",
        isAuthorized: true,
        component: IncidentForm,
        renderMenu: false,
        feature: AccessCheck.features.INCIDENTS,
    },
    {
        name: "Incidents",
        path: "/incidents",
        isAuthorized: true,
        component: Incidents,
        isStation: true,
        isResetPasswordMenu: true,
        feature: AccessCheck.features.INCIDENTS,
    },
    {
        name: "Scorecard",
        path: "/scorecard",
        isAuthorized: true,
        isResetPasswordMenu: true,
        isStation: true,
        feature: AccessCheck.features.SCORECARD,
        routes: [
            // {
            //     name: <>Overall</>,
            //     path: '/overall',
            //     component: ScorecardOverall,
            // },
            {
                name: <>Coaching</>,
                path: "/coaching",
                component: ScorecardCoaching,
            },
            {
                name: <>Teams</>,
                path: "/teams",
                component: ScorecardTeams,
            },
            {
                name: "Driver Performance",
                path: "/driver-performance",
                component: ScorecardDrivers,
            },
            {
                name: "Driver App",
                path: "/driver-app",
                component: ScorecardDriverApp,
            },
            {
                name: "Documents",
                path: "/documents",
                component: ScorecardDocuments,
            },
        ],
    },
    {
        name: "Company Management",
        path: "/company-management",
        isResetPasswordMenu: true,
        routes: [
            {
                name: "Stations",
                path: "/stations",
                matchPath: "/stations",
                component: Stations,
            },
            {
                name: "Stations Menu",
                path: "/station/:id",
                matchPath: "/station/:id",
                component: StationNavigation,
                renderMenu: false,
            },
            {
                name: "Skills",
                path: "/skills",
                component: Skills,
            },
            {
                name: "Employees",
                path: "/employees",
                matchPath: "/employee",
                component: Employees,
                isStation: true,
            },
            {
                name: "Devices",
                path: "/devices",
                component: Devices,
                isStation: true,
            },
            {
                name: "Vehicles",
                path: "/vehicles",
                component: Vehicles,
                isStation: true,
                routes: [
                    { name: "Management", path: "/vehicle-management", component: VehicleManagement },
                    { name: "Images", path: "/vehicle-image/:id", component: VehicleImageNew },
                    { name: "Image Categories", path: "/image_categories", component: VehicleImageCategories },
                    { name: "Van Affinity", path: "/van-affinity", component: VanAffinity },
                    // {
                    //     name: "Vehicle Image Viewer",
                    //     path: '/vehicle-image/:id',
                    //     component: VehicleImage,
                    //     renderMenu: false
                    // },
                    {
                        name: "Van Auto Assignment",
                        path: "/van-auto-assignment",
                        component: VanAutoAssignment,
                        // isCompany: [85, 104]
                    },
                    {
                        name: "Tasks",
                        path: "/maintenance/tasks",
                        component: VehicleMaintenanceTasks,
                        // isCompany: [85, 104]
                    },
                    {
                        name: "Template",
                        path: "/maintenance/template",
                        component: VehicleMaintenanceTemplate,
                        // isCompany: [85, 104]
                    },
                ],
            },
            {
                name: "Announcements",
                path: "/announcements",
                component: Announcements,
                isStation: true,
                feature: AccessCheck.features.ANNOUNCEMENTS,
                routes: [
                    { name: "Messages", path: "/messages", component: AnnouncementsSections.Messages },
                    { name: "Templates", path: "/template", component: AnnouncementsSections.Template },
                    //{name: "Segments",path:'/segment',component:AnnouncementsSections.Segment}
                ],
            },
            {
                name: "WorkFlow Builders",
                path: "/workflowbuilder",
                component: WorkFlowBuilder,
                isStation: true,
                renderMenu: true,
                isCompany: [85],
                routes: [
                    { name: "Forms", path: "/forms", component: Forms },
                    { name: "Workflow", path: "/workflow", component: Workflow },
                ],
            },
            {
                name: "Reports",
                path: "/custom-reports",
                component: Reports,
            },
            {
                name: "Documents",
                path: "/documents",
                component: CompanyDocuments,
            },
        ],
    },
    {
        name: "Reports",
        path: "/custom-reports",
        isAuthorized: true,
        component: Reports,
        renderMenu: false,
        isResetPasswordMenu: true,
        isStation: true,
        feature: AccessCheck.features.VAN_REPORT,
        routes: [
            {
                name: "Van Reports",
                description: "Download your vans mileage report",
                path: "/van",
                matchPath: "/custom-reports/van",
                component: VanNavigator,
                renderMenu: false,
                feature: AccessCheck.features.VAN_REPORT,
                routes: [
                    {
                        name: "Mileage",
                        path: "/mileage",
                        icon: Icon.Calendar,
                        component: Mileage,
                        default: true,
                        feature: AccessCheck.features.VAN_REPORT,
                    },
                    {
                        name: "Insurance",
                        path: "/insurance",
                        icon: Icon.Calendar,
                        component: Insurance,
                        default: true,
                        feature: AccessCheck.features.VAN_REPORT,
                    },
                ],
            },
            // {
            //     path: "/dynamic/:report",
            //     matchPath: "/custom-reports/dynamic/:report",
            //     isAuthorized: true,
            //     component: DynamicRoute,
            //     renderMenu: false,
            //     isResetPasswordMenu: true,
            //     isStation: false,
            //     isDynamic: true,
            // },
            {
                path: "/dynamic/:report/:subreport",
                matchPath: "/custom-reports/dynamic/:report/:subreport",
                isAuthorized: true,
                component: Dynamic,
                renderMenu: false,
                isResetPasswordMenu: true,
                isStation: false,
                isDynamic: true,
            },
        ],
    },
    {
        name: "Edit Profile",
        path: "/edit-profile",
        component: EditProfile,
        isAuthorized: true,
        renderMenu: false,
    },
    {
        name: "Settings",
        path: "/settings",
        component: Settings,
        isAuthorized: true,
        renderMenu: false,
        isResetPasswordMenu: true,
        feature: AccessCheck.features.SETTINGS,
        routes: [
            {
                name: "Company",
                routes: [
                    {
                        name: "Information",
                        path: "/company-information",
                        component: SettingsSections.CompanyInformation,
                    },
                    {
                        name: "Scheduler settings",
                        path: "/scheduler-settings",
                        component: SettingsSections.SchedulerSetting,
                    },
                    {
                        name: "Attendance settings",
                        path: "/attendance-settings",
                        component: SettingsSections.AttendanceSetting,
                    },
                ],
            },
            {
                name: "Subscription",
                routes: [
                    {
                        name: "Package",
                        path: "/package",
                        component: SettingsSections.SubscriptionPackage,
                    },
                    // {
                    //     name: "Payment Methods",
                    //     path: "/payment-methods",
                    //     component: SettingsSections.SubscriptionPaymentMethods
                    // },
                    {
                        name: "Invoices",
                        path: "/invoices",
                        component: SettingsSections.SubscriptionInvoices,
                    },
                ],
            },
            {
                name: "Integrations",
                routes: [
                    {
                        name: "RouteAssist&TRADE;",
                        path: "/routeassist",
                        component: SettingsSections.RouteAssistSettings,
                    },
                    {
                        name: "Paycom",
                        path: "/paycom",
                        component: SettingsSections.PaycomSettings,
                        feature: AccessCheck.features.PAYCOM,
                    },
                    {
                        name: "ADP",
                        path: "/adp",
                        component: SettingsSections.ADPSettings,
                    },
                    {
                        name: "Generate ADP Certificate",
                        path: "/certificate",
                        isCompany: [85],
                        component: SettingsSections.ADPCertificate,
                    },
                ],
            },
            {
                name: "Incident",
                routes: [
                    {
                        name: "Incident Setting",
                        path: "/incident/setting",
                        component: SettingsSections.IncidentSettings
                    }                    
                ]
            },
        ]
    }
];

export function useRouteByPath(path) {
    if (!path) path = window.location.pathname;

    const all = {};
    const getRoutes = (route) => {
        if (Array.isArray(route.routes))
            route.routes.forEach((subRoute) => {
                all[[route.path, subRoute.path].join("")] = subRoute;

                getRoutes(subRoute);
            });
    };

    ROUTES.forEach((route) => {
        all[route.path] = route;
        getRoutes(route);
    });

    return all[path];
}

const LayoutRouter = ({
    changeRoute,
    isAuthorized = true,
    useMenu = true,
    path,
    component,
    isStation,
    isResetPasswordMenu,
    ...rest
}) => {
    const [authToken] = Token();
    // const [stations, ] = useLocalStorage("selectedStation");
    const [selected] = useSelectedStation(null);
    let containerStyle = { padding: "0px" };
    if (!useMenu) {
        containerStyle.padding = "0";
    }

    if (isAuthorized && !authToken) {
        return <Redirect to="/login" />;
    }

    if (isResetPasswordMenu && localStorage.getItem("isResetPassword") == "true") {
        toast({
            type: "warning",
            title: "You must change your password first then you can access other sections.",
            useClose: true,
            timeout: 10000,
        });
        return <Redirect to="/edit-profile#user-informations" />;
    }

    if (isStation === true && !selected) {
        toast({
            type: "warning",
            title: "To use any section it's necessary to add one station !!!",
            useClose: true,
            timeout: false,
        });
        return <Redirect to="/company-management/stations" />;
    }
    if (
        !selected &&
        (window.location.pathname === "/company-management/devices" ||
            window.location.pathname === "/company-management/vehicles" ||
            window.location.pathname === "/company-management/employees")
    ) {
        toast({
            type: "warning",
            title: "To use any section it's necessary to add one station !!!",
            useClose: true,
            timeout: false,
        });
        return <Redirect to="/company-management/stations" />;
    }

    const renderGranted = (feature, component, props) => {
        const render = React.createElement(component, props);

        if (feature)
            return (
                <IsGranted renderWarning={true} feature={feature} renderLoading={true}>
                    {render}
                </IsGranted>
            );

        return render;
    };

    return (
        <>
            {useMenu && <Menu routes={ROUTES} />}
            <div style={containerStyle}>
                <Route
                    key={`route-${path}`}
                    path={path}
                    render={({ match: { url } }) => {
                        if (isAuthorized) localStorage.setItem("homepage", rest.location.pathname);

                        return (
                            <>
                                {component ? (
                                    <Route
                                        path={url}
                                        exact={
                                            url === "/" || (rest.routes && rest.routes.filter((r) => r.path).length > 0)
                                        }
                                        render={(props) => renderGranted(rest.feature, component, props)}
                                    />
                                ) : null}
                                {rest.routes &&
                                    rest.routes.map((subRoute, i) => {
                                        return (
                                            <Route
                                                key={`subroute-${url}${subRoute.path}`}
                                                path={[`${url}${subRoute.path}`]}
                                                render={(props) =>
                                                    renderGranted(subRoute.feature, subRoute.component, props)
                                                }
                                            />
                                        );
                                    })}
                            </>
                        );
                    }}
                />
            </div>
        </>
    );
};

const PlanDetailsListener = ({ api }) => {
    const { planTier, setPlanDetails } = useAuth();
    const setPlanDetailsListener = (e) => {
        //console.log(`useEffect =>_plandetails -> ${JSON.stringify({tier: e.detail[0], interval: e.detail[1]})}`); // 5 times
        setPlanDetails((d) => ({ tier: e.detail[0], interval: e.detail[1] }));
    };

    const fakeTier = async () => {
        await api.get("/ping", {});
    };
    useEffect(() => {
        if (!planTier) fakeTier().catch();
        window.addEventListener("_plandetails", setPlanDetailsListener, { once: true });
        return () => window.removeEventListener("_plandetails", setPlanDetailsListener);
    }, []);
    return null;
};

const RouteApp = () => {
    const [authData, setAuthData] = useState({ authToken: getToken(), twilioIdentity: getChatIdentifier() });
    const { authToken, twilioIdentity } = authData;
    const [planDetails, setPlanDetails] = useState({
        tier: undefined,
        interval: undefined,
    });

    const setAuthToken = useCallback((value) => {
        setAuthData((prev) => ({ ...prev, authToken: value }));
    }, []);

    const setTwilioIdentity = useCallback((value) => {
        setAuthData((prev) => ({ ...prev, twilioIdentity: value }));
    }, []);

    const isValidTokenAndIdentity = useCallback((token, identity) => {
        if (token === "null" || identity === "null") {
            localStorage.clear();
            window.location.reload();
            return false;
        }
        return typeof token === "string" && typeof identity === "string" && token.length > 500 && identity.length > 0;
    }, []);

    const isValidToken = useCallback(() => {
        if (authData.authToken === "null") {
            localStorage.clear();
            window.location.reload();
            return false;
        }
        return typeof authData.authToken === "string" && authData.authToken.length > 500;
    }, [authData.authToken]);

    const logout = useCallback(async () => {
        await resetApp();
    }, []);

    useEffect(() => {
        function mightShowAlert() {
            const isTouchDevice = "ontouchstart" in document.documentElement;

            if (window.matchMedia("(orientation: portrait)").matches && isTouchDevice) {
                alert({ text: "Please rotate device to use in landscape mode", icon: true });
            }
        }

        mightShowAlert();

        window.addEventListener("resize", mightShowAlert);

        return () => window.removeEventListener("resize", mightShowAlert);
    }, []);

    const CancelToken = axios.CancelToken;
    const AxiosSource = CancelToken.source();
    const api = engine();

    const ENV =
        process.env.NODE_ENV === "development" || window.location.href.match("devapp") ? "development" : "production";

    const homeVar = localStorage.getItem("homepage");

    return (
        <>
            <ChatProvider>
                {isValidTokenAndIdentity(authToken, twilioIdentity) && <ChatApp />}
                {/*<ChatApp/>*/}
            </ChatProvider>
            <AuthContext.Provider
                value={{
                    setAuthData,
                    setAuthToken,
                    setTwilioIdentity,
                    api,
                    AxiosSource,
                    logout,
                    ENV,
                    setPlanDetails,
                    planTier: planDetails.tier,
                    paymentInterval: planDetails.interval,
                }}
            >
                {ENV === "development" && <DevWarning />}
                <PlanDetailsListener api={api} />
                <CrispChat />
                <BrowserRouter>
                    <ToastContainer />
                    <Analytics>
                        <Switch>
                            {isValidToken() && <Redirect from="/login" to={homeVar ? homeVar : "/dashboard"} />}
                            {ROUTES.map((route, i) => (
                                <LayoutRouter key={`layoutRender-${route.path}`} {...route} />
                            ))}
                            {isValidToken() && homeVar && <Redirect from="*" to={homeVar} />}
                            {!isValidToken() && <Redirect from="*" to="/login" />}
                        </Switch>
                    </Analytics>
                </BrowserRouter>
            </AuthContext.Provider>
        </>
    );
};

export default RouteApp;
