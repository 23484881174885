import React, {forwardRef, useEffect} from "react";
import {CustomDropdown} from "@dspworkplace/ui";
import createPersistedState from 'use-persisted-state';
import styled from 'styled-components';
import {getCompanyId, getUserId, useLocalStorage} from "../../Auth";
import {engine} from "../../Utilities/index";
import {StationInclude} from "./schema";

export const useSelectedStation = createPersistedState('selected');

const FakeCss = styled.span `

    .open {
        ul {
            > li {
                padding-left:12px;
                padding-right:12px;
            }
        }
    }
`

const StationSelector = forwardRef(({
    showAllOption = true,
    ...props
}, ref) => {
    const api = engine();

    const value = props.defaultValue || null;
    const [selected, setSelected] = useSelectedStation(value);
    const [stations, setStations] = useLocalStorage('stations', []);


    useEffect(() => {
        const params = {actions: {response: {}}};

        if ( !stations || !stations.length )
            params.actions.response.Station = {"custom":
                {
                    functionName:"getStationByRole",
                    get:'stations',
                    criteria:{
                        company: getCompanyId(),
                        user: getUserId()
                    },
                    includes: StationInclude
                }
            };

        if ( selected === null )
            params.actions.response.Driver = {
                findOneBy: {
                    criteria: {
                        user: getUserId()
                    },
                    get: 'selected',
                    includes: {
                        stations: StationInclude
                    }
                }
            };

        if ( !Object.keys(params.actions.response).length )
            return ()=>{};

        api.post('/api/lazy/manage/data', params).then(
            result => {
                if (result.status === 200) {
                    if (result.data.data.stations)
                        setStations(result.data.data.stations);

                    let id = null;
                    if (result.data.data.selected)
                        id = result.data.data.selected.stations[0].id;

                    let list = result.data.data.stations || stations;

                    if ((list && list.length)
                        && (!id || (id && !list.map(s => s.id).includes(id))) )
                        id = list[0].id;

                    if (id)
                        setSelected(id);
                }
            },
            error => {}
        );
    }, []);

    const getOptions = () => {
        let ret = [];

        if ( stations.length )
            ret = ret.concat(stations.map(s => ({value: s.id, name: s.code})));

        if(ret.length)
            ret = ret.sort((a,b) => a.name.localeCompare(b.name))

        return ret;
    };

    const handleChange = value => {
        if ( typeof props.onChange === 'function' )
            props.onChange(value);

        setSelected(value);
    };

    let defaultValue = value || selected;
    if ( props.multiple ) {
        if (!Array.isArray(selected))
            defaultValue = [selected];
    } else if ( Array.isArray(selected) )
        defaultValue = selected[0];

    return (
        <FakeCss className={'test'}>
            <CustomDropdown
                options={getOptions()}
                size={'extraSmall'}
                label={'Station'}
                ref={ref}
                {...props}
                defaultValue={defaultValue}
                onChange={handleChange}
            />
        </FakeCss>
    )
});

export default StationSelector;
