import moment from "moment";
import { confirm } from "../../../components/Confirm";
import { getRole, getAllowsDispatcherPermission } from "../../../Auth";
import { alert } from "../../../components/Alert";

export const dragMoveCopy = async (item, monitor, tableRef, shiftsFromThisWeek, stateKey, dropSource,
    selectedRows = [], currDate, userId, state, dispatch, api, driver, setSelectedRows, multiEditShift) => {

    const checkWarning = async (item, shiftsFromThisWeek, stateKey, dropSource, message) => {
        const [rowSource, weekSource, indexSource] = item.stateKey;
        let cMessage;
        if (item.item.category == 8 || item.item.category == 9) {
            return true;
        }
        let dayBox = await state?.state?.filter((obj) => obj?.id == driver?.id)[0][
            "w" + moment().day(dropSource[1]?.slice(1)).weekday()
        ];
        if (dayBox[0]) {
            let returnOrNot = false;
            await dayBox.map((s) => {
                if (parseInt(s.category) == 8 || parseInt(s.category) == 9) {
                    returnOrNot = true;
                }
            });
            if (returnOrNot) {
                return true;
            }
        }
        if (localStorage.getItem('role') == 'ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE') {
            if (driver.id == "0.0.0" || rowSource == "0.0.0") {
                return alert("You don't have permission.");
            }
        }
        if (item.item.isRequested === true && item.item.isRequestCompleted === false) {
            return alert(`You cannot ${message} requested route`);
        } else if (item.item.isRescuer === true) {
            return alert(`You cannot ${message} rescue route`);
        } else if (item.item.isSecondShift === true) {
            return alert(`You cannot ${message} second shift`);
        } else if (item.item.isTrain === true) {
            return alert(`You cannot ${message} train route`);
        } else if (item.item.isDuty === true) {
            return alert(`You cannot ${message} light duty route`);
        } else if (rowSource == dropSource[0]) {
            if (weekSource == dropSource[1]) return true;
        } else if (dropSource[0].length > 0 && driver.id !== "0.0.0") {
            const { startTime, endTime, skill, type, isOpenShift } = item.item;
            var start = moment(startTime, "HH:mm");
            var end = moment(endTime, "HH:mm");

            // calculate total duration
            var duration = moment.duration(end.diff(start));
            // duration in hours
            var hours = parseInt(duration.asHours());

            const hoursToWork = hours;
            const newWorkHours = hoursToWork + driver.workHours;
            if (newWorkHours > 40) {
                cMessage = `${driver.name} will have more than 40 hours and will enter in overtime. 
                Do you confirm this change?`;
            }
            if (driver.skills && !driver.skills.includes(skill)) {
                if (!cMessage) {
                    cMessage = `${driver.name} does not have the skill ${type}. Do you confirm this change?`;
                } else {
                    cMessage = `${driver.name} does not have the skill ${type} and more than 40 hours. 
                    Do you confirm this change?`;
                }
            }

            if (cMessage) {
                const wc = await confirm(cMessage);
                if (!wc) return true;
            }
        }

        let cMessage1 = `Are you sure to ${message} this shift?`;
        if (cMessage1) {
            const wc1 = await confirm(cMessage1);
            if (!wc1) return true;
        }
        if (shiftsFromThisWeek) {
            const { minHour, maxHour } = shiftsFromThisWeek.reduce(
                (obj, shift) => {
                    obj.minHour = Math.min(obj.minHour, shift.startTime);
                    obj.maxHour = Math.max(obj.maxHour, shift.endTime);
                    return obj;
                },
                { minHour: 24, maxHour: 0 }
            );

            if (item.item.startTime >= minHour && item.item.startTime <= maxHour) {
                cMessage = `You are assigning ${driver.name} overlapping shifts. Do you wish to proceed?`;
            }
            if (item.item.endTime >= minHour && item.item.endTime <= maxHour) {
                cMessage = `You are assigning ${driver.name} overlapping shifts. Do you wish to proceed?`;
            }

            if (cMessage) {
                const wc = await confirm(cMessage);
                if (!wc) return true;
            }
        }
    }

    const checkSelectedDriverWarning = async (newDriverId, item, currDate, dropSource) => {
        if (newDriverId && newDriverId != "") {
            const params1 = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "checkSequenceDriverRoute",
                                get: "checkSequenceDriverRoute",
                                excludes: [],
                                criteria: {
                                    routeId: null,
                                    driverId: newDriverId,
                                    newDate: moment(currDate).day(dropSource[1].slice(1)).format("YYYY-MM-DD"),
                                },
                            },
                        },
                    },
                },
            };
            const response = await api.post("/api/lazy/manage/data", params1);
            if (response.data.data.checkSequenceDriverRoute.status) {
                const wc = await confirm(response.data.data.checkSequenceDriverRoute.message.web);
                if (!wc) return true;

                const params1 = {
                    actions: {
                        response: {
                            DriverRoute: {
                                custom: {
                                    functionName: "createEventForConsicutiveShift",
                                    get: "createEventForConsicutiveShift",
                                    excludes: [],
                                    criteria: {
                                        driverId: newDriverId,
                                        message: response.data.data.checkSequenceDriverRoute.message.bot,
                                    },
                                },
                            },
                        },
                    },
                };
                const response1 = await api.post("/api/lazy/manage/data", params1);
            }
        }
    }

    const multicheckSequenceDriverRoute = async (multiSelectPasteToDate, method) => {
        const pasteToDate = multiSelectPasteToDate.map((e) => e.pasteToDate)
        const copyToDate = selectedRows.map((e) => e.shiftDate)
        const params = {
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "checkSequenceDriverRoute",
                            get: "checkSequenceDriverRoute",
                            excludes: [],
                            criteria: {
                                routeId: multiSelectPasteToDate[0].routeId,
                                driverId: multiSelectPasteToDate[0].driverId,
                                timezoneOffset: -330,
                                newDate: moment(currDate).day(dropSource[1].slice(1)).format("YYYY-MM-DD"),
                                pasteToDate: pasteToDate,
                                copyToDate: copyToDate,
                                method: method
                            }
                        }
                    }
                }
            }
        }
        const response = await api.post("/api/lazy/manage/data", params);
        if (response.data.data.checkSequenceDriverRoute.status) {
            const wc = await confirm(response.data.data.checkSequenceDriverRoute.message.web);
            if (!wc) return true;

            const params1 = {
                actions: {
                    response: {
                        DriverRoute: {
                            custom: {
                                functionName: "createEventForConsicutiveShift",
                                get: "createEventForConsicutiveShift",
                                excludes: [],
                                criteria: {
                                    driverId: item.driver.id,
                                    message: response.data.data.checkSequenceDriverRoute.message.bot,
                                },
                            },
                        },
                    },
                },
            };
            const response1 = await api.post("/api/lazy/manage/data", params1);
        }
    }

    const dispatchMoveCopy = (item, dropSource, mode, dragTargets = null, tableRef) => {
        dispatch({
            type: "MOVE_OR_COPY",
            payload: {
                source: item.stateKey,
                item: item.item,
                rowIndex: dropSource[0],
                weekIndex: dropSource[1],
                mode: mode,
                dragTargets: dragTargets
            },
        });
        if (tableRef && tableRef.current) {
            tableRef.current.recomputeRowHeights();
        }
    }

    const moveDrop = async (item, tableRef, shiftsFromThisWeek, stateKey, dropSource) => {
        const [rowSource, weekSource, indexSource] = item.stateKey;
        let cMessage;
        let message = 'move';
        const warningResult = await checkWarning(item, shiftsFromThisWeek, stateKey, dropSource, message);
        if (warningResult) return;

        let newDriverId = state.state.filter((obj) => obj.id == dropSource[0])[0].driverId;

        if (newDriverId != undefined && newDriverId != null && "ROLE_DISPATCHER" == getRole() && getAllowsDispatcherPermission() == "false") {
            const driverSkillParam = {
                actions: {
                    response: {
                        Driver: {
                            custom: {
                                functionName: "checkDriverAndShiftSkillMatch",
                                get: "result",
                                excludes: [],
                                criteria: {
                                    driverId: newDriverId,
                                    shiftId: item.item.typeId,
                                },
                            },
                        },
                    },
                },
            };
            const responseDriverSkill = await api.post("/api/lazy/manage/data", driverSkillParam);
            if (!responseDriverSkill.data.data.result.result) {
                    alert({
                        text: 'WARNING: "' + responseDriverSkill.data.data.result.driverName + '" does not have the required "' + responseDriverSkill.data.data.result.shiftSkillName + '" skill to be assigned this shift type.',
                        btnText: "Cancel",
                    });
                    return false;
            }
        } 
            const checkSelectedWarning = await checkSelectedDriverWarning(newDriverId, item, currDate, dropSource);
            if (checkSelectedWarning) return;

            const params = {
                actions: {
                    response: {
                        TempDriverRoute: {
                            custom: {
                                functionName: "addTempDriverRoute",
                                get: "tempDriverRoute",
                                excludes: [],
                                criteria: {
                                    routeId: item.item.routeId ? item.item.routeId : null,
                                    isNew: item.item.isNew ? item.item.isNew : true,
                                    newDriverId: newDriverId && newDriverId != "" ? newDriverId : "0.0.0",
                                    routeStatus: item.item.routeStatus,
                                    newDate: item.item.currentWeek
                                        ? moment(currDate).day(dropSource[1].slice(1)).format("YYYY-MM-DD 00:00:00")
                                        : null,
                                    isOpenShift: item.item.isOpenShift ? item.item.isOpenShift : false,
                                    shiftId: item.item.typeId ? item.item.typeId : null,
                                    oldDriverId: rowSource,
                                    weekFrom: weekSource.replace(/[^\d]/gi, ""),
                                    weekTo: moment().day(dropSource[1].slice(1)).weekday(),
                                    indexSource: indexSource,
                                    hasAlert: !!cMessage,
                                    item: item.item,
                                    isTemp: false,
                                    startTime: item.item.utcStartTime,
                                    endTime: item.item.utcEndTime,
                                    shiftInvoiceType: item.item.shiftInvoiceType,
                                    timezoneOffset: new Date().getTimezoneOffset(),
                                    isDrag: true,
                                },
                            },
                        },
                    },
                },
            };
            
            try {
                const response = await api.post("/api/lazy/manage/data", params);
                item.item.isNew = true;
                item.item = response.data.data.tempDriverRoute;
                dispatchMoveCopy(item, dropSource, "move", [], tableRef);
            } catch (error) {
                console.error({ error });
                console.error("[ERROR]", error.message ? error.message : "");
            }
    }

    const copyDrop = async (item, dropSource) => {
        let message = 'Copy';
        const warningResult = await checkWarning(item, shiftsFromThisWeek, stateKey, dropSource, message);
        if (warningResult) return;

        let newDriverId = state.state.filter((obj) => obj.id == dropSource[0])[0].driverId;

        const checkSelectedWarning = await checkSelectedDriverWarning(newDriverId, item, currDate, dropSource);
        if (checkSelectedWarning) return;

        const pasteToDate = moment(currDate).day(dropSource[1].slice(1)).format("YYYY-MM-DD");

        const params = {
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "copyPreviousDataAndPasteNewData",
                            get: "driverRoute",
                            excludes: [],
                            criteria: [
                                {
                                    routeId: item.item.id,
                                    driverId: dropSource[0],
                                    pasteToDate: pasteToDate,
                                    userId: userId,
                                    timezoneOffset: -330,
                                    hasAlert: item.item.hasAlert,
                                    message: message,
                                    isOpenShift: false
                                }
                            ]
                        }
                    }
                }
            }
        }
        const response = await api.post("/api/lazy/manage/data", params);
        item.item = response.data.data.driverRoute.data[0][0];
        dispatchMoveCopy(item, dropSource, 'copy', [], tableRef);
    }

    const copyMultiSelected = async (item, dropSource) => {
        let message = 'Copy';
        let cMessage = `Are you sure to copy the selected shifts?`;
        if (cMessage) {
            const wc = await confirm(cMessage);
            if (!wc) return true;
        }
        const multiSelectPasteToDate = selectedRows.map((selected, index) => {
            const pasteToDate = moment(currDate).day(dropSource[1].slice(1)).add(index, 'day').format("YYYY-MM-DD");

            return {
                routeId: selected.id,
                driverId: dropSource[0],
                pasteToDate: pasteToDate,
                userId: userId,
                timezoneOffset: -330,
                hasAlert: selected.hasAlert,
                message: message,
                isOpenShift: false
            };
        });

        const checkSequenceDriverRoute = await multicheckSequenceDriverRoute(multiSelectPasteToDate, "copy")
        if (checkSequenceDriverRoute) return;

        const params1 = {
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "copyPreviousDataAndPasteNewData",
                            get: "driverRoute",
                            excludes: [],
                            criteria: multiSelectPasteToDate
                        }
                    }
                }
            }
        }
        const response1 = await api.post("/api/lazy/manage/data", params1);
        item.item = response1.data.data.driverRoute.data[0];
        const dragTargets = response1.data.data.driverRoute.data[0];
        const selectedRowLength = selectedRows.length;
        const dropSourceIndex = parseInt(dropSource[1].substring(1));
        let dragTarget = [];
        for (let i = dropSourceIndex; i < dropSourceIndex + selectedRowLength; i++) {
            let weekIndex = i % 7;
            if (weekIndex === 0) weekIndex = 7;
            dragTarget.push("w" + weekIndex);
        }

        dispatchMoveCopy(item, dropSource, 'multiSelectedCopy', dragTargets, tableRef);
        setSelectedRows([])
    }

    const moveMultiSelected = async (selectedRows, item, dropSource) => {
        let cMessage = `Are you sure to move the selected shifts?`;
        if (cMessage) {
            const wc = await confirm(cMessage);
            if (!wc) return true;
        }
        const weekToVal = dropSource[1];
        const weekTo = parseInt(weekToVal.replace(/\D/g, ''), 10);
        const weekFromVal = item.stateKey[1]
        const weekFrom = parseInt(weekFromVal.replace(/\D/g, ''), 10);
        const multiSelectPasteToDate = selectedRows.map((selected, index) => {
            const pasteToDate = moment(currDate).day(dropSource[1].slice(1)).add(index, 'day').format("YYYY-MM-DD");
            return {
                method: "addTemp",
                routeId: selected.hasAlert ? selected.routeId : selected.id,
                isNew: true,
                isTemp: false,
                hasAlert: false,
                newDriverId: dropSource[0],
                oldDriverId: item.stateKey[0],
                newDate: pasteToDate,
                routeStatus: selected.routeStatus,
                isVoluntary: selected.isVoluntary,
                startTime: selected.utcStartTime || selected.startTime,
                endTime: selected.utcEndTime || selected.endTime,
                shiftId: selected.shiftId || selected.typeId || selected.shiftType.id,
                timezoneOffset: new Date().getTimezoneOffset(),
                shiftInvoiceType: selected.shiftInvoiceType,
                autoApproveDriverRequest: selected.autoApproveDriverRequest,
                item: {
                    qty: selected.qty,
                    note: selected.note,
                    newNote: selected.newNote
                },
                backupStatus: selected.backupStatus,
                onCall: selected.onCall,
                type: selected.type,
                driverId: dropSource[0],
                isDrag: true,
                weekFrom: weekFrom.toString(),
                weekTo: weekTo,
                pasteToDate: pasteToDate
            };
        });
        const checkSequenceDriverRoute = await multicheckSequenceDriverRoute(multiSelectPasteToDate, 'move')
        if (checkSequenceDriverRoute) return;
        const response = await multiEditShift([], [], multiSelectPasteToDate, 'move');
        item.item = response.data.data.tempDriverRoute;
        const dragTargets = response.data.data.tempDriverRoute;

        dispatchMoveCopy(item, dropSource, 'multiSelectedMove', dragTargets, tableRef);
        setSelectedRows([])
    }

    const dropResult = monitor.getDropResult();
    item.method = dropResult?.dropEffect
    if (selectedRows.length > 0) {
        item.method = dropResult?.dropEffect === 'copy' ? 'multiSelectedCopy' : 'multiSelectedMove';
    }

    switch (item.method) {
        case 'multiSelectedCopy':
            copyMultiSelected(item, dropSource);
            break;
        case 'multiSelectedMove':
            moveMultiSelected(selectedRows, item, dropSource);
            break;
        case 'copy':
            copyDrop(item, dropSource);
            break;
        case 'move':
            await moveDrop(item, tableRef, shiftsFromThisWeek, stateKey, dropSource);
            break;
        default:
            await moveDrop(item, tableRef, shiftsFromThisWeek, stateKey, dropSource);
    }
}