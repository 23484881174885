import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import {
    Spacer,
    Dropdown,
    Input,
    TagInput,
    ButtonDropdown,
    CustomDropdown,
    Icon,
    Theme,
    CheckBox
} from "@dspworkplace/ui";
import toast from "../../../components/Toasts/toast";
import DatePicker from "../../../components/Calendar/picker";
import LOOKUPS from '../lookups';
import { fixWeekCondition, revertDateRangeToWeekNames } from '../fixWeekCondition';

// If DateRanges is updated make sure to update src/pages/Dashboard/components/dashboard.js removeACRTimeDimension function
const DateRanges = [
    { name: 'All time', value: '0' },
    { name: 'Custom',value: 'Custom' },
    { name: 'Today',value: 'Today' },
    { name: 'Yesterday',value: 'Yesterday' },
    { name: 'This week',value: 'This week' },
    { name: 'This month',value: 'This month' },
    { name: 'This quarter',value: 'This quarter' },
    { name: 'This year',value: 'This year' },
    { name: 'Last 7 days',value: 'Last 7 days' },
    { name: 'Last 14 days',value: 'Last 14 days' },
    { name: 'Last 30 days',value: 'Last 30 days' },
    { name: 'Last 90 days',value: 'Last 90 days' },
    { name: 'Last week',value: 'Last week' },
    { name: 'Last month',value: 'Last month' },
    { name: 'Last quarter', value: 'Last quarter' },
    { name: 'Last year',value: 'Last year' },
    { name: 'Next 30 Days',value: 'Next 30 Days' }
];

const solveMembers = (members) => {
    if(!members) return [];
    return members.map((d,k)=> ({name:d.value.description || d.value.shortTitle,value:d.value.name,key:k}))
}

const dimensionOptions = (availableMembers,filters,updateFilters) => {
    if(!availableMembers) return false;
    return availableMembers.map((m)=>
        ({text:m.value.description || m.value.shortTitle,fn: () => {
                let exist = false;
                exist = !!filters.filter((f) => f.member === m.value.name).length;
                if(!exist) updateFilters.add({dimension:m.value,operator:'equals',values:[]})


                if(exist){
                    toast({
                        type: 'Error',
                        title: "Filter already exist",
                    });
                }

            }})
    )
}

const timeDimensionOptions = (availableMembers, availableTimeDimensions, updateTimeDimensions, addToColumnOrder) => {
    if (!availableTimeDimensions) return false;
    const list = availableTimeDimensions.map((m) => {
        let exist = false;
        exist = !!availableMembers.filter((t) => t?.dimension?.name === m.name).length;
        return !exist ? ({
            text: m.shortTitle, fn: () => {
                if (!exist) {
                    updateTimeDimensions.add({ dimension: m, granularity: 'day' })
                    addToColumnOrder && addToColumnOrder(m?.name + "." + 'day')
                }
                if (exist) {
                    toast({
                        type: 'Error',
                        title: "Time dimension already exist",
                    });
                }
            }
        }) : null
    })
    return list?.filter(item => item !== null)
}

const ShowLabel = styled.div`
    font-size: 16px;
    font-weight: normal;
    font-family: Circe-Rounded;
    display: block;
    padding-bottom: 2px;
    color: #707070;
    margin-bottom: 12.5px;
    margin-left: -7px;
`;

const ChartFilters = ({
    measuresList,
    dimensionsList,
    updateFilters,
    filters,
    chartConfig
}) => {
    const [chartConfigData] = chartConfig
    const schema = chartConfigData.schema;

    let availableMembers = React.useMemo(()=>[...measuresList,...dimensionsList],[])

    const members = solveMembers(availableMembers);

    const addNewOptions = React.useMemo(()=>{
        return dimensionOptions(availableMembers,filters,updateFilters)
    },[schema,availableMembers,filters]);

    return (
        <>
            <Spacer top={4} />
            <ButtonDropdown
                text='Add Filter'
                divider={false}
                options={addNewOptions}
            />
            <Spacer top={filters.length > 0 ? 5 : 0} bottom={5}  style={{display: 'flex',alignItems: 'flex-end',flexDirection:'column'}}>
                {filters.map((f,k)=>(
                    <FilterOption
                        key={k}
                        filter={f}
                        members={members}
                        schema={schema}
                        updateFilters={updateFilters}
                        availableMembers={availableMembers}
                    />
                ))}
            </Spacer>
        </>
    );
}

const ChartFilterTimeDimension = ({
  availableTimeDimensions,
  updateTimeDimensions,
  timeDimensions,
  chartConfig,
  columnOrder,
  setColumnOrder,
  tabIndex,
  showTimeDimension,
  setShowTimeDimension,
  attendanceSetting
}) => {
    const [chartConfigData] = chartConfig
    const schema = chartConfigData.schema;

    let availableMembers = availableTimeDimensions;

    const addToColumnOrder = (data) => {
        const tempColumns = columnOrder ? { ...columnOrder } : {};
        let tableOrder = tempColumns?.tableOrder ?? [];
        let detailTableOrder = tempColumns?.detailTableOrder ?? [];
        if (tabIndex === 1 && tempColumns?.tableOrder) {
            tableOrder.push(data)
            tempColumns.tableOrder = tableOrder
        } else if (tabIndex === 2 && tempColumns?.detailTableOrder) {
            detailTableOrder.push(data)
            tempColumns.detailTableOrder = detailTableOrder
        }
        setColumnOrder(tempColumns)
    }

    const addNewOptions = React.useMemo(()=>{
        return timeDimensionOptions(timeDimensions,availableTimeDimensions,updateTimeDimensions, addToColumnOrder)
    },[schema,timeDimensions]);


    return (
        <>
            <Spacer top={4} />
            <ButtonDropdown
                text='Add Time Dimension'
                divider={false}
                options={addNewOptions}
            />
            <Spacer top={timeDimensions.length > 0 ? 5 : 0} bottom={5}  style={{display: 'flex',alignItems: 'flex-end',flexDirection:'column'}}>
                {timeDimensions.map((t,k)=>(
                    <FilterTimeOption
                        key={k}
                        time={t}
                        availableTimeDimensions={availableTimeDimensions}
                        updateTimeDimensions={updateTimeDimensions}
                        columnOrder={columnOrder}
                        setColumnOrder={setColumnOrder}
                        tabIndex={tabIndex}
                        showTimeDimension={showTimeDimension}
                        setShowTimeDimension={setShowTimeDimension}
                        chartConfig={chartConfig}
                        attendanceSetting={attendanceSetting}
                    />
                ))}
            </Spacer>
        </>
    );
}

const FilterOption = ({filter,members,schema,updateFilters,availableMembers}) => {
    const getOperators = React.useCallback(()=>{
        return filter.operators.map((o)=>({name:o.title,value:o.name}))
    },[filter])
    const lookup = React.useMemo(()=>{
        if(!filter.dimension) return false;

        const dimension = filter.dimension.name.toLowerCase();
        const [schema,column] = dimension.split('.');

        if (LOOKUPS[schema] && LOOKUPS[schema][column]) {
            return LOOKUPS[schema][column].map((option) => ({
                name: option.startsWith("ROLE_") ? option.replace("ROLE_", "").replace(/_/g, " ") :
                    option, value: option
            }));
        }
    },[filter.dimension])

    return (
        <div style={{display: 'flex',marginBottom:12,alignItems: 'flex-end',justifyContent:'space-between',width:'100%'}}>
            <Spacer right={5} style={{display: 'flex'}}>
                <Dropdown
                    name={'dimensions'}
                    label={'Dimension'}
                    options={members}
                    onChange={d=> updateFilters.update(filter,{...filter,dimension:availableMembers[d.key].value})}
                    defaultValue={filter.member}
                />
            </Spacer>
            <Spacer right={5}>
                <CustomDropdown
                    name={'type'}
                    label={'Type'}
                    size={'small'}
                    options={getOperators()}
                    defaultValue={filter.operator}
                    visibleOptionsQty={10}
                    onChange={op=>updateFilters.update(filter,{...filter,operator:op})}
                />
            </Spacer>
            <Spacer right={3}>
                <FilterCriteria
                    filter={filter}
                    updateFilters={updateFilters}
                    schema={schema}
                    lookup={lookup}
                />
            </Spacer>
            <Icon.Trash
                size={'30px'}
                style={{cursor: 'pointer',marginBottom:5}}
                color={Theme.colors.error.border}
                onClick={()=>{
                    updateFilters.remove(filter)
                }}
            />

        </div>
    )
}

const FilterCriteria = ({schema,updateFilters,filter,lookup}) => {
    const stations = React.useCallback(() => {
        const stationList = JSON.parse(localStorage.getItem("stations"));
        return (Array.isArray(stationList) ? stationList : []).map((s) => ({ value: s.code, name: s.code }))
    }, [])

    const pureName = filter.member.replace(`${schema}.`,'').toLowerCase();

    if(['set','notSet'].includes(filter.operator)){
        return <Input
            style={{opacity:0}}
        />;
    }

    if(['contains','notContains','equals','notEquals'].includes(filter.operator)){
        if(pureName === 'station'){
            const v = typeof(filter.values) === 'object' ? filter.values : [filter?.values]
            return (
                <CustomDropdown
                    options={stations()}
                    label={'Criteria'}
                    onChange={s =>  setTimeout(()=>updateFilters.update(filter,{...filter,values: s }),10)}
                    defaultValue={v}
                    multiple
                />
            )
        }

        return  <TagInput
            name={'critera'}
            label={'Criteria'}
            canCreate
            defaultValues={typeof(filter.values) === 'object' ? filter.values : [filter?.values]}
            options={lookup}
            onChange={(c)=>{
                updateFilters.update(filter,{...filter,values: c.map((i)=>i.value) });
            }}
        />
    }

    if(filter.dimension.type === 'number'){
        return <Input
            type={'number'}
            name={'criteria'}
            label={'Criteria'}
            defaultValue={typeof(filter.values) === 'object' ? filter.values[0] : filter?.values}
            onBlur={(e)=>updateFilters.update(filter,{...filter,values: [e.target.value] })}
        />
    }
    return (
        <Input
            name={'criteria'}
            label={'Criteria'}
            defaultValue={typeof(filter.values) === 'object' ? filter.values[0] : filter?.values}
            onBlur={(e)=>updateFilters.update(filter,{...filter,values: [e.target.value] })}
        />
    )
};

const FilterTimeOption = ({
    time, availableTimeDimensions, updateTimeDimensions, chartConfig,
    columnOrder, setColumnOrder, tabIndex, showTimeDimension, setShowTimeDimension, attendanceSetting
}) => {
    const times = React.useMemo(()=>{
        return availableTimeDimensions.map((t,k)=>{
            return {name:t.shortTitle,value:t.name,key:k}
        })
    },[time])
    const checkBoxRef = useRef()
    const [isCustom, setIsCustom] = useState(false);

    const [chartConfigData] = chartConfig;
    const schema = chartConfigData.schema;

    const removeFromColumn = (item) => {
        const tempColumns = columnOrder ? { ...columnOrder } : {};
        let tableOrder = tempColumns?.tableOrder ?? [];
        let detailTableOrder = tempColumns?.detailTableOrder ?? [];
        const name = item?.dimension?.name + "." + item?.granularity;
        if (tabIndex === 1 && tempColumns?.tableOrder) {
            let indexToRemove = tableOrder.indexOf(name);
            if (indexToRemove !== -1) {
                tableOrder.splice(indexToRemove, 1);
            }
            tempColumns.tableOrder = tableOrder
            setColumnOrder(tempColumns)
        } else if (tabIndex === 2 && tempColumns?.detailTableOrder) {
            let indexToRemove = detailTableOrder.indexOf(name);
            if (indexToRemove !== -1) {
                detailTableOrder.splice(indexToRemove, 1);
            }
            tempColumns.detailTableOrder = detailTableOrder
            setColumnOrder(tempColumns)
        }
    }

    const updateColumnOrder = (time, newItem, isGranular) => {
        const oldName = time?.dimension?.name + "." + time?.granularity;
        let newName = "";
        if(isGranular){
            newName = time?.dimension?.name + "." + newItem
        } else {
            newName = newItem?.name + "." + time?.granularity
        }
        const tempColumns = columnOrder ? { ...columnOrder } : {};
        let tableOrder = tempColumns?.tableOrder ?? [];
        let detailTableOrder = tempColumns?.detailTableOrder ?? [];
        if (tabIndex === 1 && tempColumns?.tableOrder) {
            let indexToUpdate = tableOrder.indexOf(oldName);
            if (indexToUpdate !== -1) {
                tableOrder[indexToUpdate] = newName;
            }
            tempColumns.tableOrder = tableOrder
            setColumnOrder(tempColumns)
        } else if (tabIndex === 2 && tempColumns?.detailTableOrder) {
            let indexToUpdate = detailTableOrder.indexOf(oldName);
            if (indexToUpdate !== -1) {
                detailTableOrder[indexToUpdate] = newName;
            }
            tempColumns.detailTableOrder = detailTableOrder
            setColumnOrder(tempColumns)
        }
    }

    const handleCheckBox = (s, granular) => {
        const tempTimeDimensions = showTimeDimension ? { ...showTimeDimension } : {};
        const currentTabTimeDimensions = tabIndex === 1 ? 'basicTimeDimension' : 'detailTimeDimension'
        let basicTimeDimension = showTimeDimension?.basicTimeDimension ?? [];
        let detailTimeDimension = showTimeDimension?.detailTimeDimension ?? [];
        let currentTimeDimensionArray = tabIndex === 1 ? basicTimeDimension : detailTimeDimension
        if (granular) {
            const oldName = s?.currentTarget?.name;
            const index = currentTimeDimensionArray.indexOf(oldName);
            if (index !== -1) {
                currentTimeDimensionArray[index] = s?.currentTarget?.newName;
            }
        } else {
            if (s.currentTarget.checked) {
                currentTimeDimensionArray.push(s?.currentTarget?.name)
            } else {
                currentTimeDimensionArray = currentTimeDimensionArray.filter(c => c !== s?.currentTarget?.name);
            }
        }
        tempTimeDimensions[currentTabTimeDimensions] = currentTimeDimensionArray
        setShowTimeDimension(tempTimeDimensions)
    }

    const updateGranularTimeDimension = (v) => {
        handleCheckBox({
            currentTarget: {
                checked: false,
                name: time.dimension.name + "." + time.granularity,
                newName: time?.dimension?.name + "." + v
            }
        }, v)
    }

    const updateNameTimeDimension = (newItem) => {
        handleCheckBox({
            currentTarget: {
                checked: false,
                name: time.dimension.name + "." + time.granularity,
                newName: newItem?.name + "." + time?.granularity
            }
        }, true)
    }

    const getDefaultTimeDimension = (name, e) => {
        const tempDimension = (tabIndex === 1 ? showTimeDimension?.basicTimeDimension : showTimeDimension?.detailTimeDimension) || []
        if (checkBoxRef?.current?.type === 'checkbox') {
            checkBoxRef.current.checked = tempDimension.includes(name);
        }
    }

    const removeFromTimeDimension = () => {
        handleCheckBox({
            currentTarget: {
                checked: false,
                name: time.dimension.name + "." + time.granularity
            }
        })
    }

    useEffect(() => {
        getDefaultTimeDimension(time.dimension.name + "." + time.granularity)
    }, [showTimeDimension, time])

    const showCheckBox = chartConfigData?.type === 'table'
    const revertDateRangeValue = revertDateRangeToWeekNames(time.dateRange)

    return (
        <div style={{ display: 'flex', marginBottom: 12, alignItems: 'flex-end', justifyContent: 'space-between', width: '100%' }}>
            {showCheckBox &&
                <div style={{ marginBottom: 8, marginRight: -8 }}>
                    <ShowLabel>Show</ShowLabel>
                    <CheckBox
                        name={time.dimension.name + "." + time.granularity}
                        title={""}
                        ref={checkBoxRef}
                        onClick={handleCheckBox}
                        options={[{ value: true, label: "" }]}
                    />
                </div>}
            <Spacer right={showCheckBox ? 1 : 5} style={{ display: 'flex' }}>
                <CustomDropdown
                    name={'dimensions'}
                    label={'Dimension'}
                    options={times}
                    onChange={(d) => {
                        const item = availableTimeDimensions?.find(a => a?.name === d)
                        updateTimeDimensions.update(time, { ...time, dimension: item });
                        updateColumnOrder(time, item)
                        updateNameTimeDimension(item)
                    }}
                    defaultValue={time.dimension.name}
                    size={'small'}
                />
            </Spacer>
            <Spacer right={showCheckBox ? 1 : 5}>
                <Dropdown
                    name={'for'}
                    label={'For'}
                    size={'small'}
                    options={DateRanges}
                    defaultValue={!time.dateRange ? '0' : typeof (time.dateRange) === 'object' ?  (isCustom ? 'Custom' : revertDateRangeValue) : time.dateRange}
                    visibleOptionsQty={10}
                    onChange={(f) => {
                        let v = f.value;
                        if (v === '0') v = undefined;
                        if (v === 'Custom') {
                            setIsCustom(true);
                        } else {
                            setIsCustom(false);
                        }
                        const queryWeek = fixWeekCondition({ timeDimensions: [{ ...time, dateRange: v }] })
                        updateTimeDimensions.update(time, queryWeek.timeDimensions[0]);
                    }}
                />
            </Spacer>
            <Spacer right={showCheckBox ? 1 : 5}>
                <Dropdown
                    name={'by'}
                    label={'By'}
                    size={'small'}
                    options={time.dimension.granularities.map((g) => ({ value: g.name || '0', name: g.title || 'w/o grouping' }))}
                    defaultValue={time.granularity || '0'}
                    visibleOptionsQty={10}
                    onChange={(f) => {
                        let v = f.value;
                        if (v === '0') v = undefined;
                        updateTimeDimensions.update(time, { ...time, granularity: v });
                        updateColumnOrder(time, v, true)
                        updateGranularTimeDimension(v)
                    }}
                />
            </Spacer>
            <Spacer right={showCheckBox ? 2 : 3}>
                {time.dateRange && (time.dateRange === 'Custom' || (typeof (time.dateRange) === 'object' && revertDateRangeValue === 'Custom') || isCustom)
                    ?
                    <DatePicker
                        name={'date'}
                        label={'Date'}
                        range={'date'}
                        defaultValue={typeof (time.dateRange) === 'object' ? [new Date(time.dateRange[0]), new Date(time.dateRange[1])] : undefined}
                        onChange={(d) => {
                            const dates = d.split(' – ');
                            updateTimeDimensions.update(time, { ...time, dateRange: dates });
                        }}
                    />
                    :
                    <Input type={'hidden'} />
                }

            </Spacer>
            <div>
                <Icon.Trash
                    size={'30px'}
                    style={{ cursor: 'pointer', marginBottom: 5 }}
                    color={Theme.colors.error.border}
                    onClick={() => {
                        updateTimeDimensions.remove(time)
                        removeFromColumn(time)
                        removeFromTimeDimension()
                    }}
                />
            </div>
        </div>
    )
}

export {ChartFilters,ChartFilterTimeDimension}