import React, {useContext, useEffect, useReducer, useState} from 'react';
import styled from 'styled-components';
import {CustomButton} from "../index";
import Week from "./Week";
import {Spacer, CheckBox, Button} from '@dspworkplace/ui';
import {SchedulesAddStateContext} from "../index";
import {SchedulesAddDispatchContext} from "../index";
import {useForm} from "react-hook-form";
import AddShiftType from "./AddShiftType";
import moment from "moment";
import { confirm } from "../../../../../../components/Confirm";


export const Grid = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: ${props=>props.templateColumns ? props.templateColumns : 'auto'};
    grid-column-gap: ${props=>props.columnGap ? props.columnGap : '0'};
    grid-template-rows: ${props=>props.templateRows ? props.templateRows : 'auto'};
    grid-row-gap: ${props=>props.rowGap ? props.rowGap : '0'};
    justify-content: ${props=>props.justifyContent ? props.justifyContent : 'normal'};
    align-items: ${props=>props.alignItems ? props.alignItems : 'center'};
    padding: ${props=>props.padding ? props.padding : '0'};
    margin: ${props=>props.margin ? props.margin : '0'};
    border: ${props=>props.border ? props.border : 'unset'};
    border-bottom: ${props=>props.borderBottom ? props.borderBottom : 'unset'};
    border-top: ${props=>props.borderTop ? props.borderTop : 'unset'};
    border-left: ${props=>props.borderLeft ? props.borderLeft : 'unset'};
    border-right: ${props=>props.borderRight ? props.borderRight : 'unset'};
    border-radius: ${props=>props.borderRadius ? props.borderRadius : 'unset'};
    font-size: ${props=>props.fontSize ? props.fontSize : '14px'};
    font-weight: ${props=>props.fontWeight ? props.fontWeight : 'normal'};
    color: ${props=>props.color ? props.color : '#333333'};
    background-color: ${props=>props.backgroundColor ? props.backgroundColor : '#FFFFFF'};
    text-decoration: ${props=>props.textDecoration ? props.textDecoration : 'unset'};
    text-align: ${props=>props.textAlign ? props.textAlign : 'unset'};
    width: ${props=>props.width ? props.width : 'auto'};
    height: ${props=>props.height ? props.height : 'auto'};
    cursor: ${props=>props.cursor ? props.cursor : 'auto'};
`;

export const Row = styled.div`
    box-sizing: border-box;
    display: grid;
    grid-template-columns: ${props=>props.templateColumns ? props.templateColumns : '70px 196px repeat(7, 104px)'};
    grid-template-rows: ${props=>props.templateRows ? props.templateRows : 'auto'};
    justify-content: ${props=>props.justifyContent ? props.justifyContent : 'flex-start'};
    align-items: ${props=>props.alignItems ? props.alignItems : 'center'};
    padding: ${props=>props.padding ? props.padding : '0'};
    margin: ${props=>props.margin ? props.margin : '0'};
    border: ${props=>props.border? props.border : '0'};
    border-bottom: ${props=>props.borderBottom ? props.borderBottom : '0'};
    border-right: ${props=>props.borderRight ? props.borderRight : '0'};
`;

export const Text = styled.div`
    box-sizing: border-box;
    display: grid;
    justify-self: ${props=>props.justifySelf ? props.justifySelf : 'auto'};
    align-items: ${props=>props.alignItems ? props.alignItems : 'center'};
    font-family: circe-rounded;
    font-size: 14px;
    color: #707070;
    border: ${props=>props.border? props.border : '0'};
    border-bottom: ${props=>props.borderBottom ? props.borderBottom : '0'};
    border-right: ${props=>props.borderRight ? props.borderRight : '0'};
    text-align: ${props=>props.textAlign ? props.textAlign : 'center'};
    padding: ${props=>props.padding ? props.padding : '0'};
    margin: ${props=>props.margin ? props.margin : '0'};
    width: ${props=>props.width ? props.width : '100%'};
    height: ${props=>props.height ? props.height : '100%'};
`;

export const CheckBoxCustom = styled(CheckBox)`
    padding: ${props=>props.padding ? props.padding : '0'};
    margin: ${props=>props.margin ? props.margin : '0'};
`;

export const FlexBox = styled.div`
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 0 0 24%;
    border-bottom: 1px dashed #CBD6E2;
`;

const TableData = styled.div`
    display: inline-block;
    margin:5px;
    display:inline-block;
`;

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const daysCheckBox = [{"Sun": 0}, {"Mon": 0}, {"Tue": 0}, {"Wed": 0}, {"Thu": 0}, {"Fri": 0}, {"Sat":0}];
function weeksBetween(d1) {
    if (moment(d1).day() === 0) {
        return moment(d1, 'DD-MM-YYYY').add(1, 'days').isoWeek();
    } else {
        return moment(d1, 'DD-MM-YYYY').isoWeek();
    }
}

function diff_weeks(start, end) {

    start = new Date(start);
    end = new Date(end);

    let startYear ;
    let endYear;
    if (moment(start).day() === 0) {
        startYear = moment(start).add(1, 'days').utc().isoWeekYear();
    } else {
        startYear = moment(start).utc().isoWeekYear();
    }

    if (moment(end).day() === 0) {
        endYear = moment(end).add(1, 'days').utc().isoWeekYear();
    } else {
        endYear = moment(end).utc().isoWeekYear();
    }

    let startWeekNumber = 0;
    let endWeekNumber = 0;
    if (moment(start).day() === 0) {
        startWeekNumber = moment(start, 'DD-MM-YYYY').add(1, 'days').utc().isoWeek();
    } else {
        startWeekNumber = moment(start, 'DD-MM-YYYY').utc().isoWeek();
    }

    if (moment(end).day() === 0) {
        endWeekNumber = moment(end, 'DD-MM-YYYY').add(1, 'days').utc().isoWeek();
    } else {
        endWeekNumber = moment(end, 'DD-MM-YYYY').utc().isoWeek();
    }

    let lastDateWeekNumber = moment(startYear+'-12-31').utc().isoWeek();

    if( startYear === endYear ){
        return  (endWeekNumber - startWeekNumber)+1;
    } else {
        let weekno = lastDateWeekNumber + endWeekNumber;
        return  (weekno - startWeekNumber)+1;
    }
 }

const reducer = (state, action) => {
    switch (action.type) {
        case 'delete':
           state.shiftTypes[action.row].splice(action.col, 1);
            /*if(action.row > 0){
                if(state.shiftTypes[action.row].length === 0){
                    state.weekArr.splice(action.row, 1);
                }
            }*/
            return {...state};
        case 'setOpen':
            return {...state, open: action.open, selectAll: false, week: action.week, searchShiftTypes:[]};
        case 'selectAll':
            if(!state.selectAll === true){
                document.querySelectorAll("[data-shift-type='shift-type']").forEach(item=>{item.checked = true});
            }else{
                document.querySelectorAll("[data-shift-type='shift-type']").forEach(item=>{item.checked = false});
            }
            return {...state, selectAll: !state.selectAll};
        case 'setSearchShiftTypes':
            return {...state, searchShiftTypes: action.searchShiftTypes, loading: action.loading};
        case 'addSelectedShiftTypes':
            let index = action.week-1;
            if(!Array.isArray(state.shiftTypes[index])){
                state.shiftTypes[index] = [...action.selectedShiftTypes];
            }else{
                state.shiftTypes[index] = [...state.shiftTypes[index], ...action.selectedShiftTypes];
            }
            return {...state, searchShiftTypes:[], open: action.open,isCopyWeek:action.isCopyWeek};
        case 'addWeek':
            state.weekArr.push(state.weekArr[state.weekArr.length-1]+1);
            return {...state};
        case 'removeWeek':
            let i = state.weekArr.indexOf(action.week);
            state.weekArr.splice(i, 1);
            state.shiftTypes.splice(i, 1);
            for (i; i <= state.weekArr.length - 1; i++)
                state.weekArr[i]--;

            state.isAddSchedule = 0;
            return {...state};
        case 'WeekPreDefine':
             state.weekArr =action.weekArr
             return {...state};
        case 'realWeekDefine':
            state.realWeekArr =action.weekArr
            return {...state};
        case 'SetWeekShiftType':
            let weekCount = action.start;
            let tempShiftType = state.shiftTypes;
            state.weekArr.forEach(function(item,key){
              state.shiftTypes[key].forEach(function(shiftType,shiftKey){
                tempShiftType[key][shiftKey]= {...tempShiftType[key][shiftKey],week:weekCount};
              });
              weekCount = weekCount + 1;
            });
            return {...state,shiftTypes:tempShiftType};

        case 'addWeekStatus':
            return {...state, isAddSchedule: action.status};
        case 'refresh':
            return {...state};
        default:
            return state;
      }
}
export const DesignPanelAddContext = React.createContext();
export const DesignPanelDispatchContext = React.createContext();
const DesignPanel = (preState) => {
    const schedulesAddStateContext= useContext(SchedulesAddStateContext);

    const initialState = {
        shiftTypes: schedulesAddStateContext.state.design.shiftTypes,
        searchShiftTypes: [],
        open: false,
        loading: false,
        selectAll: false,
        isCopyWeek: schedulesAddStateContext.state.design.shiftTypes.length > 0 ? 1 : 0,
        week: 0,
        weekArr: schedulesAddStateContext.state.design.weekArr,
        realWeekArr: schedulesAddStateContext.state.design.weekArr,
        station: preState.preState.station,
        endDate: schedulesAddStateContext.state.information.endDate,
        isAddSchedule: 0
    }
    const schedulesAddDispatchContext = useContext(SchedulesAddDispatchContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const {handleSubmit, register} = useForm({mode:'onBlur', defaultValues: {}});
    const [information,setInformation] = useState(schedulesAddStateContext.state.information);

    const onSubmit = async data =>
    {
        if(data.week){
            var result = [];
            let calculateDays = 0;
            var days, entry, count;
            data.week.forEach((week, i) => {
                result[i] = [];
                week.forEach( (shift, j) => {
                    //if(shift.days.length > 0){
                        count = 0;
                        days = [{"Sun": 0}, {"Mon": 0}, {"Tue": 0}, {"Wed": 0}, {"Thu": 0}, {"Fri": 0}, {"Sat":0}];
                        for (let d = 0; d < days.length; d++) {
                            for (let k = 0; k < shift.days.length; k++) {
                                entry = Object.entries(days[d])[0];
                                if(entry[0] === shift.days[k]){
                                    days[d][shift.days[k]] = 1;
                                    count++;
                                    calculateDays++;
                                    break;
                                }
                            }
                        }
                        state.shiftTypes[i][j]['days'] = days;
                        state.shiftTypes[i][j]['count'] = count;
                        result[i].push(state.shiftTypes[i][j]);
                   // }
                })
            });

            if (calculateDays === 0) {
                let confirmation = await confirm({
                    icon: true,
                    text: "ATTENTION: You have built a recurring schedule without selecting any working days in the design tab. Are you sure you want to save this change?",
                });

                if (!confirmation) {
                    return false;
                }
            }

            schedulesAddDispatchContext.dispatch( {type:'setDesign', design: {shiftTypes: result, weekArr: state.weekArr}} );
            document.querySelectorAll("[data-tab='tab']")[2].click();
        } else {
            schedulesAddDispatchContext.dispatch( {type:'setDesign', design: {shiftTypes: [], weekArr: state.weekArr}} );
            document.querySelectorAll("[data-tab='tab']")[2].click();
        }

    }
    const addWeek = async ()=>{
        await dispatch({type:'addWeek'})
        let start = weeksBetween(new Date(preState.preState.information.startDate));

        let startWeek = weeksBetween(new Date(preState.preState.information.startDate));
        let tempArray=[];
        let recWeeks = information.recurringWeeks;
        let ogWeekCount = 0;

        if( preState.preState.isCopy == 1 ){
            ogWeekCount = parseInt(preState.preState.originalWeekCount);
        } else {
            ogWeekCount = parseInt(state.weekArr.length);
        }

        for (let i=startWeek;i<(parseInt(startWeek)+ogWeekCount);i++){
            tempArray.push(i);
            if( preState.preState.information.recurring === false  ) {
                let end = weeksBetween(new Date(preState.preState.information.endDate));
                if( end === i ){
                    dispatch({
                        type: 'addWeekStatus',
                        status: 1
                    })
                }
            }
        }
        if( preState.preState.information.recurring === true  ) {
            if(state.weekArr.length === recWeeks)
                dispatch({
                    type: 'addWeekStatus',
                    status: 1
                })
        }
        if( preState.preState.isCopy == 1 ){
            schedulesAddDispatchContext.dispatch( {type:'setTotalWeeks', originalWeekCount: ogWeekCount+1} );
        }
        schedulesAddDispatchContext.dispatch( {type:'setWeeks', weekArr: tempArray} );
    };
    const handleCopyWeeks = async()=>{
      let start = moment(new Date(information.startDate)).utc();
      let end = moment(new Date(information.endDate)).utc();
      let startWeekNumber = weeksBetween(new Date(information.startDate));
      let weekCount = diff_weeks(start,end);


      let tempWeekCount=2;
      for (let i = 2; i <= weekCount; i++) {
          let shiftTemp=[];
          startWeekNumber++;
          let ct=0;
          await state.shiftTypes[0].map((shift,index)=>{
              let weekDays=[];
              if(schedulesAddStateContext.state.weekData.length > 0){
                schedulesAddStateContext.state.weekData[shift.id].Sun === 1 ?  weekDays.push({'Sun':1}):weekDays.push({'Sun':0});
                schedulesAddStateContext.state.weekData[shift.id].Mon === 1 ?  weekDays.push({'Mon':1}):weekDays.push({'Mon':0});
                schedulesAddStateContext.state.weekData[shift.id].Tue === 1 ?  weekDays.push({'Tue':1}):weekDays.push({'Tue':0});
                schedulesAddStateContext.state.weekData[shift.id].Wed === 1 ?  weekDays.push({'Wed':1}):weekDays.push({'Wed':0});
                schedulesAddStateContext.state.weekData[shift.id].Thu === 1 ?  weekDays.push({'Thu':1}):weekDays.push({'Thu':0});
                schedulesAddStateContext.state.weekData[shift.id].Fri === 1 ?  weekDays.push({'Fri':1}):weekDays.push({'Fri':0});
                schedulesAddStateContext.state.weekData[shift.id].Sat === 1 ?  weekDays.push({'Sat':1}):weekDays.push({'Sat':0});
              } else {
                 if( schedulesAddStateContext.state.isCopy === 1 ){

                    shift.days[0].Sun === 1 ?  weekDays.push({'Sun':1}):weekDays.push({'Sun':0});
                    shift.days[1].Mon === 1 ?  weekDays.push({'Mon':1}):weekDays.push({'Mon':0});
                    shift.days[2].Tue === 1 ?  weekDays.push({'Tue':1}):weekDays.push({'Tue':0});
                    shift.days[3].Wed === 1 ?  weekDays.push({'Wed':1}):weekDays.push({'Wed':0});
                    shift.days[4].Thu === 1 ?  weekDays.push({'Thu':1}):weekDays.push({'Thu':0});
                    shift.days[5].Fri === 1 ?  weekDays.push({'Fri':1}):weekDays.push({'Fri':0});
                    shift.days[6].Sat === 1 ?  weekDays.push({'Sat':1}):weekDays.push({'Sat':0});
                 }  else {
                    weekDays.push({'Sun':0},{'Mon':0},{'Tue':0},{'Wed':0},{'Thu':0},{'Fri':0},{'Sat':0})
                 }
              }
              let sft ={
                  break:shift.break,
                  category: shift.category,
                  color:shift.color,
                  endTime:shift.endTime,
                  id: shift.id,
                  invoice:shift.invoice,
                  name:shift.name,
                  range:shift.range,
                  skill:shift.skill,
                  startTime:shift.startTime,
                  startTimeOnDst:shift.startTimeOnDst,
                  endTimeOnDst:shift.endTimeOnDst,
                  rangeOnDst:shift.rangeOnDst,
                  week: startWeekNumber,
                  days:weekDays,
                  skillId:shift.skillId
              }
              shiftTemp.push(sft);
          });
          schedulesAddStateContext.state.weekArr.push(startWeekNumber);

          state.shiftTypes.push(shiftTemp);
          state.weekArr.push(tempWeekCount);
          state.realWeekArr.push(tempWeekCount);
          tempWeekCount++;
      }

      state.isCopyWeek = 0;
      dispatch({
        type: 'addWeekStatus',
        status: 1
      });

      await dispatch({type: 'refresh'});
    };

    const goBack = async ()=>{
        document.querySelectorAll("[data-tab='tab']")[0].click();
    }
    const cancelBtn = async ()=>{
        document.getElementById('cancelBtn').click()
    }
    useEffect(()=>{
        let weekArr=[];
        let realWeekArr =[];
        if(information.recurring === false){
            if(schedulesAddStateContext.state.weekArr.length === diff_weeks(information.startDate,information.endDate) || diff_weeks(information.startDate,information.endDate) === 1){
                dispatch({
                    type: 'addWeekStatus',
                    status: 1
                })
            }

            if(schedulesAddStateContext.state.weekArr.length >= schedulesAddStateContext.state.originalWeekCount){
                dispatch({
                    type: 'addWeekStatus',
                    status: 1
                })
            }
        } else {
            if( schedulesAddStateContext.state.isEdit === 1 || information.recurringWeeks == 1 ){
                dispatch({
                    type: 'addWeekStatus',
                    status: 1
                })
            }
        }

        let start = weeksBetween(new Date(preState.preState.information.startDate));
        if(preState.preState.isEdit === 0 && preState.preState.isCopy === 0 ) {
            if( state.weekArr.length === 1 ){
                dispatch({type: 'WeekPreDefine', weekArr: [1]});
                schedulesAddDispatchContext.dispatch({type: 'setWeeks', weekArr: [start]});
            }
        } else {
            let count = 1;
            if((moment().utc().format('YYYY-MM-DD') > preState.preState.information.startDate) && (preState.preState.isEdit === 1)) {
                start = preState.preState.originalScheduleDesignWeekAndYear.startWeek;
            }
            for (var i = start; i <= (start+(state.weekArr.length-1)); i++) {
                realWeekArr.push(i);
                weekArr.push(count);
                count++;
            }
            dispatch({type: 'WeekPreDefine', weekArr: weekArr});
            schedulesAddDispatchContext.dispatch({type: 'setWeeks', weekArr:  realWeekArr});
        }
    },[]);

    return (
        <DesignPanelAddContext.Provider value={{state}}>
            <DesignPanelDispatchContext.Provider value={{dispatch}}>
                <Grid padding='20px 0 0 0'>
                    <Grid templateColumns='70px 196px repeat(7, 104px)' templateRows='42px' borderBottom='1px solid #7C98B6'>
                        <Grid borderRight='1px solid #CBD6E2' color='#707070' justifyContent='center' height='100%'>Week</Grid>
                        <Grid borderRight='1px solid #CBD6E2' color='#707070' padding='0 0 0 12px' height='100%'>Shift Type</Grid>
                        { days.map((item, index) =>
                        {
                            return <Grid key={index+100} color='#707070' padding='0 40px 0 40px'>{item}</Grid>;
                        })
                        }
                    </Grid>


                { state.weekArr.map((item, index) =>
                    {
                        if(information.recurring === true && (schedulesAddStateContext.state.isEdit === 1 || schedulesAddStateContext.state.isCopy === 1)  ){
                          if(index < schedulesAddStateContext.state.originalWeekCount ){
                            return(
                                <Week
                                    key={(index+item)*item}
                                    week={item}
                                    arrItems={state.shiftTypes[index]}
                                    register={register}
                                    days={daysCheckBox}
                                />
                            )
                          }
                        } else {
                            if(schedulesAddStateContext.state.isEdit === 1 ){
                                   if(index < schedulesAddStateContext.state.originalWeekCount ){
                                       return(
                                           <Week
                                               key={(index+item)*item}
                                               week={item}
                                               arrItems={state.shiftTypes[index]}
                                               register={register}
                                               days={daysCheckBox}
                                           />
                                       )
                                    }
                            } else {
                                  return(
                                      <Week
                                          key={(index+item)*item}
                                          week={item}
                                          arrItems={state.shiftTypes[index]}
                                          register={register}
                                          days={daysCheckBox}
                                          showRemove={state.weekArr.length > 1}
                                      />
                                  );
                            }
                        }

                    })
                }

                {state.isAddSchedule === 0 && <Grid justifyContent='center' padding='16px 0 16px 0' borderBottom='1px solid #CBD6E2'>
                    {state.isCopyWeek === 1 && state.weekArr.length === 1 && information.recurring === false && (schedulesAddStateContext.state.isEdit === 0) ? <><CheckBox
                        name='checkbox'
                        title={''}
                        options={[
                            {value:true,label:'Check this box to copy first week updates to all other weeks.'},
                        ]}
                        onClick={()=>{handleCopyWeeks()}}
                        />
                         <Spacer top={4}></Spacer>
                                  OR
                          <Spacer top={4}></Spacer></> :'' }
                          <CustomButton width='104px' onClick={()=>{addWeek()}}>Add Week</CustomButton>
                        <Spacer top={4}></Spacer>
                </Grid> }


                <Spacer top={4}>
                    <Button onClick={handleSubmit(onSubmit)}>Go To Drivers Selection</Button>
                    <Spacer inline right={3}/>
                    <Button type={'button'} onClick={()=>{goBack()}}>Back</Button>
                    <Spacer inline right={3}/>
                    <Button type={'cancel'} onClick={()=>{cancelBtn()}}>Cancel</Button>
                </Spacer>

                </Grid>
                {state.open && <AddShiftType preState={state} ></AddShiftType>}
            </DesignPanelDispatchContext.Provider>
        </DesignPanelAddContext.Provider>
    );
}

export default React.memo(DesignPanel);
