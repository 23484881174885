export const STATUS = {
    PENDING:'pending',
    READY:'ready',
    ERROR:'error',
    FILTERING:'filtering'
}

export const initialState = (options) => {
    return {
        status:STATUS.PENDING,
        data: [],
        pageReference:options?.pageReference,
        slug: options?.slug
    }
}

export const reducer = (state,action) =>{
    const {payload} = action;
    switch (action.type){
        case 'loading':
            return {...state,status:payload.loading}
        case 'setPanelList':
            state.data = payload || [];
            state.status = STATUS.READY;

            return {...state};
        case 'addPanel':
            state.data.push({id:payload.id,name:payload.name,charts:[]});
            return {...state};
        case 'updatePanel':
            const updatePanelId = state.data.findIndex((p) => p.id === payload.id);
            if(state.data[updatePanelId]){
                state.data[updatePanelId].name = payload.name;
            }

            return {...state};
        case 'removePanel':
            const rPanelIndex = state.data.findIndex((p)=> p.id === payload.id);

            if(rPanelIndex >=0){
                state.data.splice(rPanelIndex,1);
            }

            return {...state};
        case 'removeChartFromPanel':
            const removePanelIndex = state.data.findIndex((p)=> p.id === payload.panelId);
            const removeChartIndex = state.data[removePanelIndex].charts.findIndex((c)=> c.id === payload.chartId);
            if(removePanelIndex >=0 && removeChartIndex >= 0){
                state.data[removePanelIndex].charts.splice(removeChartIndex,1);
            }

            return {...state};
        case 'removeChartDrag':
            const panelIndex = state.data.findIndex(panel => panel.charts.some(chart => chart.id === payload.chartId));
            if (panelIndex !== -1) {
                const chartIndex = state.data[panelIndex].charts.findIndex(chart => chart.id === payload.chartId);
                if (chartIndex !== -1) {
                    state.data[panelIndex].charts.splice(chartIndex, 1);
                }
            }

            return { ...state };
        case 'addChartIntoPanel':
            const newChart = {...payload.chart};
            const panelToAddIndex = state.data.findIndex((p)=> p.id === payload.panelId);
            if(panelToAddIndex >=0){
                if(!newChart.id){
                    newChart.id = 0;
                }
                if(typeof newChart.cardConfig === 'string') newChart.cardConfig = JSON.parse(newChart.cardConfig);
                if(typeof newChart.chartConfig === 'string') newChart.chartConfig = JSON.parse(newChart.chartConfig);
                if(typeof newChart.chartQuery === 'string') newChart.chartQuery = JSON.parse(newChart.chartQuery);
                state.data[panelToAddIndex].charts.push(newChart);
            }

            return {...state};
        case 'UpdateCardConfig':
            const updateConfigPanelIndex = state.data.findIndex((p)=> p.id === payload.panelId);
            const updateConfigChartIndex = state.data[updateConfigPanelIndex].charts.findIndex((c)=> c.id.toString() === payload.chartId.toString());
            if(updateConfigPanelIndex >=0 && updateConfigChartIndex >= 0){
                state.data[updateConfigPanelIndex].charts[updateConfigChartIndex].cardConfig = payload.cardConfig;
            }
            return {...state};
        case 'updateTempChartIntoPanel':
            const tempPanelChartId = state.data.findIndex((p)=> p.id === payload.panelId);
            const tempChartId = state.data[tempPanelChartId].charts.findIndex((c)=> c.id === payload.tempId);
            if(tempPanelChartId >=0 && tempChartId >= 0){
                if (!payload.chart.id && payload.tempId){
                    payload.chart.id = payload.tempId
                }
                state.data[tempPanelChartId].charts[tempChartId] = payload.chart;
            }
            return {...state};

        case 'updateTempChartInPanel':
            const tempUpPanelChartId = state.data.findIndex((p)=> p.id === payload.panelId);
            const tempUpChartId = state.data[tempUpPanelChartId].charts.findIndex((c)=> c.id === payload.tempId);
            if(tempUpPanelChartId >=0 && tempUpChartId >= 0){
                if(typeof payload.chart.cardConfig === 'string') payload.chart.cardConfig = JSON.parse(payload.chart.cardConfig);
                if(typeof payload.chart.chartConfig === 'string') payload.chart.chartConfig = JSON.parse(payload.chart.chartConfig);
                if(typeof payload.chart.chartQuery === 'string') payload.chart.chartQuery = JSON.parse(payload.chart.chartQuery);
                state.data[tempUpPanelChartId].charts[tempUpChartId] = payload.chart;
            }
            return {...state};
        case 'setChartTemp':
            const p = state.data.findIndex((p)=> p.id === payload.panelId);
            const cIndex = state.data[p].charts.findIndex((c)=> c.id === payload.chartId);
            state.data[p].charts[cIndex].isTemp = payload.temp;
            return {...state};
        default:
            return {...state};
    }
}
