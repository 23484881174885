import {useContext, useEffect, useRef} from "react";
import apiFactory from "./factory";
import {useSelectedStation} from "../components/StationSelector/StationPicker";
import { getCompanyId } from "../Utilities";

// todo setup all interfaces

export default function (context)
{
    const [state, dispatch] = useContext(context);
    const instance  = useRef(apiFactory())?.current;
    const [station] = useSelectedStation();

    useEffect(() => {
        return () => instance?.cancel();
    }, [instance]);

    const loadAvailableMetrics = async () => {
        dispatch({
            type: 'FETCHING',
            payload: 'metrics',
        });

        return instance.call({
            actions: {
                response: {
                    "Scorecard\\ScorecardMetric": {
                        findBy: {
                            get: "metrics",
                            criteria: {
                                isArchive: false,
                                source: state.source,
                                // metric_key: state.key,
                            },
                            includes: {
                                0: "key",
                                1: "name",
                                2: "description",
                                3: "options",
                                4: "metricType",
                                5: "coaching",
                                6: "baseType",
                                7: "source",
                                8: "sourceAbbr",
                                9: "timeframe",
                                10: "description",
                                11: "driverDescription",
                                12: "coaching",
                            },
                            orderBy: {
                                source: "ASC",
                                timeframe: "ASC",
                                name: "ASC",
                            }
                        }
                    },
                    "Scorecard\\ScorecardGoal": {
                        findBy: {
                            get: "goals",
                            criteria: {
                                isArchive: false,
                                station,
                                type: state.type,
                                // metric: state.key,
                            },
                            includes: {
                                0: "id",
                                2: "type",
                                3: "goal",
                                4: "description",
                                5: "driverDescription",
                                6: "coaching",
                                7: "options",
                                8: "status",
                                9: "onOff",
                                10: "triggers",
                                11: "isMetricVideoDisplay",
                                "metric": {
                                    0: "key",
                                },
                                "target": {
                                    0: "name",
                                    "user": {
                                        0: "friendlyName",
                                    },
                                },
                            }
                        }
                    },
                    "CompanySetting": {
                        findOneBy: {
                            criteria: {
                                company: getCompanyId(),
                            },
                            get: "getCompanySetting",
                            includes: {
                                0: "enableAutomatedScorecardCoaching"
                            },
                        },
                    },
                    "MetricVideo": {
                        custom: {
                            functionName: "getMetricVideo",
                            get: "getMetricVideo",
                            excludes: {},
                            includes: {},
                            criteria: {}
                        }
                    }
                }
            }
        }).then(response => {
            dispatch({
                type: 'READY',
                payload: {
                    metrics: response?.data?.data?.metrics
                        ? response?.data?.data?.metrics.map(metric => {
                            metric.goal = { ...response?.data?.data?.goals?.find(g => g.metric.key === metric.key) }
                            metric.goal.videoObj = { ...response?.data?.data?.getMetricVideo.find(m => m.metricKey === metric.key) }
                            return metric;
                        })
                        : [],
                    goals: response?.data?.data?.goals
                        ? response?.data?.data?.goals.map(goal => {
                            goal.metric = { ...response?.data?.data?.metrics?.find(m => m.key === goal.metric.key) }
                            goal.videoObj = { ...response?.data?.data?.getMetricVideo.find(m => m.metricKey === goal.metric.key) }
                            return goal;
                        })
                        : [],
                    enableAutomatedScorecardCoaching: response?.data?.data?.getCompanySetting?.enableAutomatedScorecardCoaching ?? false,
                }
            })
        })
    }

    const loadGoals = async (criteria) => {
        dispatch({
            type: 'FETCHING',
            payload: 'goals',
        });

        return instance.call({
            actions: {
                response: {
                    "Scorecard\\ScorecardGoal": {
                        findBy: {
                            get: "goals",
                            criteria: {
                                isArchive: false,
                                type: criteria.type?.toUpperCase(),
                                station,
                                metric: criteria. metric
                            },
                            includes: {
                                0: "id",
                                1: "type",
                                2: "goal",
                                3: "options",
                                4: "entity",
                                5: "entityId",
                                6: "status",
                                // "metric": {
                                //     0: "key",
                                //     1: "name",
                                //     2: "options",
                                //     4: "metricType",
                                //     5: "baseType",
                                //     6: "sourceAbbr",
                                //     7: "timeframe"
                                // },
                                "target": {
                                    "user": {
                                        0: "friendlyName"
                                    }
                                },
                            }
                        }
                    }
                }
            }
        }).then(response =>
            dispatch({
                type: 'READY',
                payload: {
                    goals: response?.data?.data?.goals || []
                }
            })
        )
    }

    const saveGoal = async (goal) => {
        dispatch({
            type: 'FETCHING',
            payload: 'savingGoal'
        })

        let params = {actions: {}};

        let tempVideoObject = goal.videoObj;

        delete goal.videoObj;
        const record = {
            ...goal,
            station,
            type: state?.type,
            options: JSON.stringify(goal.options),
            metric: goal?.metric?.key ?? goal.metric,
        }

        const target = record?.target;
        delete record?.target;

        params.actions[goal.id ? 'update' : 'create'] = {
            "Scorecard\\ScorecardGoal": {
                goal: record
            }
        };

        if (params.actions.update)
            params.actions.update["Scorecard\\ScorecardGoal"].goal = {
                findBy: {
                    id: goal.id
                },
                updateRecord: record
            }

        return instance.call(params).then(async response => {
            const newGoal = record.isArchive ? null : {
                ...record,
                id: record.id ?? response?.data?.data?.responseId,
                target: goal.target,
                options: goal.options,
                metric: {...state.metrics.find(m => m.key === record.metric)},
            };

            if (newGoal && target)
                newGoal.target = target;
            else if (record.type !== 'STATION') {
                await loadAvailableMetrics(); // load target from API
                dispatch({
                    type: 'READY',
                    payload: {
                        savingGoal: false
                    }
                })
                return;
            }

            let newGoals;
            if (state?.goals?.find(g => g.id === newGoal.id))
                newGoals = state?.goals?.map(g => {
                    if (g.id === newGoal.id)
                        g = newGoal;
                    return g;
                });
            else
                newGoals = state?.goals?.concat([newGoal]);

            newGoal.videoObj = tempVideoObject;

            const newMetrics = state?.metrics?.map(m => {
                if (m.key === newGoal?.metric?.key)
                    m.goal = newGoal;
                return m;
            });

            dispatch({
                type: 'ADD',
                payload: {
                    loading: {
                        ...state.loading,
                        savingGoal: false
                    },
                    metrics: newMetrics,
                    goals: newGoals,
                }
            })
        })
    }

    const loadEntitiesByType = type => {
        dispatch({
            type: 'FETCHING',
            payload: 'entities',
        })

        const params = {actions: {response: {}}};

        if (type === 'INDIVIDUAL')
            params.actions.response.Driver = {
                findBy: {
                    get: "entities",
                    criteria: {
                        isArchive: false,
                    },
                    matching: {
                        stations: [station],
                    },
                    includes: {
                        0: "id",
                        1: "employeeStatus",
                        "user": {
                            0: "friendlyName"
                        }
                    },
                }
            }
        else if (type === 'TEAM')
            params.actions.response.Team = {
                findBy: {
                    get: 'entities',
                    criteria: {
                        isArchive: false,
                        station,
                    },
                    includes: {
                        0: "id",
                        1: "name",
                        2: "membersNames",
                        3: "leaders",
                        "members": {
                            0: "id",
                        }
                    }
                }
            }
        else
            throw new Error(`Unexpected type ${type}`);

        return instance.call(params).then(response =>
            dispatch({
                type: 'READY',
                payload: {
                    entities: {
                        ...state.entities,
                        [type]: response?.data?.data?.entities
                            .sort((a, b) => (a?.user?.friendlyName ?? a.name).localeCompare(b?.user?.friendlyName ?? a.name))
                        ?? []
                    },
                }
            })
        )
    }

    const saveTeam = async team => {
        dispatch({
            type: 'FETCHING',
            payload: 'savingTeam',
        });

        team.station = station;

        team.leaders = typeof team?.leaders === "object" ? team?.leaders : JSON.parse(team?.leaders || []);
        team.leaders = JSON.stringify(team.leaders);

        delete team.membersNames;

        const params = {actions: {}};

        params.actions[team.id ? 'update' : 'create'] = {
            Team: {
                team,
            }
        };

        if (params.actions.update)
            params.actions.update.Team.team = {
                findBy: {
                    id: team.id
                },
                updateRecord: team
            }

        return instance.call(params).then(() =>
            dispatch({
                type: 'READY',
                payload: {
                    savingTeam: false,
                }
            })
        )
    }

    return {
        cancel: instance?.cancel,
        loadAvailableMetrics,
        loadGoals,
        saveGoal,
        loadEntitiesByType,
        saveTeam
    };
}
