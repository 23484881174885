import styled from "styled-components";
import { Button, Icon, Spacer, Theme, ThirdTitle } from "@dspworkplace/ui";
import React, { useState } from "react";
import WithDragAndDrop, { DragItem } from "../dragHandler";
import { CardDrag } from "../UI";
import { useDrag } from "react-dnd";

const TableData = styled.div`
    width: ${(props) => (props.width ? props.width : "224px")};
    min-width: ${(props) => (props.width ? props.width : "224px")};
    padding: ${(props) => (props.padding ? props.padding : "12px")};
    font-size: 14px;
    color: #707070;
    display: inline-flex;
    justify-content: ${(props) => (props.center ? "center" : "flex-start")};
    align-items: center;
    vertical-align: middle;
    font-family: ${Theme.font.body};
    word-break: break-all;

    ${(props) => props.order && `cursor:pointer;`}

    ${(props) =>
        props.active &&
        `
    color: ${Theme.colors.secondary};
    font-weight: bold;
    `}

    ${(props) => props.compact && "padding: 8px;"}

    &.allowToChat {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 18px;
        justify-content: center;
        align-items: center;
    }

    &.usedBy {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &.toggleAllowLoadOut {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 20px;
        justify-content: center;
        align-items: center;
    }
`;

const Table = styled.ul`
    list-style: none;
    margin: ${(props) => (props.margin ? props.margin : "0")};
    padding: 0;
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.width ? props.width : "auto")};

    > li.header {
        border-bottom: 1px solid #7c98b6;
        margin: ${(props) => (props.margin ? props.margin : "")};
        min-width: ${(props) => (props.minWidth ? props.minWidth : "")};

        ${TableData} {
            font-family: ${Theme.font.main};
        }
    }

    > li:not(.header):not(.footer) {
        display: flex;
    }

    > li + li + li {
        display: inline-flex;
    }
    > li:not(.header) + li:not(.footer) {
        border-top: 1px dashed #cbd6e2;
        margin: ${(props) => (props.margin ? props.margin : "")};
        min-width: ${(props) => (props.minWidth ? props.minWidth : "")};
    }
`;

const AddTable = styled.li.attrs((props) => ({
    className: "footer",
}))`
    background: #fafafa;
    padding: 16px 16px 16px 12px;
    border-top: 1px solid #516f90;
    border-bottom: none;
`;

const TableFooter = styled(AddTable)`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${(props) =>
        props.sticky &&
        `
    position: sticky;
    bottom: 0;
    `}

    small {
        color: ${Theme.colors.info.border};
    }
`;

const TableRow = styled.li`
    display: flex;
`;

const TableHeader = WithDragAndDrop(({  headers, sortByFunc, sortedBy, compact, moveMember, isDraggable, ...props }) => {
    let render = [];
    sortedBy = sortedBy || [];
    sortByFunc = sortByFunc || (() => {});

    const generateHeaders = (list) => {
        return list.map((th, k) => {
            let active = sortedBy.find((criteria) => criteria.field == th.sortBy);
            let direction = active || { direction: 0 };

            return (
                isDraggable ?
                    <TableData
                        key={k}
                        width={th.width}
                        order={th.sortBy}
                        active={active}
                        center={th.centered}
                        onClick={() => {
                            th.sortBy && sortByFunc(th.sortBy, th);
                        }}
                        style={{
                            wordBreak: "normal",
                            ...th.style,
                        }}
                        compact={compact}
                    >
                        <DragItem id={th.label} key={th.label} type={"column"} index={k} onMove={moveMember}>{({ previewRef, dragRef }) => (
                            <div style={{ cursor: "move" }}>
                                <div ref={previewRef}>
                                    <div ref={dragRef}>
                                        {th.label}
                                        {th.sortBy && <OrderIco direction={direction.direction} />}
                                        {th.children && th.children}
                                    </div>
                                </div>
                            </div>
                        )}
                        </DragItem>
                    </TableData>
                    : <TableData
                        key={k}
                        width={th.width}
                        order={th.sortBy}
                        active={active}
                        center={th.centered}
                        onClick={() => {
                            th.sortBy && sortByFunc(th.sortBy, th);
                        }}
                        style={{
                            wordBreak: "normal",
                            ...th.style,
                        }}
                        compact={compact}
                    >
                        <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                            {th.label && <Spacer>{th.label}</Spacer>}
                            {th.mainLabel && <Spacer style={{color:"#F7931E", fontSize:"10px"}}>({th.mainLabel})</Spacer>}
                        </div>
                        {th.sortBy && <OrderIco direction={direction.direction} />}
                        {th.children && th.children}
                    </TableData>
            );
        });
    };

    if (!Array.isArray(headers)) {
        props.style = props.style || {};
        props.style.display = "flex";
        props.style.alignItems = "flex-end";
        props.style.width = "fit-content";
        Object.entries(headers).forEach(([key, group]) => {
            render.push(
                <div key={`table-header-group-${key}`} style={{ whiteSpace: "nowrap" }}>
                    <Spacer left={compact ? 2 : 3}>
                        <ThirdTitle>{group.label}</ThirdTitle>
                    </Spacer>
                    {generateHeaders(group.headers)}
                </div>
            );
        });
    } else {
        render = generateHeaders(headers);
    }

    return (
        <li className="header" {...props} style={{ display: "flex" }}>
            {render}
        </li>
    );
});

const OrderIco = ({ direction = 1, style }) => {
    //0 DESC
    //1 ASC
    // TODO: it is working in inverse at least for devices.
    let css = {
        marginLeft: "8px",
        overflow: "visible",
        ...style,
    };

    return direction ? (
        <Icon.ArrowUp size="16px" color={Theme.colors.info.border} style={css} />
    ) : (
        <Icon.ArrowDown size="16px" color={Theme.colors.info.border} style={css} />
    );
};

const ContainerScroll = styled.div`
    width: 100%;
    overflow-y: unset;
    display: block;
    ${(props) =>
        props.minWidth &&
        `
        @media (max-width:${props.minWidth}px){
            overflow-y:auto;
        }
   `}
`;

const TableButton = styled(Button).attrs(() => ({
    type: "cancel",
}))`
    padding: 0;
    height: "auto";
    line-height: 1;
`;

export { Table, TableHeader, TableRow, TableData, TableButton, AddTable, TableFooter, OrderIco, ContainerScroll };
