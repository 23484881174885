import {engine, showErrorToast} from "../../../../Utilities";

export async function fetchScorecardDetails(stationId, setLoading, setCards, setValue) {
    try {
        setLoading(true);
        let {data} = await engine().post("/api/scorecard/driver", {
            view: 'DRIVER_APP',
            stationId: stationId,
        });
        setValue("numberOfScoreCardMainFields", data.numberOfScoreCardMainFields);
        setLoading(false);
        let scoreCardSortedFields = data.scoreCardSortedFields;
        if (Array.isArray(scoreCardSortedFields) && scoreCardSortedFields.length > 0) {
            scoreCardSortedFields = scoreCardSortedFields.filter(e => e.id !== 'AMAZON_FOCUS');
        }
        data.scoreCardSortedFields = scoreCardSortedFields;
        setCards(data.scoreCardSortedFields);
        return data;
    } catch (e) {
        await showErrorToast(e, 'Error fetching', 'Error in fetching the sorted scorecard fields.')
        return e;
    }
}

export async function updateScorecardDetails(stationId, setLoading, cards, data, setValue) {
    try {
        setLoading(true);
        let response = await engine().post("/api/scorecard/driver", {
            userId: localStorage.getItem("userId"),
            stationId: stationId,
            scoreCardSortedFields: cards,
            numberOfScoreCardMainFields: data.numberOfScoreCardMainFields,
            view: 'DRIVER_APP',
            case: 'UPDATE',
        });
        setLoading(false);
        setValue('numberOfScoreCardMainFields', data['numberOfScoreCardMainFields'])
        return response.data;
    } catch (e) {
        await showErrorToast(e, 'Error updating', 'Error in submission of sorted scorecard fields.')
        return e;
    }
}


export async function fetchAutomatedScorecardCoachingMetricDetails(stationId, setLoading, setCards, setValue) {
    try {
        setLoading(true);
        let {data} = await engine().post("/api/scorecard/automated-scorecard-coaching-metric", {
            view: 'DRIVER_APP',
            stationId: stationId,
        });
        setValue("numberOfAutomatedScorecardCoachingMetric", data.numberOfAutomatedScorecardCoachingMetric);
        setLoading(false);
        let automatedScorecardCoachingMetric = data.automatedScorecardCoachingMetric;
        if (Array.isArray(automatedScorecardCoachingMetric) && automatedScorecardCoachingMetric.length > 0) {
            automatedScorecardCoachingMetric = automatedScorecardCoachingMetric.filter(e => e.id !== 'AMAZON_FOCUS');
        }
        data.automatedScorecardCoachingMetric = automatedScorecardCoachingMetric;
        setCards(data.automatedScorecardCoachingMetric);
        return data;
    } catch (e) {
        await showErrorToast(e, 'Error fetching', 'Error in fetching the sorted scorecard fields.')
        return e;
    }
}

export async function updateAutomatedScorecardCoachingMetricDetails(stationId, setLoading, cards, data, setValue) {
    try {
        setLoading(true);
        let response = await engine().post("/api/scorecard/automated-scorecard-coaching-metric", {
            userId: localStorage.getItem("userId"),
            stationId: stationId,
            automatedScorecardCoachingMetric: cards,
            numberOfAutomatedScorecardCoachingMetric: data.numberOfAutomatedScorecardCoachingMetric,
            view: 'DRIVER_APP',
            case: 'UPDATE',
        });
        setLoading(false);
        setValue('numberOfAutomatedScorecardCoachingMetric', data['numberOfAutomatedScorecardCoachingMetric'])
        return response.data;
    } catch (e) {
        await showErrorToast(e, 'Error updating', 'Error in submission of sorted scorecard fields.')
        return e;
    }
}