import React,{useState} from 'react';
import {AtomicBlockUtils} from "draft-js";
import VideoSvg from "./video.svg";
import { openTrainingVideoModel } from "../TrainingVideoModel";
import Tooltip from '../../../../../../../components/Tooltip';

const videoBasePath = {
    'youtube':'//www.youtube.com/embed/'
}

const VideoRender = ({blockProps}) => {
    const autoPlay=0;
    const controls = 0;
    const height = (blockProps.resizeData?.type == "metricVideo") ? '240px' : '400px';
    return (
        <div className={"EmbedVideo"}>
            <iframe
                width="100%"
                height={height}
                //src={`${blockProps.src}?autoplay=${autoPlay}&controls=${controls}`}
                src={`${blockProps.src}`}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                allowFullScreen
            />

        </div>
    );
}

const VideoButton = ({theme,getEditorState,setEditorState,...props}) => {
    const [video,setVideo] = useState();

    // const handleClick = (e) => {
    //     e.preventDefault();
    //     const editorState = getEditorState();
    //     const video = window.prompt('Paste the video link -');
    //     if(!video) return 'not-handle';

    //     const videoId = getYoutubeId(video);

    //     if(!videoId){
    //         setEditorState(props.modifier(editorState, { src: video }))
    //     }else{
    //         const videoUrl = videoBasePath['youtube']+videoId;
    //         const ImageEntity = editorState.getCurrentContent().createEntity('draft-js-video-plugin-video','IMMUTABLE',{src:videoUrl});
    //         const ImageEntityKey = ImageEntity.getLastCreatedEntityKey();
    //         const newState = AtomicBlockUtils.insertAtomicBlock(editorState,ImageEntityKey,' ');
    //         setEditorState(newState);
    //         setVideo({src:videoUrl,entityKey:ImageEntityKey});
    //     }
    // }

    
    const handleClick = async (e) => {
        e.preventDefault();
        const formData = await openTrainingVideoModel(props?.showModalSection);
        const editorState = getEditorState();
        let videoValue = formData?.value;

        if(formData?.type === "metricVideo") {
            let videoObj = props?.metricVideo.find((video) => video.metricKey == videoValue);
            if (videoObj != undefined) {
                videoValue = videoObj?.videoUrl ? videoObj?.videoUrl + "?h=" + videoObj.hasKey : "";
            }
            const VideoEntity = editorState
                .getCurrentContent()
                .createEntity("draft-js-video-plugin-video", "IMMUTABLE", { src: videoValue, type: "metricVideo" });
            const VideoEntityKey = VideoEntity.getLastCreatedEntityKey();
            const newState = AtomicBlockUtils.insertAtomicBlock(editorState, VideoEntityKey, " ");
            setEditorState(newState);
            setVideo({ src: videoValue, entityKey: VideoEntityKey });

        } else if (formData?.type === "url") {

            if (!videoValue) return "not-handle";
            const videoId = getYoutubeId(videoValue);
            if (!videoId) {
                setEditorState(props.modifier(editorState, { src: videoValue }));
            } else {
                const videoUrl = videoBasePath["youtube"] + videoId;
                const ImageEntity = editorState
                    .getCurrentContent()
                    .createEntity("draft-js-video-plugin-video", "IMMUTABLE", { src: videoUrl });
                const ImageEntityKey = ImageEntity.getLastCreatedEntityKey();
                const newState = AtomicBlockUtils.insertAtomicBlock(editorState, ImageEntityKey, " ");
                setEditorState(newState);
                setVideo({ src: videoUrl, entityKey: ImageEntityKey });
            }
        }
    }
    return (
        <div className={theme.buttonWrapper} style={{paddingLeft:4}}>
            {/*<Tooltip
                trigger={'click'}
                content={<h1>Something</h1>}
            >
            <button className={theme.button}>
                <img src={VideoSvg} />
            </button>
            </Tooltip>*/}
            <button className={`${theme.button} ${video ? theme.active : ''}`} onClick={handleClick}>
                <img src={VideoSvg} />
            </button>
        </div>
    )
};

const getYoutubeId = (url) =>{
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
        ? match[2]
        : null;
}

export {VideoRender,VideoButton, getYoutubeId,videoBasePath};