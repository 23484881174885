import React, {useRef} from "react";
import {CancelToken} from "axios";
import {getCompanyId, useAuth} from "../../../Auth";
import moment from "moment";
import {DriverSchemaInclude} from "../../Incidents/schema";
import {useSelectedStation} from "../../../components/StationSelector";
import { generateMultiEditReqBody, generateMultipleDriverSkillsReqBody } from "../Common/generateMultiReqBody";
import {Icon} from "@dspworkplace/ui";

const useSchedulerApi = () => {
    const {api} = useAuth();
    const canceler = useRef(()=>{});
    const [selectedStation] = useSelectedStation();

    const call = params => {
        return api.post(
            '/api/lazy/manage/data',
            params,
            {
                cancelToken: new CancelToken(function executor(c){
                    canceler.current = c;
                }),
                cache: {
                    ignoreCache: true
                }
            }
        ).finally(() => canceler.current = (()=>{}));
    };

    const checkSequenceDriverRoute = ({
        routeId,
        driverId,
        newDate
    }) => {
        return call({
            "actions": {
                "response": {
                    "DriverRoute": {
                        "custom": {
                            "functionName": "checkSequenceDriverRoute",
                            "get": "checkSequenceDriverRoute",
                            "excludes": [],
                            "criteria": {
                                "routeId": routeId,
                                "driverId": driverId,
                                "newDate": newDate,
                            },
                        },
                    },
                },
            },
        })
    };

    const getConsecutiveShift = ({
        newDriverId,
        message
    }) => {
        return call({
            "actions": {
                "response": {
                    "DriverRoute": {
                        "custom": {
                            "functionName": "createEventForConsicutiveShift",
                            "get": "createEventForConsicutiveShift",
                            "excludes": [],
                            "criteria": {
                                "driverId": newDriverId,
                                "message": message,
                            },
                        },
                    },
                },
            },
        })
    };

    const multicheckSequenceDriverRoute = ({
        routeId,
        driverId,
        timezoneOffset,
        newDate,
        pasteToDate,
        method
    }) => {
        return call({
            "actions": {
                "response": {
                    "DriverRoute": {
                        "custom": {
                            "functionName": "checkSequenceDriverRoute",
                            "get": "checkSequenceDriverRoute",
                            "excludes": [],
                            "criteria": {
                                "routeId": routeId,
                                "driverId": driverId,
                                "timezoneOffset": timezoneOffset,
                                "newDate": newDate,
                                "pasteToDate": pasteToDate,
                                "copyToDate": pasteToDate,
                                "method": method
                            }
                        }
                    }
                }
            }
        })
    };

    const getDayMoveDrop = ({
        routeId, isNew, oldDriverId, newDriverId, newDate, weekFrom,
        weekTo, startTime, endTime, routeStatus, isOpenShift,
        shiftId, isTemp, item, shiftInvoiceType, hasAlert, timezoneOffset
    }) => {
        return call({
            "actions": {
                "response": {
                    "TempDriverRoute": {
                        "custom": {
                            "functionName": "addTempDriverRoute",
                            "get": "tempDriverRoute",
                            "excludes": [],
                            "criteria": {
                                "routeId": routeId,
                                "isNew": isNew,
                                "oldDriverId": oldDriverId,
                                "newDriverId": newDriverId,
                                "newDate": newDate,
                                "weekFrom": weekFrom,
                                "weekTo": weekTo,
                                "startTime": startTime,
                                "endTime": endTime,
                                "routeStatus": routeStatus,
                                "isOpenShift": isOpenShift,
                                "shiftId": shiftId,
                                "isTemp": isTemp,
                                "item": item,
                                "shiftInvoiceType": shiftInvoiceType,
                                "hasAlert": hasAlert,
                                "timezoneOffset": timezoneOffset
                            }
                        }
                    }
                }
            }
        })
    };

    const copyPreviousDataAndPasteNewData = ({
        routeId,
        driverId,
        pasteToDate,
        userId,
        timezoneOffset,
        hasAlert,
        message,
        isOpenShift
    }) => {
        return call({
            "actions": {
                "response": {
                    "DriverRoute": {
                        "custom": {
                            "functionName": "copyPreviousDataAndPasteNewData",
                            "get": "driverRoute",
                            "excludes": [],
                            "criteria": [
                                {
                                    "routeId": routeId,
                                    "driverId": driverId,
                                    "pasteToDate": pasteToDate,
                                    "userId": userId,
                                    "timezoneOffset": timezoneOffset,
                                    "hasAlert": hasAlert,
                                    "message": message,
                                    "isOpenShift": isOpenShift
                                }
                            ]
                        }
                    }
                }
            }
        })
    };

    const getShiftData = ({
        id,
        findRescuer = false
    }) => {
        return call({
            "actions": {
                "response": {
                    "DriverRoute": {
                        "custom": {
                            "functionName": "getShiftDataById",
                            "get": "driverRoute",
                            "criteria": {
                                "driverRouteId": id,
                                "timezoneOffset": new Date().getTimezoneOffset(),
                                "findRescuer" : findRescuer
                            }
                        }
                    }
                }
            }
        })
    };

    const getTraineeData = ({
        id
    }) => {
        return call({
            "actions": {
                "response": {
                    "DriverRoute": {
                        "custom": {
                            "functionName": "getTraineesDataById",
                            "get": "trainees",
                            "criteria": {
                                "driverRouteId": id,
                                "timezoneOffset": new Date().getTimezoneOffset()
                            }
                        }
                    }
                }
            }
        })
    };

    const getShiftProperties = ({
        id
    }) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            criteria:{
                                id
                            },
                            functionName: "getExtraData",
                            get:"extra",
                            includes: {
                                vehicle: {
                                    "0": "vehicleId"
                                },
                                device: {
                                    "0": "number",
                                    "1": "name"
                                },
                                shiftInvoiceType: {
                                  "0": "id",
                                  "1": "name"
                                },
                                returnToStation: {
                                  "0": "id",
                                  "1": "mileage"
                                },
                                kickoffLog: {
                                    "0": "id",
                                    "1": "mileage"
                                },
                                routeCodeAssignments: {
                                    0: "id",
                                    1: "attemptedPackages",
                                    2: "completedPackages",
                                    3: "completedStops",
                                    4: "deliveredPackages",
                                    5: "missingPackages",
                                    6: "reattemptablePackages",
                                    7: "remainingPackages",
                                    8: "returnedPackages",
                                    9: "returningPackages",
                                    10: "totalPackages",
                                    11: "totalStops",
                                    12: "undeliverablePackages",
                                    routeCode: {
                                        0: "code",
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
    }

    const getShiftMakeRescueCheck = ({
        id
    }) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        findBy: {
                            criteria:{
                                oldDriverRoute:id,
                                isRescuer : true
                            },
                            get:"driverRoute",
                            includes: {
                                0:'id'
                            }
                        }
                    }
                }
            }
        });
    }

    const editDeletedShiftNote = ({
        id,
        newNote,
    }) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "editDeletedShiftNote",
                            get: "editDeletedShiftNote",
                            criteria: {
                                routeId: id,
                                newNote: newNote,
                            }
                        }
                    }
                }
            }
        })
    }
    const editDeletedShift = ({
        id,
        updateRecord
    }) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "editDeletedShift",
                            get: "driverRoute",
                            criteria: {
                                routeId: id,
                                ...updateRecord
                            }
                        }
                    }
                }
            }
        })
    }

    const editShift = ({
        id,
        updateRecord
    }) => {
        return call({
            actions: {
                update: {
                    DriverRoute: {
                        u1: {
                            findBy: {
                                id
                            },
                            updateRecord
                        }
                    }
                }
            }
        })
    }

    const tempEditShift = ({
        id,
        newRecord,
        oldRecord = {}
    }) => {
        oldRecord = {
            ...newRecord,
            ...oldRecord
        };

        let startTime = newRecord.utcStartTime
            || newRecord.startTime;

        let endTime = newRecord.utcEndTime
            || newRecord.endTime;

        let shiftId = newRecord.shiftId
            || newRecord.typeId
            || newRecord.shiftType.id;

        let routeStatus = newRecord.routeStatus;
        let isVoluntary = newRecord.isVoluntary;
        let backupStatus = newRecord.backupStatus;
        let onCall = newRecord.onCall;
        const params = {
            actions: {
                response: {
                    TempDriverRoute: {
                        custom: {
                            functionName: "addTempDriverRoute",
                            get: "tempDriverRoute",
                            criteria: {
                                routeId: id,
                                isNew: true,
                                isTemp: oldRecord.isTemp,
                                hasAlert: true,
                                newDriverId: newRecord.driverId,
                                oldDriverId: oldRecord.driverId,
                                newDate: newRecord.shiftDate,
                                oldDate: oldRecord.shiftDate,
                                routeStatus: routeStatus,
                                isVoluntary:isVoluntary,
                                startTime: startTime,
                                endTime: endTime,
                                shiftId: shiftId,
                                timezoneOffset: new Date().getTimezoneOffset(),
                                shiftInvoiceType: newRecord.shiftInvoiceType,
                                isEditTrain:newRecord.isEditTrain,
                                isEditDuty:newRecord.isEditDuty,
                                autoApproveDriverRequest:newRecord.autoApproveDriverRequest,
                                item: {
                                    qty: newRecord.qty,
                                    note: newRecord.note,
                                    newNote: newRecord.newNote
                                },
                                backupStatus: backupStatus,
                                onCall: onCall
                            }
                        }
                    }
                }
            }
        };

        if (newRecord.isNew) {
            params.actions.response.TempDriverRoute.custom.functionName = "editTempDriverRoute";
            params.actions.response.TempDriverRoute.custom.criteria.id = newRecord.id;
            /*params.actions.response.TempDriverRoute.custom.criteria.shift = {
                ...newRecord,
                shiftType: shiftId,
            };*/
            params.actions.response.TempDriverRoute.custom.criteria.shift = {
                //note: newRecord.note,
                //newNote: newRecord.newNote,
                startTime: newRecord.startTime,
                endTime: newRecord.endTime,
                shiftType: newRecord.shiftId,
                shiftInvoiceType: newRecord.shiftInvoiceType
            };
        }

        return call(params);
    }

    const tempEditShiftNote = ({
        id,
        note,
        newNote
    }) => {

        const params = {
            actions: {
                update: {
                    DriverRoute: {
                        update_driver_route: {
                            findBy: {
                                id: id,
                            },
                            updateRecord: {
                                currentNote: note,
                                newNote: newNote,
                                shiftNote: note+' '+newNote,
                            },
                        },
                    },
                },
            },
        }

        return call(params);
    }

    const archiveShift = ({
        id,
        shift = {}
    }) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "removeDriverRoute",
                            get: "result",
                            criteria: {
                                id: id,
                                isNew: shift.isNew,
                                timezoneOffset: new Date().getTimezoneOffset(),
                            },
                        },
                    },
                },
            },
        });
    }

    const  getShiftOptions = ({
        shift = {},
        driver = {}
    }) => {
        let station = (Object.entries(driver).length > 0) ? ((driver.id == '0.0.0') ? selectedStation : [driver.stationId]) : [shift.stationId];
        const params = {
            actions: {
                response: {
                    Shift: {
                        custom: {
                            functionName: "getNoramlAndBackupShiftForScheduler",
                            criteria: {
                                company: getCompanyId(),
                                station: station,
                                isRescue: shift.isRescuer,
                                isTrain: shift.isTrain,
                                isDuty: shift.isDuty,
                                isUnscheduledDriverShift: shift.isUnscheduledDriver,
                                isEditTrainDutyShift:shift.isEditTrainDutyShift
                            },
                            get: "shift",
                            includes: {
                                0: "id",
                                1: "name",
                                2: "color",
                                3: "textColor",
                                4: "startTime",
                                5: "endTime",
                                6: "startTimeOnDst",
                                7: "endTimeOnDst",
                                8: "category",
                                9: "isOT",
                                station: {
                                    "0": "id",
                                    "1": "name",
                                },
                                invoiceType: {
                                  "0": "id",
                                  "1": "name"
                                }
                            },
                            excludes: ["station"],
                        },
                    },
                    InvoiceType: {
                        custom: {
                            functionName: "getInvoiceTypeForScheduler",
                            criteria: {
                                station: station,
                                company: getCompanyId()
                            },
                            get: "invoiceTypes",
                            includes: {
                                0: "id",
                                1: "name",
                                "station" : {
                                    "0" :"id",
                                    "1" :"name"
                                }
                            },
                            excludes: ["station"]
                        }
                    }
                },
            },
        }

        if (shift.routeId && shift.shiftDate) {
            params.actions.response.DriverRoute = {
                custom: {
                    functionName: "getToDaysDriverCode",
                        criteria: {
                        station: shift.stationId,
                        date: shift.shiftDate,
                        routeId: shift.routeId,
                    },
                    get: "todayCodes",
                    includes: {
                        "0": "id",
                        "1": "code",
                    },
                },
            };
        }

        if (shift.isTrain || shift.isDuty) {
            params.actions.response.Driver = {
                custom: {
                    criteria: {
                        date: shift.shiftDate,
                        station: shift.stationId,
                        driver: shift.driverId,
                    },
                    functionName: "getDriversFromStation",
                    get: 'drivers',
                    includes: DriverSchemaInclude
                }
            }
        }

        return call(params)
    }

    const saveRescueShift = ({
        rescueShift = {},
        rescued = {},
        isEdit = false
    }) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "AddRescueShift",
                            criteria: {
                                data: rescueShift,
                                id: rescued.routeId,
                                shiftDate: rescued.shiftDate,
                                isEdit: isEdit,
                                timezoneOffset: new Date().getTimezoneOffset(),
                            },
                            get: "rescuer",
                        },
                    },
                },
            },
        })
    }

    const saveRefusedRescueShift = ({routeId}) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "saveRefusedRescueShift",
                            criteria: {
                                routeId: routeId,
                            },
                            get: "refused",
                        },
                    },
                },
            },
        })
    }

    const saveSecondShift = ({
        secondShift = {},
        oldShift = {},
        isEdit = false
    }) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "addSecondShift",
                            criteria: {
                                data: secondShift,
                                id: oldShift.routeId,
                                shiftDate: oldShift.shiftDate,
                                isEdit: isEdit,
                                timezoneOffset: new Date().getTimezoneOffset(),
                            },
                            get: "secondShift",
                        },
                    },
                },
            },
        })
    }

    const saveSpecialShift = ({
        newShift = {},
        related = {},
        isEdit = false,
    }) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "addUpdateTrainAndDuty",
                            criteria: {
                                data: newShift,
                                id: related.routeId,
                                shiftDate: related.shiftDate,
                                isEdit: isEdit,
                                timezoneOffset: new Date().getTimezoneOffset(),
                                isTrain: newShift.isTrain || false,
                                isDuty: newShift.isDuty || false,
                                driverId: related.driverId || false,
                            },
                            get: "result",
                        },
                    },
                },
            },
        })
    }

    const getTodayRouteCodes = ({
        station,
        dateCreated,
        routeId,
        isRescue
    }) => {
        return call({
            actions: {
                response: {
                    DriverRouteCode: {
                        custom: {
                            functionName : "getTodayRouteCode",
                            criteria: {
                                station: station,
                                dateCreated: moment(dateCreated).format('YYYY-MM-DD'),
                                routeId: routeId,
                                isRescue: isRescue
                            },
                            get: "codes",
                            includes: {
                                0: "id",
                                1: "code",
                                2: "isArchive"
                            },
                        },
                    },
                },
            },
        })
    }

    const saveShiftRouteCodes = ({
        shift,
        codes
    }) => {
        return call({
            actions: {
                response: {
                    DriverRouteCode: {
                        custom: {
                            functionName: "addDriverRouteCode",
                            criteria: {
                                code: codes,
                                company: getCompanyId(),
                                station: shift.stationId,
                                id: shift.routeId,
                                date:shift.date
                            },
                            get: "result",
                        },
                    },
                },
            },
        })
    }

    const reassignRouteCodes = ({
        routeId,
        newDriver,
        routeCodes
    }) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "reassignRouteCode",
                            get: "reassignRouteCodes",
                            criteria: {
                                newDriver: newDriver,
                                driverRoute: routeId,
                                routeCodes: routeCodes,
                                timezoneOffset: new Date().getTimezoneOffset()
                            }
                        }
                    }
                }
            }
        })
    }

    const setSendHome = ({
        driverId,
        time = undefined
    }) => {
        let updateRecord = {};
        let trigger = "";
        if(time === undefined){
            updateRecord = {
                datetimeEnded: moment().utc().format("YYYY-MM-DD HH:mm"),
                shiftInvoiceType: null
            };
            trigger = "sent_home";
        }else{
            updateRecord = {
                datetimeEnded: null,
            };
            trigger = "unsent_home";
        }
        return call({
            actions: {
                update: {
                    DriverRoute: {
                        update_send_home: {
                            findBy: {
                                id: driverId,
                            },
                            updateRecord: updateRecord,
                        },
                    },
                },
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "addSendOrUnSendHomeEventData",
                            get: "sendHome",
                            criteria: {
                                driverRouteId: driverId,
                                isSendHome: time === undefined ? true : false,
                            },
                        }
                    },
                }
            },
            workflow: [
                {
                    token: "sent_home",
                    category: "sent_home",
                    triggers: [
                        trigger
                    ]
                }
            ]
        })
    }

    const removeRequest = (
        routeId,currentWeek
    ) => {
        return call({
            actions: {
                response: {
                    TempDriverRoute: {
                        custom: {
                            functionName: "removeDropRequest",
                            get: "result",
                            excludes: [],
                            criteria: {
                                id:routeId,
                                wknumber:currentWeek,
                                timezoneOffset: new Date().getTimezoneOffset(),
                             }
                        }
                    }
                }
            }
        })
    }


    const dropLoadoutShift = ({
        driverId,
        incidentId,
        notifyDriver,
        isMultiDelete
    }) => {

        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "deleteShiftEventForTimeline",
                            get: "shiftEventForTimeLine",
                            criteria: {
                                driverRouteId: driverId,
                                isMultiDelete: isMultiDelete,
                                ...(notifyDriver === true && { notifyDriver })
                            },
                        }
                    },
                },
                // "delete": {
                //     "TempDriverRoute": {
                //         "temp_driver_route_1": {
                //             "findOneBy": {
                //                 "routeId": driverId,
                //                 "isNew": true
                //             },
                //             "isHardDelete": true
                //         }
                //     }
                // },
                ...(isMultiDelete === false && {
                    update: {
                        DriverRoute: {
                            update_driver_route: {
                                findBy: {
                                    id: driverId,
                                },
                                updateRecord: {
                                    isArchive: true,
                                    incident: incidentId
                                },
                            },
                        },
                    },
                })

            },
        })
    }
    const restoreShift = ({
        driverId
    }) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "restoreShiftTemp",
                            get: "restoreShiftTemp",
                            criteria: {
                                id: driverId,
                            },
                        }
                    },
                }
            },
        })
    }
    const dropRescueLoadoutShift = ({
        driverId,
        incidentId
    }) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "dropRescueShift",
                            get: "dropRescueShift",
                            criteria: {
                                driverRouteId: driverId,
                                timezoneOffset: new Date().getTimezoneOffset(),
                            },
                        }
                    },
                }
            },
        })
    }
    

    const createOpenRouteTemp = ({
        driverId,
        incidentId,
        isMultiDelete
    }) => {
        return call({
            actions: {
                response: {
                  DriverRoute: {
                    custom: {
                      functionName: "deleteShiftEventForTimeline",
                      get: "shiftEventForTimeLine",
                      criteria: {
                        driverRouteId: driverId,
                        isNotOpen: false,
                        isMultiDelete: isMultiDelete
                      },
                    }
                  },
                },
                ...(isMultiDelete === false && {
                    "delete": {
                    "TempDriverRoute": {
                        "temp_driver_route_1": {
                            "findOneBy": {
                                "routeId": driverId,
                                "isNew": true
                            },
                            "isHardDelete": true
                        }
                    }
                },
                update: {
                    DriverRoute: {
                        update_driver_route: {
                            findBy: {
                                id: driverId,
                            },
                            updateRecord: {
                                isArchive:	true
                            },
                        },
                    },
                },
                })
                // update: {
                //     DriverRoute: {
                //         update_driver_route: {
                //             findBy: {
                //                 id: driverId,
                //             },
                //             updateRecord: {
                //                 driver: null,
                //                 isOpenShift:true,
                //                 incident: incidentId,
                //                 isTemp: false
                //             },
                //         },
                //     },
                // },
            },
        })
    }

    const createOpenRoute = ({
        driverId,
        incidentId,
        isMultiDelete
    }) => {
        return call({
            actions: {
                response: {
                  DriverRoute: {
                    custom: {
                      functionName: "deleteShiftEventForTimeline",
                      get: "shiftEventForTimeLine",
                      criteria: {
                        driverRouteId: driverId,
                        isNotOpen: false,
                          type: 'deleteAndMoveToOpen',
                          isMultiDelete: isMultiDelete
                      },
                    }
                  },
                },
                ...(isMultiDelete === false && {
                update: {
                    DriverRoute: {
                        update_driver_route: {
                            findBy: {
                                id: driverId,
                            },
                            updateRecord: {
                                isArchive:	true
                            },
                        },
                    },
                },
                })
                // update: {
                //     DriverRoute: {
                //         update_driver_route: {
                //             findBy: {
                //                 id: driverId,
                //             },
                //             updateRecord: {
                //                 driver: null,
                //                 isOpenShift:true,
                //                 incident: incidentId
                //             },
                //         },
                //     },
                // },
            },
        })
    }

    const getRouteCodes = (
        data
    ) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "getDateWiseRouteCodes",
                            get: "routeCodes",
                            excludes: [],
                            criteria: {
                                station:data.station,
                                date:data.date,
                                week:data.week,
                                timezoneOffset: new Date().getTimezoneOffset(),
                                code: data.code,
                             }
                        }
                    }
                }
            }
        })
    }

    const getStations = (
        data
    ) => {
        return call({
            actions: {
                response: {
                    Station: {
                        findBy: {
                            criteria: {
                                company: localStorage.getItem('company'),
                                isArchive: false
                            },
                            get: "station",
                            includes: {
                                0: "id",
                                1: "code",
                                2: "color",
                            }
                        }
                    }
                }
            }
        })
    }

    const removetRouteCode = (
        data
    ) => {
        return call({
            actions: {
                response: {
                    DriverRouteCode: {
                        custom: {
                            functionName: "removetRouteCode",
                            get: "removeCode",
                            excludes: [],
                            criteria: {
                                route:data.route,
                                code:data.code
                            }
                        }
                    }
                }
            }
        })
    }
    
    const getDriverShiftByDateForDayView = (
        config
    ) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: "getDriverShiftByDateForDayView",
                            get: "routes",
                            excludes: [
                                "skill",
                                "vehicle",
                                "device",
                                "driver",
                                "station",
                                "stations",
                                "shiftType",
                                "schedule",
                                "vehicleDriverRecord",
                                "status",
                                "tempDriverRoutes",
                                "kickoffLog",
                                "returnToStation",
                                "oldDriver",
                                "oldDriverRoute",
                                "driverRoutes",
                                "oldTempDriverRoutes",
                                "company",
                                "user",
                                "events",
                                "updatedBy"
                            ],
                            criteria: {
                                date: config.date,
                                timezoneOffset: config.timezoneOffset,
                                week:  config.week,
                                year: config.year,
                                station: config.station,
                                companyId:config.companyId
                            },
                        },
                    },
                },
            },
        })
    }

    const getShiftActions = ({
        shift = {},
        canMakeRescue = null,
        format = 'scheduler'
    }) => {
        shift.get = prop => {
            const maps = {
                scheduler: {
                    hasSecondShift: 'hasSecondshift',
                    isTrainer: 'isTrain',
                    isLightDuty: 'isDuty',
                },
                loadout: {
                    isSecondShift: 'priorShiftRouteId',
                    hasSecondShift: 'secondShift',
                    isTrainer: 'isAddTrain',
                    isLightDuty: 'isLightDuty',
                    isRescuer: 'rescue',
                    isPunchedOut: 'clockedOut',
                }
            };
            return (shift.hasOwnProperty(maps[format][prop]))
                ? shift[maps[format][prop]]
                : shift[prop] || undefined;
        };
        shift.isTodayShift = shift.shiftDate === moment().format('YYYY-MM-DD');

        if (shift.get('isNew'))
            return [];

        let actions = [];

        if (!shift.get('dateTimeEnded')
            && !shift.get('isSecondShift')
            && !shift.get('hasSecondShift')
            && !shift.get('isTrainer')
            && !shift.get('isLightDuty'))
            actions.push({
                action: 'add-second-shift',
                icon: Icon.Plus,
                label: 'Second Shift'
            });
        if (canMakeRescue == true && !shift.get('isRefusedRescue')){
          if(shift.category == 7){
            actions.push({
              action: 'add-rescue-shift',
              label: 'Make Rescue'
            });
          }else{
            actions.push({
              action: 'add-rescue-shift',
              icon: Icon.Plus,
              label: 'Rescue'
            });
          }
        }      
        
        if (!shift.get('isRescuer')) {
            if (!shift.get('isTrainer'))
                actions.push({
                    action: 'make-trainer',
                    label: 'Make Trainer'
                });
                

            if (!shift.get('isLightDuty'))
                actions.push({
                    action: 'make-light-duty',
                    label: 'Light Duty'
                });
        }

       // if (shift.get('isTodayShift')) {
            if (!shift.get('isSentHome')
                && !shift.get('dateTimeEnded'))
                actions.push({
                    action: 'send-home',
                    label: 'Send Home'
                });

            if (shift.get('isSentHome'))
                actions.push({
                    action: 'un-send-home',
                    label: 'Un-send Home'
                });
        //}
        if (canMakeRescue == true && !shift.get('isRefusedRescue')) {
            actions.push({
                action: 'refused-rescue',
                label: 'Refused Rescue'
            });
        }

        if (shift.get('requestApprovedBy')
            && shift.get('isRequested') === true
            && shift.get('isRequestCompleted') === false
            && shift.get('isOpen') === true)
            actions.push({
                action: 'disapprove',
                label: 'Disapprove',
                type: 'delete'
            });

        return actions;
    };

    const multiEditShift = async (selectedRows, data, multiSelectPasteToDate = [], message) => {
        const criteria = generateMultiEditReqBody(selectedRows, data, message);

        return call({
            actions: {
                response: {
                    TempDriverRoute: {
                        custom: {
                            functionName: 'multiUpdateDriverRoute',
                            get: "tempDriverRoute",
                            criteria: multiSelectPasteToDate.length > 0 ? multiSelectPasteToDate : criteria,
                        },
                    },
                },
            },
        })
    }

    const checkMultipleDriverSkills = async (selectedRows, data) => {
        const criteria = generateMultipleDriverSkillsReqBody(selectedRows, data.shiftType);
        return call({
            actions: {
                response: {
                    Driver: {
                        custom: {
                            functionName: "multiCheckDriverAndShiftSkillMatch",
                            get: "result",
                            excludes: [],
                            criteria,
                        },
                    },
                },
            },
        })
    }

    const multiDeleteShift = async (selectedRows) => {
        return call({
            actions: {
                response: {
                    DriverRoute: {
                        custom: {
                            functionName: 'deleteShiftEventForTimeline',
                            get: 'shiftEventForTimeLine',
                            criteria: {
                                driverRouteId: selectedRows.map(item => item.id),
                                isMultiDelete: true
                            }
                        }
                    }
                }
            }
        })
    }


    return {
        canceler,
        getShiftProperties,
        editShift,
        editDeletedShift,
        editDeletedShiftNote,
        tempEditShift,
        tempEditShiftNote,
        archiveShift,
        getShiftOptions,
        saveRescueShift,
        saveRefusedRescueShift,
        saveSecondShift,
        getTodayRouteCodes,
        saveShiftRouteCodes,
        reassignRouteCodes,
        saveSpecialShift,
        setSendHome,
        removeRequest,
        getShiftData,
        dropLoadoutShift,
        restoreShift,
        dropRescueLoadoutShift,
        createOpenRoute,
        createOpenRouteTemp,
        getRouteCodes,
        removetRouteCode,
        getShiftActions,
        getTraineeData,
        getStations,
        getDriverShiftByDateForDayView,
        getShiftMakeRescueCheck,
        multiEditShift,
        multiDeleteShift,
        checkMultipleDriverSkills,
        checkSequenceDriverRoute,
        getConsecutiveShift,
        multicheckSequenceDriverRoute,
        getDayMoveDrop,
        copyPreviousDataAndPasteNewData,
    }
};

export default useSchedulerApi;
