import React, {useCallback, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import Moment from "moment";

import DefaultStore from './ContextStore';
import Loading from "../../../components/Loading";
import {Table, TableData, TableHeader} from '../../../components/Table'
import Skeleton from '../../../components/Skeleton';
import {Input, Theme, Icon, Spacer, Button, Dropdown, CustomDropdown} from '@dspworkplace/ui';
import { useAuth } from "../../../Auth";
import {useForm} from "react-hook-form";
import {covertUtcToLocal} from "../../../components/TimeFormat";
import {confirm} from "../../../components/Confirm";
import {useSelectedStation} from "../../../components/StationSelector";
import {AccessCheck, IsGranted} from "../../../security";

const FooterTab = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    background:#FAFAFA;
    padding:16px;
    border-top:1px solid ${Theme.colors.info.text};
    max-width: ${props=>props.maxWidth ? props.maxWidth : '1150px'};;
    width: 100%;

    &.fixed {
        position:fixed;
        bottom:0;
    }


    >h3 {
        font-weight:normal;
    }
`;

const TableText = styled.p`
    font-size:14px;
    color:#333333;
`

const App = () => {
    const [state,dispatch] = useContext(DefaultStore);
    const HeaderDays = solveHeader(state.week, state.year);
    let width = 0;
    const bodyRef = document.getElementById('routeCommitmentbody');

    if(bodyRef){
        const totalPaddingSpace = (bodyRef.offsetWidth > 1200 ? 156 : 60) + 184 + 112;
        width = (bodyRef.offsetWidth - totalPaddingSpace) / HeaderDays.length;
    }

    return (
        <Table>
            <TableHeader
                headers={[{
                    width: '184px',
                    label: (state.grouped === false) ? 'Shift Type' : 'Balance Group',
                    sortBy: (state.grouped === false) ? 'shiftType' : 'balanceGroup',
                }].concat(HeaderDays.map(d => ({width: `${width}px`, label: d}))).concat([
                    {width: '112px', label: 'Total', sortBy: 'total'}
                ])}
                sortedBy={state.sortedBy}
                sortByFunc={sortBy => {
                    dispatch({
                        type: 'SORT',
                        payload: sortBy
                    })
                }}
            />
            {state.isLoading && <Loading style={{height:'100px',margin:'0 auto',padding:20}}/>}
            {state.data && <TableList data={state.data} width={width} />}
        </Table>
    )
}

const TableList = ({data,width}) => {
    const [state,dispatch] = useContext(DefaultStore);
    let totalValues = {w0:0,w1:0,w2:0,w3:0,w4:0,w5:0,w6:0,total:0};

    let shiftCount = 0;
     const MainData = data.map((item,key)=>{
         if(item.shifts.length > 0){
            shiftCount = (shiftCount + item.shifts.length);
        }
         return <TableLi key={key} item={item} totalValues={totalValues} k={key} grouped={state.grouped} width={width} />
      })
    if(state.grouped === true ){
         MainData.push(<AddData key={'add'} width={width} />);
    } else {
      MainData.push(<AddShiftData key={'shiftData'} balanceGroupId={data.id} width={width} />);
    }
    MainData.push(<Footer key='footer' totalItems={data.length} totalValues={totalValues} dataToggle={state.grouped} totalShiftType={shiftCount} width={width}/>)
    return MainData
}

const AddShiftData = ({balanceGroupId,width}) => {
      const { register, handleSubmit, reset } = useForm();
      const [state,dispatch] = useContext(DefaultStore);
        const [selectedStation] = useSelectedStation();
      const { api } = useAuth();
      const [shiftTypes, setShiftTypes] = useState([]);
      const [isShiftOpen, setIsShiftOpen] = useState(false);

      let station = {};
      if(selectedStation > 0)
      {
          station = selectedStation;
      }
      const fetchShifts = async () => {
          const params={
            "actions": {
                "response": {
                    "Shift": {
                        "custom": {
                            "functionName": "getShiftsForBalanceGroup",
                            "criteria": {
                                "company": localStorage.getItem('company'),
                                "balanceGroup": balanceGroupId,
                                station
                            },
                            "get":"getShifts",
                            "includes": {
                                  "0":"id",
                                  "1":"name",
                                  "2":"startTime",
                                  "3":"endTime",
                                  "4":"unpaidBreak",
                                  "5":"note",
                                  "6":"color",
                                  "7":"category",
                                  "station":["id","name"],
                                  "balanceGroup":["id","name"]
                            },
                            "excludes": ["company","shift"],
                        }
                    }
                }
            }
          }
          try{
              let response = await api.post('/api/lazy/manage/data', params);

              let shiftArray=[]
              state.data.map((groupObj) => {
                  groupObj.shifts.map((shiftObj) => {
                      shiftArray.push(shiftObj.id);
                  });
              });
              let tempObj=[];
              response.data.data.getShifts.map((shiftObj) => {
                  if(shiftObj.balanceGroup){
                      if(shiftArray.indexOf(shiftObj.id) == -1 ) {
                        tempObj.push(shiftObj);
                      }
                  }
              });
              setShiftTypes(tempObj);
          }catch(error){
            if (error.response.status == '401') {
                window.location = "/logout";
            }
          }
      };
    const onSubmit = async (form)  =>
    {
        dispatch({type:'LOADING',payload: true});
        let tempShift=[]
        shiftTypes.map((shiftObj) => {
            if(shiftObj.id === parseInt(form.shift)){
              tempShift = shiftObj;
            }
        });
        let balanceGroupExist=[];
        let tempKey;
        state.data.map((item,key) => {
            if(item.balanceGroup === tempShift.balanceGroup.name){
              balanceGroupExist.push(item);
              tempKey = key;
            }
        });
        let shiftData = {
            id:tempShift.id,
            shift:tempShift.name,
            isBackup:tempShift.category == 2 ? true : false,
            startTime:tempShift.startTime,
            endTime:tempShift.endTime,
            textTime:Moment.unix(tempShift.startTime.timestamp).utc().format("hh:mma")+' - '+Moment.unix(tempShift.endTime.timestamp).utc().format("hh:mma"),
            bg:tempShift.color, w0:parseInt(form.w0),w1:parseInt(form.w1),w2:parseInt(form.w2),w3:parseInt(form.w3),w4:parseInt(form.w4),w5:parseInt(form.w5),w6:parseInt(form.w6)
          }

        if(balanceGroupExist.length > 0 ){

            state.data[tempKey].shifts.push(shiftData);
            state.data[tempKey].station = tempShift.station.id;
            state.data[tempKey].w0 = state.data[tempKey].w0 + parseInt(form.w0);
            state.data[tempKey].w1 = state.data[tempKey].w1 + parseInt(form.w1);
            state.data[tempKey].w2 = state.data[tempKey].w2 + parseInt(form.w2);
            state.data[tempKey].w3 = state.data[tempKey].w3 + parseInt(form.w3);
            state.data[tempKey].w4 = state.data[tempKey].w4 + parseInt(form.w4);
            state.data[tempKey].w5 = state.data[tempKey].w5 + parseInt(form.w5);
            state.data[tempKey].w6 = state.data[tempKey].w6 + parseInt(form.w6);
            //dispatch({type:'ADD',payload:state.data,week:state.week,tempData:state.tempData});
        } else {
            let newData = [];
            let alldata=[];
            let alltempData=[];
            newData = { id:tempShift.balanceGroup.id,balanceGroup:tempShift.balanceGroup.name,station:tempShift.station.id, w0:parseInt(form.w0),w1:parseInt(form.w1),w2:parseInt(form.w2),w3:parseInt(form.w3),w4:parseInt(form.w4),w5:parseInt(form.w5),w6:parseInt(form.w6),
              shifts:[shiftData]
            };
            alldata = [...state.data, newData];
            alltempData = [...state.tempData, newData];
            dispatch({type:'ADD',payload:alldata,week:state.week,year:state.year});
            dispatch({type:'ADD_TEMP_DATA',tempData:alltempData})
        }
        dispatch({type:'LOADING',payload: false});
        setIsShiftOpen(false);
    }
    const handleAddShiftType = async() => {
        await fetchShifts();
        setIsShiftOpen(true);
    };

    return (
      <li style={{position:'relative',display:'inline'}}>
      {isShiftOpen && <form onSubmit={handleSubmit(onSubmit)}>
          <TableData width='184px' style={{flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
            <Spacer inline left={3}>
                <Dropdown
                    placeholder='Select Shift Type'
                    name='shift'
                    size='small'
                    ref={register({required:true})}
                    options={shiftTypes.map((shift) => { return {name: shift.name.toString() , value: shift.id.toString()}}) }
                />
            </Spacer>
          </TableData>

          <TableData width={`${width}px`}>
              <Input
                  size='extraSmall'
                  defaultValue={0}
                  name={'w0'}
                  ref={register({required:false})}
              />
          </TableData>
          <TableData width={`${width}px`}>
              <Input
                  size='extraSmall'
                  defaultValue={0}
                  name={'w1'}
                  ref={register({required:false})}
              />
          </TableData>
          <TableData width={`${width}px`}>
              <Input
                  size='extraSmall'
                  defaultValue={0}
                  name={'w2'}
                  ref={register({required:false})}
              />
          </TableData>
          <TableData width={`${width}px`}>
              <Input
                  size='extraSmall'
                  defaultValue={0}
                  name={'w3'}
                  ref={register({required:false})}
              />
          </TableData>
          <TableData width={`${width}px`}>
              <Input
                  size='extraSmall'
                  defaultValue={0}
                  name={'w4'}
                  ref={register({required:false})}
              />
          </TableData>
          <TableData width={`${width}px`}>
              <Input
                  size='extraSmall'
                  defaultValue={0}
                  name={'w5'}
                  ref={register({required:false})}
              />
          </TableData>
          <TableData width={`${width}px`}>
              <Input
                  size='extraSmall'
                  defaultValue={0}
                  name={'w6'}
                  ref={register({required:false})}
              />
          </TableData>
          <TableData  width='80px' padding={'0px'}> <Spacer inline left={3}><Button type='primary'>Save</Button></Spacer></TableData>
        </form> }
        <TableData width='100%' style={{flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
        <IsGranted feature={AccessCheck.features.ROUTE_COMMITMENT} ops={AccessCheck.OPS.C}>    
        {!isShiftOpen && <><Button style={{position:'relative',left:'calc(50% - (170px / 2))', top:11}} onClick={() => {handleAddShiftType()}} >Add New Shift</Button><Spacer bottom={5}/></>}
        </IsGranted>
        </TableData>
      </li>
    );
}
const AddData = ({width}) => {
    const [open,setOpen] = useState(false);
    const [state,dispatch] = useContext(DefaultStore);

    let numbers = [];

    for(let i =0;i<7;++i){
        if(open){
            numbers.push(<TableData width={`${width}px`} key={i}><Input name={`w${i}`} value="0" size={'extraSmall'} /></TableData>)
        }else{
            numbers.push(<TableData width={`${width}px`} key={i}><Input style={{opacity:0.2,cursor:'default',background:'#fff'}} readOnly size={'extraSmall'} /></TableData>)
        }

    }

    const addBalanceGroup = useCallback(async(value) => {

        let newData = [];
        let alldata=[];
        let alltempData=[];
        let station = 0;
        state.balanceGroupObj.map((item)=>{
            if(parseInt(value.value) === item.id){
              station = item.station.id;
            }
        });

        newData = { id:parseInt(value.value),balanceGroup:value.name,station:station, w0:0,w1:0,w2:0,w3:0,w4:0,w5:0,w6:0,
          shifts:[]
        };
        alldata = [...state.data, newData];
        alltempData = [...state.tempData, newData];

        dispatch({type:'ADD',payload:alldata,week:state.week,year:state.year})
        dispatch({type:'ADD_TEMP_DATA',tempData:alltempData})

        let temp=[];
        let tempObj=[];

        Object.keys(state.balanceGroup).forEach(function(index) {
            if(state.balanceGroup[index].value !== value.value ) {
              temp = {
                  'name': state.balanceGroup[index].name.toString(),
                  'value': state.balanceGroup[index].value.toString(),
              }
              tempObj.push(temp);
            }
        });
        dispatch({type:'ADD_BALANCE_GROUP',balanceGroup:tempObj})
        setOpen(false);
    });

    return (
        <li style={{position:'relative'}}>
            <TableData width='184px'>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
                    <div style={{display: 'flex',alignItems:'center'}}>
                        <div style={{display: 'flex',flexDirection: 'column'}}>
                        </div>
                        {
                            open
                            ?
                                <Spacer inline left={3}>
                                    <Dropdown
                                        placeholder='Select Balance Group'
                                        name='group'
                                        size='small'
                                        options={state.balanceGroup}
                                        onChange={(e)=>{addBalanceGroup(e)}}
                                        visibleOptionsQty={6}
                                    />
                                </Spacer>
                            :
                            <Spacer inline left={3} style={{height:20,width:100}}><Skeleton animation={true} /></Spacer>
                        }
                    </div>
                </div>
            </TableData>
            {numbers}
            <TableData width='100px'>
                <div style={{height:20,width:28}}>{open ? 0 : <Skeleton animation={true}/>}</div>
            </TableData>
            <IsGranted feature={AccessCheck.features.ROUTE_COMMITMENT} ops={AccessCheck.OPS.C}>
            {!open &&  <Button style={{position:'absolute',left:'calc(50% - (170px / 2))', top:11}} onClick={()=>{setOpen(true)}}>Add Balance Group</Button> }
            </IsGranted>                
        </li>
    )
}

const TableLi = ({item,k,totalValues,grouped,width}) => {

    const [expanded, setExpanded] = useState(false);
    const [isShiftOpen, setIsShiftOpen] = useState(false);
    const [shiftTypes, setShiftTypes] = useState([]);
    const [state,dispatch] = useContext(DefaultStore);
    const [selectedStation] = useSelectedStation();
    const { register, handleSubmit, reset } = useForm();
    const { api } = useAuth();

    const handleDelete = useCallback(async(key) => {
      let alldata=[];
      const confirmation = await confirm('Confirm that you want to delete this group ?');
      if(!confirmation){
          return false;
      }
      let tempDataObj = state.data[key];
      dispatch({type:'DELETE',key: key});
      dispatch({type:'ADD_TEMP_DATA',tempData:state.data})
      let tempObj={
          'name': tempDataObj.balanceGroup.toString(),
          'value': tempDataObj.id.toString(),
      }
      alldata = [...state.balanceGroup, tempObj];
      dispatch({type:'ADD_BALANCE_GROUP',balanceGroup:alldata})
    });

    if(!grouped){
        return item.shifts.map((shift,key)=>{
            const {total,View} = getDaysColumn(shift,totalValues,false,[k,key],'',width)
            return (
                <li key={key}>
                    <div  style={{borderTop: key === 0 ? 'none' : '1px dashed #CBD6E2'}}>
                        <TableData width='184px' style={{flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                            <div style={{display:'flex',alignItems:'center'}}>
                                <span style={{height:24,width:24,display:'block',background:shift.bg,borderRadius:'100%',marginRight:4, border: '2px solid white', boxSizing: 'border-box'}} />
                                <span><TableText>{shift.shift}</TableText></span>
                            </div>
                            <div style={{marginLeft:28}}><TableText>{covertUtcToLocal(shift.startTime, state.station.timezone)}-{covertUtcToLocal(shift.endTime, state.station.timezone)}</TableText></div>
                        </TableData>
                        {View}
                        <TableData width='80px'>{total}</TableData>
                    </div>
                </li>
            )
        });
    }
    if(expanded){
        const fetchShifts = async () => {
            let station = {};
            if(selectedStation > 0){
                station = selectedStation;
            }
            const params={
              "actions": {
                  "response": {
                      "Shift": {
                          "findBy": {
                              "criteria": {
                                  "balanceGroup": item.id,
                                  station
                              },
                              "get":"getShifts",
                              "includes": {
                                    "0":"id",
                                    "1":"name",
                                    "2":"startTime",
                                    "3":"endTime",
                                    "4":"unpaidBreak",
                                    "5":"note",
                                    "6":"color",
                                    "7":"category",
                                    "station":["id","name"]
                              },
                              "excludes": ["company","shift"],
                          }
                      }
                  }
              }
            }
            try{
                let response = await api.post('/api/lazy/manage/data', params);
                let shiftArray=[]
                Object.keys(item.shifts).forEach(function(index) {
                    shiftArray.push(item.shifts[index].id);
                });

                let temp=[];
                let tempObj=[];
                Object.keys(response.data.data.getShifts).forEach(function(index) {
                    if (shiftArray.indexOf(response.data.data.getShifts[index].id) == -1 && response.data.data.getShifts[index].category != 7) {
                       tempObj.push(response.data.data.getShifts[index]);
                    }
                });
                setShiftTypes(tempObj);

            }catch(error){
              if (error.response.status == '401') {
                  window.location = "/logout";
              }
            }
        };

          const handleAddShiftButton = async() => {
              await fetchShifts();
              setIsShiftOpen(true);
          };

        const onSubmit = async (form)  =>
        {
          let tempShift = [];
          let tempIndex;
          shiftTypes.map((shift,index) => {
              if( shift.id == form.shift ){
                tempShift = shift;
                tempIndex = index;
              }
          });
          await shiftTypes.splice(tempIndex, 1);
          setShiftTypes(shiftTypes);
          let shiftData = {
              id:tempShift.id,
              shift:tempShift.name,
              isBackup:tempShift.category == 2 ? true : false,
              startTime:tempShift.startTime,
              endTime:tempShift.endTime,
              textTime:Moment.unix(tempShift.startTime.timestamp).utc().format("hh:mma")+' - '+Moment.unix(tempShift.endTime.timestamp).utc().format("hh:mma"),
              bg:tempShift.color, w0:parseInt(form.w0),w1:parseInt(form.w1),w2:parseInt(form.w2),w3:parseInt(form.w3),w4:parseInt(form.w4),w5:parseInt(form.w5),w6:parseInt(form.w6)
            }
            state.data[k].shifts.push(shiftData);
            state.data[k].station = tempShift.station.id;
            state.data[k].w0 = state.data[k].w0 + parseInt(form.w0);
            state.data[k].w1 = state.data[k].w1 + parseInt(form.w1);
            state.data[k].w2 = state.data[k].w2 + parseInt(form.w2);
            state.data[k].w3 = state.data[k].w3 + parseInt(form.w3);
            state.data[k].w4 = state.data[k].w4 + parseInt(form.w4);
            state.data[k].w5 = state.data[k].w5 + parseInt(form.w5);
            state.data[k].w6 = state.data[k].w6 + parseInt(form.w6);
            //dispatch({type:'ADD',payload:state.data,week:state.week,tempData:state.tempData});
            setIsShiftOpen(false);
        }
        return (
            <li style={{padding:'16px 8px',flexDirection:'column'}}>
                <div style={{display:'flex',alignItems:'center',width:'100%'}}>
                    <div style={{display: 'flex',alignItems:'center'}}>
                        <div style={{display: 'flex',flexDirection: 'column'}}>
                            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.D]}>
                                <Icon.Times size='16px' onClick={() => {handleDelete(k)}}  color='#7C98B6' />
                            </IsGranted>
                        </div>
                        <Spacer inline left={3}><TableText>{item.balanceGroup}</TableText></Spacer>
                    </div>
                    <Icon.Contract
                        size='16px'
                        color='#7C98B6'
                        style={{cursor:'pointer',marginLeft:8}}
                        onClick={()=>{setExpanded(!expanded)}}
                    />
                </div>
                <Spacer top={4} />
                <div style={{borderLeft:'1px dashed #CBD6E2',marginLeft:5,paddingLeft:8}}>
                    {item.shifts.map((shift,key)=>{
                        const {total,View} = getDaysColumn(shift,totalValues,false,[k,key],'',width)
                        return (
                            <div key={key} style={{borderTop: key === 0 ? 'none' : '1px dashed #CBD6E2'}}>
                                <TableData width='164px' style={{flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                                    <div style={{display:'flex',alignItems:'center'}}>
                                        <span style={{height:24,width:24,display:'block',background:shift.bg,borderRadius:'100%',marginRight:4, border: '2px solid white', boxSizing: 'border-box'}} />
                                        <span><TableText>{shift.shift}</TableText></span>
                                    </div>
                                    <div style={{marginLeft:28}}><TableText>{covertUtcToLocal(shift.startTime, state.station.timezone)}-{covertUtcToLocal(shift.endTime, state.station.timezone)}</TableText></div>

                                </TableData>
                                {View}
                                <TableData width='80px'>{total}</TableData>
                            </div>
                        )
                    })
                  }
                  {isShiftOpen && <div key='10' style={{borderTop:  'none'}}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TableData width='164px' style={{flexDirection:'column',justifyContent:'center',alignItems:'flex-start'}}>
                          <Spacer inline left={3}>
                              <CustomDropdown
                                  placeholder='Select Shift Type'
                                  name='shift'
                                  size='small'
                                  ref={register({required:true})}
                                  type={'color'}
                                  options={shiftTypes.map((shift) => ({
                                      name: shift.name.toString(),
                                      value: shift.id.toString(),
                                      color: shift.color
                                  }))}
                              />
                          </Spacer>
                        </TableData>

                        <TableData width={`${width}px`}>
                            <Input
                                size='extraSmall'
                                defaultValue={0}
                                name={'w0'}
                                ref={register({required:false})}
                            />
                        </TableData>
                        <TableData width={`${width}px`}>
                            <Input
                                size='extraSmall'
                                defaultValue={0}
                                name={'w1'}
                                ref={register({required:false})}
                            />
                        </TableData>
                        <TableData width={`${width}px`}>
                            <Input
                                size='extraSmall'
                                defaultValue={0}
                                name={'w2'}
                                ref={register({required:false})}
                            />
                        </TableData>
                        <TableData width={`${width}px`}>
                            <Input
                                size='extraSmall'
                                defaultValue={0}
                                name={'w3'}
                                ref={register({required:false})}
                            />
                        </TableData>
                        <TableData width={`${width}px`}>
                            <Input
                                size='extraSmall'
                                defaultValue={0}
                                name={'w4'}
                                ref={register({required:false})}
                            />
                        </TableData>
                        <TableData width={`${width}px`}>
                            <Input
                                size='extraSmall'
                                defaultValue={0}
                                name={'w5'}
                                ref={register({required:false})}
                            />
                        </TableData>
                        <TableData width={`${width}px`}>
                            <Input
                                size='extraSmall'
                                defaultValue={0}
                                name={'w6'}
                                ref={register({required:false})}
                            />
                        </TableData>
                        <TableData width='80px' padding={'0px'}>  <Spacer inline><Button type='primary'>Save</Button></Spacer></TableData>
                      </form>
                    </div> }
                    <IsGranted feature={AccessCheck.features.ROUTE_COMMITMENT} ops={AccessCheck.OPS.C}>
                    {!isShiftOpen && <><Button style={{position:'relative',left:'calc(50% - (170px / 2))', top:11}} onClick={() => {handleAddShiftButton()}} >Add New Shift</Button><Spacer bottom={5}/></>}
                    </IsGranted>              
                </div>

            </li>
        )
    }
    const {total, View,totalValues: totalData} = getDaysColumn(item,totalValues,true,[],'',width);

    return (
        <li >
            <TableData width='184px'>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'100%'}}>
                    <div style={{display: 'flex',alignItems:'center'}}>
                        <div style={{display: 'flex',flexDirection: 'column'}}>
                            <IsGranted feature={AccessCheck.features.SETTINGS} ops={[AccessCheck.OPS.D]}>
                                <Icon.Times size='16px' onClick={() => {handleDelete(k)}} color='#7C98B6' />
                            </IsGranted>
                        </div>
                        <Spacer inline left={3}><TableText>{item.balanceGroup}</TableText></Spacer>
                    </div>
                    <Icon.Expand
                        size='16px'
                        id={'id-'+item.id}
                        color='#7C98B6'
                        style={{cursor:'pointer'}}
                        onClick={()=>{setExpanded(!expanded)}}
                    />
                </div>

            </TableData>
            {View}
            <TableData width='112px'>{total}</TableData>
        </li>

    )

}

const Footer = ({totalItems,totalValues, dataToggle, totalShiftType,width}) => {
    const routeRef = document.getElementById('routeCommitmentbody');
    let maxWidth = 0;
    if(routeRef){
        maxWidth = routeRef.offsetWidth;
    }
    return (
        <FooterTab maxWidth={maxWidth}>
            <TableData width='184px'>{(dataToggle === false) ? totalShiftType + ' shift types': totalItems + ' balance groups'}</TableData>
            {Object.values(totalValues).map((item,k)=>
                <TableData key={k} width={`${width}px`}>{item}</TableData>
            )}
        </FooterTab>
    );
}

const getDaysColumn = (data,totalValues,inputDisable=false,index,type = '',width) => {
    let View = [];
    let total = 0;
    for(let i=0;i<7;++i){
        const Comp = (<InputN keyIndex ={i+data.cId} key={i} inputDisable={inputDisable} value={data[`w${i}`]} column={`w${i}`} indexes={index} type={type} width={width} />)
        View.push(Comp);
        total += data[`w${i}`];
        totalValues[`w${i}`] += data[`w${i}`];
        totalValues.total += data[`w${i}`];;
    }
    return {total,View,totalValues};
}

const InputN = ({inputDisable,value,column,indexes,keyIndex,width}) => {
    const [state,dispatch] = useContext(DefaultStore);
    const param = {rowIndex:indexes[0],shiftIndex:indexes[1],column:column,oldValue:value};
    return (
        <TableData width={`${width}px`}>
            <Input
                size='extraSmall'
                key={keyIndex}
                readOnly={inputDisable}
                defaultValue={value}
                onBlur={(e)=>{param.value = e.target.value; return inputDisable ? null : dispatch({type:'CHANGE',payload:param})}}
            />
        </TableData>
    )
}

const solveHeader = (week,year) => {

    const firstDay = Moment().isoWeekYear(year).isoWeek(week).startOf('isoWeek');
    let days = [];
    for(let i=0;i<7;++i){
        days.push(firstDay.weekday(i).format('ddd/DD'))
    }
    return days;
}

export default App;
