import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Theme, Spacer, ThirdTitle, Icon } from '@dspworkplace/ui';
import { NavImage } from '../../Image/logo';

import { NavLink, useLocation, matchPath } from 'react-router-dom';
import { useAuth, getCompanyId } from "../../../Auth";

import { SummaryButton } from '../../../pages/Summary';
import IsGranted from "../../../security/IsGranted";
import AccessCheck from "../../../security/AccessCheck";
import { getAppVersion } from "../../../Utilities";
import { useReleaseNote } from "../../../Utilities/Headwayapp";

import { useMedia } from "../../../hooks/useMedia";
import { getEnableAutomatedScorecardCoachingMetric } from "../../../Utilities/index.ts";
import Tooltip from '../../Tooltip';

const navSize = '52px';

const Nav = styled.nav`
    background: ${Theme.colors.info.bg};
    height: ${navSize};
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${Theme.colors.info.shadow};
`;

const SubNav = styled(Nav)`
    background: ${Theme.colors.neutrals.white};
    justify-content: flex-start;
`;

const SpacerBorder = styled(Spacer)`
    border-right: 1px dashed ${Theme.colors.neutrals.silver};
    width: ${props => props.width};
    display: inline-block;
`;

const Menu = styled(Spacer)`
    flex: 1;
    align-items: center;
    height: ${navSize};
`;

export const Tabs = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;

    > li {
        display: inline-block;
        height: 100%;
        line-height: ${navSize};
        padding: 0 16px;

        &.active {
            position: relative;

            &:after {
                content: "";
                width: 95%;
                height: 3px;
                background: ${Theme.colors.secondary};
                border-radius: 3px;
                position: absolute;
                bottom: -1.9px;
                left: 2.5%;
            }

            a {
                font-weight: bold;
            }
        }

        a {
            font-size: ${Theme.font.medium.size};
            color: ${Theme.colors.secondary};
            font-family: circe-rounded;
            font-weight: normal;
            text-align: center;
        }

        svg {
            vertical-align: middle;
        }

        @media (max-width: 1200px) {
            padding-left: 8px;
            padding-right: 8px;
        }
    }
`;

const SpacerInline = styled(Spacer)`
    display: inline-block;
`;

const RegularThirdTitle = styled(ThirdTitle)`
    font-weight: normal;
`;

const JobTitle = styled.p`
    font-size: ${Theme.font.extraSmall.size};
    color: ${Theme.colors.info.border};
`;

export const MenuItemBadge = styled.sup`
    line-height: 1;
    align-self: baseline;
    color: ${Theme.colors.primary};
    font-weight: bold;
    text-indent: 4px;
    display: inline-block;
`;

const SubMenu = (mainRoute, pathname) => {
    if (!mainRoute.routes || mainRoute.routes.filter(r => r.renderMenu === true || r.renderMenu === undefined).length === 0) {
        return false;
    }

    return (
        <SubNav className='sub-nav-main'>
            <SpacerBorder width='156px' />
            <Tabs>
                {mainRoute.routes.map((route, i) => {
                    // if(route.name.includes('Incident'))
                    if (getEnableAutomatedScorecardCoachingMetric() == "false" && route.path === "/forced-coaching-metric") return null;
                    if (route.renderMenu === false) return null;

                    let activeClass = '';
                    let result = matchPath(pathname, mainRoute.path + route.path);
                    if (result)
                        activeClass = 'active';

                    let beta = null;
                    if (route.isCompany)
                        if (route.isCompany.indexOf(parseInt(getCompanyId())))
                            return null;
                        else
                            beta = <MenuItemBadge>Beta</MenuItemBadge>;

                    return (
                        <li key={i} className={activeClass}>
                            <NavLink to={mainRoute.path + route.path}>{route.name}{beta}</NavLink>
                        </li>
                    )
                })}
            </Tabs>
        </SubNav>
    );
};

const App = ({ routes }) => {
    let { pathname } = useLocation();
    let activeRoute = {};
    const ReleaseNote = useReleaseNote()

    return (
        <>
            <Nav className={'nav-main'}>
                <SpacerBorder left={5} right={4} width='148px' style={{ marginRight: 16 }}>
                    <NavLink to='/dashboard'>
                        <NavImage />
                    </NavLink>
                </SpacerBorder>

                <Menu style={{ flexGrow: 2 }}>
                    <Tabs>
                        {routes.map((route, i) => {
                            if (route.renderMenu === false) return null;
                            if (route.useMenu !== false) {

                                if (matchPath(pathname, route.path)) {
                                    activeRoute = route;
                                }

                                return (
                                    <li key={i} className={matchPath(pathname, route.path) ? 'active' : ''}>
                                        <NavLink
                                            to={route.component ? route.path : route.path + route.routes[0].path}>{route.name}</NavLink>
                                    </li>
                                )
                            }
                            return null;
                        })}
                    </Tabs>
                </Menu>

                {/* <Menu style={{ flex: 'unset' }}>
                    <Tabs style={{ display: 'inline-block' }}>
                        <li id={"summary-button"}>
                            <SummaryButton />
                        </li>
                        <IsGranted feature={AccessCheck.features.SETTINGS}>
                            <li className={matchPath(pathname, '/settings') ? 'active' : ''} id={'settings-icon'}>
                                <NavLink to="/settings">
                                    <Icon.Cog size='32px' />
                                </NavLink>
                            </li>
                        </IsGranted>
                        {ReleaseNote && <li>{ReleaseNote}</li>}
                    </Tabs>
                </Menu> */}

                {localStorage.getItem("currentUserRole") != "ROLE_CHAT_USER" && <DropdownInfos />}

                <Spacer inline left={4} right={5} style={{
                    borderLeft: '1px dashed ' + Theme.colors.neutrals.silver
                }}>
                    <Profile />
                </Spacer>
            </Nav>
            {SubMenu(activeRoute, pathname)}
        </>
    );
};

const ProfileContainer = styled.div`
    cursor: pointer;
    position: relative;
`

const ProfileOptions = styled.div`
    position: absolute;
    top: 47px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #CCCCCC;
    border: 1px solid #CCCCCC;
    border-top: none;
    border-radius: 0 0 2px 2px;
    width: 100%;
    z-index: 5000;
`
const InfosOptions = styled.div`
    position: absolute;
    top: 44px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #CCCCCC;
    border: 1px solid #CCCCCC;
    border-top: none;
    border-radius: 0 0 2px 2px;
    // width: 245%;
    z-index: 5000;
    display:flex;
    justify-content:center;
    align-items:center;
    right: 0;
`
const Option = styled.div`
    padding: 8px;
    color: #707070;
    font-size: 12px;
    text-align: center;

    > a {
        padding: 8px;
        color: #707070;
        font-size: 12px;
        text-align: center;
    }

    &.version {
        font-size: 8px;
        font-weight: bold;
    }

    &:hover {
        background: #EAF0F6;
    }
`

const Profile = () => {
    const [open, setOpen] = useState(false);
    const { logout } = useAuth();
    const popupRef = useRef();

    const handleClick = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            setOpen(false);
        } else {
            setOpen(true);
        }
    }

    useEffect(() => {
        document.addEventListener('mouseup', handleClick, false);

        return () => {
            document.removeEventListener('mouseup', handleClick, false)
        }
    }, [])

    return (
        <ProfileContainer ref={popupRef} id={"profile-container"}>
            <SpacerInline right={3}>
                <RegularThirdTitle>{localStorage.getItem('friendlyName') ? localStorage.getItem('friendlyName') : 'First Last'}</RegularThirdTitle>
                <JobTitle>{localStorage.getItem('title') ? (localStorage.getItem('title').length > 23 ? <Tooltip content={localStorage.getItem('title')} verticalPosition={"bottom"}>{localStorage.getItem('title').substring(0, 22) + '...'}</Tooltip> : localStorage.getItem('title')) : 'Driver'}</JobTitle>
            </SpacerInline>
            {open ? <Icon.ArrowUp size='16px' color='#7C98B6' style={{ top: -4, position: 'relative' }} /> :
                <Icon.ArrowDown size='16px' color='#7C98B6' style={{ top: -4, position: 'relative' }} />}
            {open &&
                <ProfileOptions>
                    <Option><NavLink to={'/edit-profile'}>Edit Profile</NavLink></Option>
                    <Option onClick={() => {
                        logout().then();
                    }}>Logout</Option>
                    {/* <Option className={'version'} onClick={() => {
                        try {
                            console.log(process.env)
                        } catch (e) {

                        }
                    }}>{getAppVersion()}</Option> */}
                </ProfileOptions>
            }
        </ProfileContainer>
    )
}

const MenuInfosDesktop = ({ re }) => {
    let { pathname } = useLocation();
    // const ReleaseNote = useReleaseNote()

    return (
        <Menu style={{ flex: 'unset' }} className={'menu-infos-desktop'}>
            <Tabs style={{ display: 'inline-block' }}>
                <li id={"summary-button"}>
                    <SummaryButton />
                </li>
                <IsGranted feature={AccessCheck.features.SETTINGS}>
                    <li className={matchPath(pathname, '/settings') ? 'active' : ''} id={'settings-icon'}>
                        <NavLink to="/settings">
                            <Icon.Cog size='32px' />
                        </NavLink>
                    </li>
                </IsGranted>
                {re && <li>{re}</li>}
            </Tabs>
        </Menu>
    )
}

const MenuInfosMobile = ({ re }) => {
    const [openInfos, setOpenInfos] = useState(false);
    const popupRef = useRef();
    let { pathname } = useLocation();
    //const ReleaseNote = useReleaseNote()

    const handleClick = (e) => {
        if (popupRef.current && !popupRef.current.contains(e.target)) {
            setOpenInfos(false);
        } else {
            setOpenInfos(true);
        }
    }

    useEffect(() => {
        document.addEventListener('mouseup', handleClick, false);

        return () => {
            document.removeEventListener('mouseup', handleClick, false)
        }
    }, [])

    return (
        <div ref={popupRef} id={'mobile-actions-container'} style={{
            position: 'relative',
            cursor: 'pointer',
        }} className={'menu-infos-mobile'}>
            <SpacerInline right={3}>
                <Icon.Menu size='28px' />
            </SpacerInline>
            <InfosOptions style={{ display: openInfos ? 'flex' : 'none' }}>
                <Option id={"summary-button"}>
                    <SummaryButton />
                </Option>

                <Option>
                    <IsGranted feature={AccessCheck.features.SETTINGS}>
                        <span className={matchPath(pathname, '/settings') ? 'active' : ''} id={'settings-icon'}>
                            <NavLink to="/settings">
                                <Icon.Cog size='32px' />
                            </NavLink>
                        </span>
                    </IsGranted>
                </Option>

                <Option>
                    {re && <span>{re}</span>}
                </Option>
            </InfosOptions>
        </div>
    )

}



const DropdownInfos = () => {

    const ReleaseNote = useReleaseNote()

    return <div>
        {window.screen.width > 1201 ? <MenuInfosDesktop re={ReleaseNote} /> : <MenuInfosMobile re={ReleaseNote} />}
    </div>
}

export default React.memo(App);
