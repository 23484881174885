import React, { useState, useEffect } from "react";
import { portalPromise } from "../../../../../components/Dialog";
import { Input, Spacer, Button, TagInput, Dropdown } from "@dspworkplace/ui";
import Modal from "../../../../../components/Modal";
import { useForm } from "react-hook-form";
import { useSelectedStation } from "../../../../../components/StationSelector";
import VehicleTaskPriorityCombobox from "../../../../../domain/vehicle_maintenance_task/components/TaskPriorityCombobox";
import TaskAmzStatusCombobox from "../../../../../domain/vehicle_maintenance_task/components/TaskAmzStatusCombobox";
import VehiclePlanTypeCombobox from "../../../../../domain/vehicle_maintenance_plan/components/TypeCombobox";
import VendorCombobox from "../../../../../domain/vehicle_maintenance_plan/components/VendorCombobox";
import VehiclePlanCategoryCombobox from "../../../../../domain/vehicle_maintenance_plan/components/CategoryCombobox";
import FormCombobox from "../../../../../components/Form/Combobox/combobox.hookForm";
import { LoadingCombobox } from "../../../../../components/Form/Combobox/combobox.recoil";
import { GetVehicleLookup } from "../../../../../domain/vehicle/api/getVehicles";
import { GetIncidentLookup } from "../../../../../domain/vehicle/api/getIncidents";
import { GetTaskLocationLookup } from "../../../../../domain/vehicle/api/getTaskLocation";
import DatePicker from "../../../../../components/Calendar/picker";
import { CustomErrorMessage } from "../../template/Common/CreateTemplateForm";
import apiFactory from '../../../../../api/factory';
import { getCompanyId } from "../../../../../Auth";
import { useRecoilRefresher_UNSTABLE } from "recoil";
import { VMPlanType } from "../../../../../domain/vehicle_maintenance_plan/state/VehicleMaintenancePlanType";
import { AccessCheck, IsGranted } from "../../../../../security";
import moment from "moment";
import DayPicker from '../../../../../components/Calendar/day';

const TaskForm = ({ success = () => { }, cancel = () => { }, task = {}, logForm = false }) => {
    const isCompany =  [85, 121, 221, 258, 254, 400];
    const checkAvoidPermission = isCompany.includes(parseInt(getCompanyId()));
    const [vehicles, setVehicles] = useState([{ label: 'Loading', value: 'loading', disabled: true }])
    const [incidents, setIncidents] = useState([{ name: 'Loading', value: 'loading', disabled: true }])
    const [locations, setLocations] = useState();
    const [vendor, setVendor] = useState([]);
    const [selectedStation] = useSelectedStation();
    const updateType = useRecoilRefresher_UNSTABLE(VMPlanType);
    const { call } = apiFactory();
    const { register, handleSubmit, errors, control, watch, reset, setValue, getValues } = useForm({
        defaultValues: {},
    });
    
    const type = watch('type');
    const [changeType, setChangeType] = useState(task?.category?.parent?.label === 'PM' ? true : false);
    const [changeTypeId, setChangeTypeId] = useState(task?.category?.parent?.id ? task?.category?.parent?.id : type?.value);
    
    useEffect(() => {
        GetVehicleLookup({ station_id: selectedStation }).then(response => {
            setVehicles(response.data.map((vehicle) => ({
                value: vehicle.id,
                label: vehicle.vehicleId
            })))
        })
        GetIncidentLookup({ station_id: selectedStation }).then(response => {
            let taskIncident = (task != null && task?.incidents) ? task?.incidents : [];
            setIncidents(
                response.map((incident) => {
                    return {
                        value: incident.id,
                        name: incident.label,
                        selected: (taskIncident.find(element=> element.id === incident.id)) ? true : false
                    }
            }))
        })
    }, [selectedStation]);

    const handleAddCategory = async (data) => {
        if ('id' in data && data.id == null && type != null) {
            let res = await call({ label: data.value, "company_id": parseInt(getCompanyId()), "parent_id": type.value, entity: "VehicleMaintenancePlanItem" }, `/api/v2/category`, 'post')
            let typeN = type;
            let children = [...typeN.children, { label: data.value, value: res.data.id }];
            typeN = { label: type.label, value: type.value, children: children };
            updateType();
            setValue('type', typeN);
            setValue('category', { label: data.value, value: res.data.id });
            return { id: null };
        }
    }
    const handleAddParentCategory = async (data) => {
        if ('id' in data && data.id == null) {
            let res = await call({ label: data.value, "company_id": parseInt(getCompanyId()), entity: "VehicleMaintenancePlanItem" }, `/api/v2/category`, 'post')
            reset('category');
            reset('type');
            updateType();
            setValue('type', { label: data.value, value: res.data.id, children: [] });
            setValue('category', null);
        } else {
            setChangeType(false);
            if(data.label == 'PM') {
                setChangeType(true);
            }
            setChangeTypeId(data.value);
        }
    }

    const onSubmit = (data) => {
        if (data.location && data.location.value) {
            data.location = data.location.value;
        }
        success(data);
    }

    return (
        <Modal
            width={"340px"}
            visible={true}
            title={!task ? "New Vehicle Maintenance Task" : "Editing Vehicle Maintenance Task"}
            closeOnOverlay={false}
            setVisible={cancel}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Spacer style={{ display: 'flex', position: 'relative' }}>
                    <FormCombobox
                        label="Vehicle ID"
                        name={"vehicleId"}
                        control={control}
                        rules={{ required: 'Vehicle ID is required' }}
                        options={vehicles}
                        placeholder={vehicles?.[0].value === 'loading' ? 'loading' : 'Select a Vehicle'}
                        OptionRender={vehicles?.[0].value === 'loading' ? LoadingCombobox : null}
                        error={errors.vehicleID && errors.vehicleID.message}
                        isAdd={false}
                        Required
                        defaultValue={task?.vehicle?.id}
                    />
                    {errors.vehicleId && <CustomErrorMessage style={{ bottom: -18 }}>{errors.vehicleId.message}</CustomErrorMessage>}
                </Spacer>

                <Spacer top={6} style={{ display: 'flex', position: 'relative' }}>
                    <TagInput
                        ref={register()}
                        name={'incidents'}
                        label={'Incidents'}
                        options={incidents}
                        visibleOptionsQty={10}
                        defaultValue={task?.incidents}
                    />
                </Spacer>

                <Spacer top={6} style={{ display: 'flex', position: 'relative' }}>
                    <TaskAmzStatusCombobox
                        label="Amz Status"
                        name={"amzFleetToolStatus"}
                        control={control}
                        isAdd={false}
                        defaultValue={task?.amzFleetToolStatus}
                    />
                    {errors.amzFleetToolStatus && <CustomErrorMessage style={{ bottom: -18 }}>{errors.amzFleetToolStatus.message}</CustomErrorMessage>}
                </Spacer>

                <Spacer top={6} style={{ display: 'flex', position: 'relative' }}>
                    <VehiclePlanTypeCombobox
                        label="Type"
                        name={"type"}
                        control={control}
                        onChange={(e) => { handleAddParentCategory(e) }}
                        rules={{ required: 'Type is required' }}
                        size={"small"}
                        Required
                        defaultValue={task?.category?.parent?.id}
                    />
                    {errors.type && <CustomErrorMessage style={{ bottom: -18 }}>{errors.type.message}</CustomErrorMessage>}

                    {logForm == false && <Spacer left={4} style={{ display: 'table', position: 'relative' }}>
                        <VehicleTaskPriorityCombobox
                            name={"priority"}
                            label="Priority"
                            control={control}
                            rules={{ required: 'Priority is required' }}
                            size={"small"}
                            Required
                            isAdd={false}
                            defaultValue={task?.priority}
                        />
                        {errors.priority && <CustomErrorMessage style={{ bottom: -18 }}>{errors.priority.message}</CustomErrorMessage>}
                    </Spacer>}
                </Spacer>

                <Spacer top={6} style={{ position: 'relative' }}>
                    <VehiclePlanCategoryCombobox
                        name={'category'}
                        label={'Category'}
                        control={control}
                        onChange={(e) => { return handleAddCategory(e) }}
                        rules={{ required: 'Category is required' }}
                        typeId={changeTypeId}
                        defaultValue={task?.category?.id}
                    />
                    {errors.category && <CustomErrorMessage style={{ bottom: -18 }}>{errors.category.message}</CustomErrorMessage>}
                </Spacer>
                {/* {logForm == true && <> */}
                {checkAvoidPermission ?  
                <><Spacer top={6} style={{ position: 'relative' }}>
                        <VendorCombobox
                            label="Vendor"
                            name={"vendor"}
                            control={control}
                            onChange={(e) => {setVendor(e) }}
                            isAdd={false}
                            defaultValue={task?.vendor?.id}
                        />
                        {errors.vendor && <CustomErrorMessage style={{ bottom: -18 }}>{errors.vendor.message}</CustomErrorMessage>}
                    </Spacer>

                    </> :  <IsGranted feature={AccessCheck.addons.VEHICLE_MAINTENANCE_PLAN}> 
                    <Spacer top={6} style={{ position: 'relative' }}>
                        <VendorCombobox
                            label="Vendor"
                            name={"vendor"}
                            control={control}
                            onChange={(e) => {setVendor(e) }}
                            isAdd={false}
                            defaultValue={task?.vendor?.id}
                        />
                        {errors.vendor && <CustomErrorMessage style={{ bottom: -18 }}>{errors.vendor.message}</CustomErrorMessage>}
                    </Spacer>

                </IsGranted>}
                {(changeType === true || type?.label && type.label == 'PM') &&
                    <Spacer top={6} style={{ display: 'flex', position: 'relative' }}>
                        <Spacer style={{ position: 'relative' }}>
                            <DayPicker
                                label='PM Date'
                                className='picker'
                                defaultValue={moment(task?.pmDate).utc().format('YYYY-MM-DD')}
                                name='pm_date'
                                ref={register({ required: "PM Date is required" })}
                                size={'small'}
                                useControllers={true}
                            />
                        </Spacer>
                        <Spacer left={4} style={{ position: 'relative' }}>
                            <Input
                                name='pm_mileage'
                                label='PM Mileage'
                                type='number'
                                ref={register()}
                                size={'small'}
                                defaultValue={task?.pmMileage}
                            />
                            {errors.pm_mileage && <CustomErrorMessage style={{ width: '100%', left: 16, bottom: -17 }}>{errors.pm_mileage.message}</CustomErrorMessage>}
                        </Spacer>
                    </Spacer>
                }

                {logForm && <Spacer top={6} style={{ display: 'flex', position: 'relative' }}>
                    <DatePicker
                        name='date_serviced'
                        label='Date Serviced'
                        ref={register({ required: "Date Serviced is required" })}
                        defaultValue={task?.dateServiced}
                    />
                    {errors.date_serviced && <CustomErrorMessage style={{ bottom: -17 }}>{errors.date_serviced.message}</CustomErrorMessage>}
                </Spacer>}

                <Spacer top={6} style={{ display: 'flex', position: 'relative' }}>
                    <Input
                        name='quote'
                        label='Estimate'
                        type='number'
                        ref={register}
                        size={'small'}
                        step={".01"}
                        defaultValue={task?.quote}
                    />
                    <Spacer left={4}>
                        <Input
                            name='estimateCost'
                            label='Cost'
                            type='number'
                            ref={register}
                            size={'small'}
                            step={".01"}
                            defaultValue={task?.estimateCost}
                        />
                    </Spacer>
                </Spacer>

                <Spacer top={6} />

                <Input ref={register} type={"text"} name={"notes"} label={"Notes"} defaultValue={task?.notes}/>

                <Spacer top={4} />

                <Button type="primary" style={{ display: "block", marginLeft: "auto" }}>
                    Save Task
                </Button>
            </form>
        </Modal>
    );
};

export const openTaskForm = (task, logForm = false) => {
    return portalPromise((success, cancel) => {
        return <TaskForm success={success} cancel={cancel} task={task} logForm={logForm} />;
    });
};
