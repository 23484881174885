import React, { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { Dialog, dialogPromise } from "../../../components/Dialog";
import { Dropdown, Spacer, Alert } from "@dspworkplace/ui";
import DayPicker from "../../../components/Calendar/dayNew";
import API from "../api";
import StationSelector, { useSelectedStation } from "../../../components/StationSelector";
import Loading from "../../../components/Loading";
import { useLocalStorage } from "../../../Auth";
import ShiftBlockSelector from "../../../components/ShiftBlock/selector";
import ComboBox from "../../../components/ComboBox";
import TimePicker from "../../../components/TimePicker";

const ReportIncidentForm = ({ success = () => {}, cancel = () => {}, defaultValues = {} }) => {
    const [selectedStationFromStationSelected] = useSelectedStation();
    const [selectedStation, setSelectedStation] = useLocalStorage("selected", selectedStationFromStationSelected);
    const [isTypeDriverRoute, setIsTypeDriverRoute] = useState(false);
    const [isTypeShift, setIsTypeShift] = useState(false);
    const [isTodayPickOrDropIncident, setIsTodayPickOrDropIncident] = useState(false);
    const [input, setInput] = useState({
        driver: null,
        driverRoute: defaultValues.driverRoute,
        date: defaultValues.date,
    });
    const [options, setOptions] = useState({
        types: [],
        drivers: [],
        driverRoutes: [],
        loaded: false,
    });

    const fetchFormOptions = async () => {
        if (options.loaded)
            setOptions({
                ...options,
                loaded: false,
            });

        API.fetchFormOptions({
            station: selectedStation || selectedStationFromStationSelected,
            driver: defaultValues.driver || input.driver,
            date: defaultValues.date || input.date,
            driverRoute: input.driverRoute,
        }).then(
            (response) => {
                let opt = {
                    drivers: options.drivers,
                    driverRoutes: options.driverRoutes,
                    types: response.data.data.incidentTypes,
                    loaded: true,
                };

                if (response.data.data.drivers) opt.drivers = response.data.data.drivers.driver;

                if (response.data.data.driverRoutes) {
                    opt.driverRoutes = response.data.data.driverRoutes;
                } else {
                    opt.driverRoutes = [];
                }

                if (defaultValues.dropAndPicRoute == false) {
                    opt.types = opt.types.filter((o) => o.token != "Dro" && o.token != "Pic");
                }

                if (input.incidentType == undefined && defaultValues.incidentType) {
                    opt.types.map((option) => {
                        if (
                            defaultValues.incidentType == option.id &&
                            (option.token == "Dro" || option.token == "Pic" || option.token == "Thx")
                        ) {
                            setIsTypeDriverRoute(true);
                        }
                        if (
                            defaultValues.incidentType == option.id &&
                            (option.token == "Coa" || option.token == "Cor" || option.token == "Acc")
                        ) {
                            setIsTypeShift(true);
                        }
                        return true;
                    });
                }
                input.driverRoute = null;
                setInput(input);
                setOptions(opt);
            },
            (error) => console.log
        );
    };

    useEffect(() => {
        fetchFormOptions();
    }, [selectedStation, input.driver, input.date]);

    useEffect(() => {
        if (input.date && selectedStation) {
            setIsTodayPickOrDropIncident(false);
            let tempType = options.types.filter(type => type.id === input.incidentType);
            let tempToken = tempType.length > 0 ? tempType[0].token : null;
            if ((tempToken == 'Dro' || tempToken == 'Pic')) {
                handleCategoryChange(input.date, selectedStation);
            }
        }
    }, [isTypeDriverRoute, input.date, isTypeShift]);

    useEffect(() => {
        if (defaultValues.driverRoute && !defaultValues.driver) {
            fetchFormOptions();
        }
    }, [input.incidentType]);

    const saveInput = (field, value) => {
        let i = { ...input };
        i[field] = value;
        setInput(i);
    };

    const handleStationChange = (station) => {
        setOptions({
            ...options,
            loaded: false,
        });

        setSelectedStation(station);

        if (input.date && station) {
            setIsTodayPickOrDropIncident(false);
            if (isTypeDriverRoute == true || isTypeShift == true) {
                handleCategoryChange(input.date, station);
            }
        }

        API.fetchFormOptions({ station }).then(
            (response) => {
                setOptions({
                    ...options,
                    drivers: response.data.data.drivers.driver,
                    loaded: true,
                });
            },
            (error) => console.log
        );
    };

    const handleCategoryChange = (inputDate, station) => {
        API.checkDropOrPickupInStation(station, inputDate).then(
            (response) => {
                if (response.data.data.result) {
                    input.driver = null;
                    setIsTodayPickOrDropIncident(true);
                } else {
                    setIsTodayPickOrDropIncident(false);
                }
            },
            (error) => console.log
        );
    };

    const shiftOptions = isTypeDriverRoute || isTypeShift
        ? []
        : options.driverRoutes.map((dr) => ({
            ...dr,
            hour: `${dr.startTime}-${dr.endTime}`,
        }));

    return !options.loaded ? (
        <Modal width={"340px"} removeHeader={true} visible={true} setVisible={() => {}}>
            <Spacer all={5} style={{ textAlign: "center" }}>
                <Loading style={{ height: 40 }} />
            </Spacer>
        </Modal>
    ) : (
        <>
            <Dialog
                success={success}
                cancel={cancel}
                align={{
                    header: "left",
                    content: "left",
                    footer: "right",
                }}
                modal={{
                    removeHeader: false,
                }}
                defaultValues={defaultValues}
                title={"Report Incident"}
                fields={[
                    {
                        field: StationSelector,
                        props: {
                            label: "Station",
                            name: "station",
                            Required: true,
                            size: "medium",
                            showAllOption: false,
                            defaultValue: selectedStation,
                            onChange: handleStationChange,
                        },
                    },
                    {
                        field: Dropdown,
                        props: {
                            label: "Category",
                            name: "incidentType",
                            Required: true,
                            options: options.types.map((opt) => ({
                                name: opt.name,
                                value: opt.id,
                                data: opt.token,
                            })),
                            visibleOptionsQty: 6,
                            defaultValue: input.incidentType,
                            onChange: (v) => {
                                saveInput("incidentType", v.value);
                                if (v.data == "Dro" || v.data == "Pic" || v.data == "Thx") {
                                    setIsTypeDriverRoute(true);
                                } else {
                                    setIsTypeDriverRoute(false);
                                }
                                if (v.data == "Coa" || v.data == "Cor" || v.data == "Acc") {
                                    setIsTypeShift(true);
                                } else {
                                    setIsTypeShift(false);
                                }
                            },
                        },
                    },
                    {
                        field: ComboBox,
                        props: {
                            label: "Driver",
                            name: "driver",
                            Required: isTypeDriverRoute ? false : true,
                            Displayed: isTypeDriverRoute ? false : true,
                            type: "people",
                            options: options.drivers.map((opt) => ({
                                name: opt.name,
                                value: opt.id,
                                profile: opt.image,
                            })),
                            visibleOptionsQty: 6,
                            defaultValue: input.driver,
                            onChange: (v) => saveInput("driver", v),
                            spanStyle: { position: "relative", zIndex: 2 },
                        },
                    },
                    {
                        field: DayPicker,
                        props: {
                            label: "Incident Date",
                            name: "date",
                            Required: true,
                            defaultValue: input.date,
                            onChange: (v) => v.utc && saveInput("date", v.utc.match(/\d{4}-\d{2}-\d{2}/)[0]),
                        },
                    },
                    {
                        field: ShiftBlockSelector,
                        props: {
                            name: "driverRoute",
                            Required: isTypeDriverRoute || isTypeShift ? false : shiftOptions.length ? true : false,
                            shifts: shiftOptions,
                        },
                    },
                    {
                        field: TimePicker,
                        props: {
                            label: "Incident Time",
                            name: "time",
                            Required: true,
                        },
                    },
                ]}
                buttons={[
                    {
                        text: "Save and Finish Later",
                        size: "default",
                        action: "later",
                        props: {
                            Displayed: isTodayPickOrDropIncident ? false : true,
                        },
                    },
                    {
                        text: "Next Step",
                        type: "primary",
                        size: "default",
                        action: "next",
                        props: {
                            Displayed: isTodayPickOrDropIncident ? false : true,
                        },
                    },
                ]}
                children={
                    isTodayPickOrDropIncident && (
                        <Alert.Error
                            title="Error"
                            content={"You can add only two drop/pickup incident in a day for each station."}
                        />
                    )
                }
            />
        </>
    );
};

const openReportIncidentForm = (defaultValues) => {
    return dialogPromise((success, cancel) => (
        <ReportIncidentForm success={success} cancel={cancel} defaultValues={defaultValues} />
    ));
};

export { openReportIncidentForm };
