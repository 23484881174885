import React, { useEffect, useState, useRef, forwardRef, useLayoutEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Icon, Spacer, Theme, ThirdTitle } from "@dspworkplace/ui";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ScrollyOverlayStyle } from "../Modal";
import IconAccidentInjury from "../../pages/SchedulerLoadOut/accident_injury.svg";

const TimeLine = styled.div`
    display:flex;
    overflow: hidden;

    >.event {
        display:flex;
        flex-direction:column;
        align-items:center;
        margin-right:12px;
        position:relative;
    }
`

const ListName = styled.span`
    font-size:14px;
    color:#707070;
    width:70px;
    display:inline-block;
`

const ListContent = styled(ListName)`
    color:#333333;
`

const DateText = styled.h6`
    font-size:12px;
    font-weight:bold;
    font-family:circe-rounded;
    color:#707070;
`;

const Box = styled.div`
    height: ${props => props.hasSchedule ? 38 : 32}px;
    ${props => props.hasSchedule ? 'margin-top: -3px;' : undefined}
    // min-width: 120px;
    padding-left:4px;
    padding-right:7.2px;
    border-radius:2px;
    background: ${props => props.color ? props.color : '#EAF0F6'};
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor:pointer;
    overflow: hidden;
    ${props => props.isDeleted && !props.isRestore && `
        opacity: .2;
        filter: saturate(0.5);
        
        &:after {
            content: ' ';
            position:absolute;
            height: 100%;
            width: 100%;
            background: #ccc;
            display: block;
            clip-path: polygon(95% 0, 100% 4%, 4% 100%, 0 98%);
            top:0;
            left:0;
            
        }
    `}


    &:hover,
    &.opened {
        box-shadow: 0 0 0 2px ${props => props.color ? props.color : '#EAF0F6'}55;
    }

    ${props => props.timeline && `
    height: 36px;
    margin-top: -2px;
    `}

    .hasTimeline {
        position:absolute;
        bottom:0px;
        width:100%;
        left:0;
        height: 3px;
        background-image: linear-gradient(90deg, ${props => props.lineColor || 'white'} 50%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 10px 3px;
        background-repeat: repeat-x;
        opacity:0.5;
        transform: skewX(-45deg);
    }
`;

const Text = styled.p`
    font-size:10px;
    font-weight:500;
    color:${props => props.color ? props.color : '#333333'};

    &.small {
        font-size:8px;
    }
`;

export const Popup = styled.div`
    padding: 16px 20px 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #33333326;
    z-index:200;
    width:376px;
    box-sizing:border-box;
    cursor: default;

    position: fixed;
    ${props => props.top !== undefined ? `
    top: ${props.top}px;
    ` : ''}
    ${props => props.left !== undefined ? `
    left: ${props.left}px;
    ` : ''}
    transition: all .1s ease-out;

    .popup-arrow {
        // let's just not worry about this right now
        display: none;

        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right:5px solid white;
        position:absolute;
        left: -4px;
        top: 50%;
        margin-top:-5px;
    }

    &.left-sided .popup-arrow {
        left: 99.9%;
        transform: rotate(180deg)
    }

    &.bottom-position .popup-arrow {
        top: initial;
        bottom: 5%;
    }

    &.top-position .popup-arrow {
        bottom: initial;
        top: 5%;
    }

    &.top-side .popup-arrow {
        top:-2px;
        left:5px;
        transform: rotate(90deg);
    }

    &.bottom-side .popup-arrow {
        bottom: -7px;
        left: 5px;
        transform: rotate(-90deg);
        top: unset;
    }
`;

const Alert = styled.div`
    position:absolute;
    left:-6px;
    height:22px;
    width:22px;
    background:#F5C26B;
    border:2px solid white;
    border-radius:100%;
    top:6px;
    display:flex;
    justify-content:center;
    align-items:center;
`

const CustomAlert = styled(Alert)`
    border-color:${props => props.bg};
    background:${props => (props.backgroud) ? props.backgroud : 'white'};
`

const AlertNumber = styled(Alert)`
    background:#7C98B6;
    color:#fff;
    font-size:12px;
    font-weight:bold;
`;

const ShiftPopupOverlay = styled(ScrollyOverlayStyle)`
    background: none;
`;

const DropdownWrapper = styled.ul`
    padding: 0px;
    margin: 4px 0px;
    background: white;
    border: 1px solid rgb(204, 204, 204);
    box-shadow: rgb(204, 204, 204) 0px 0px 6px;
    border-radius: 2px;
    list-style: none;
    width: auto;
    display: inline-block;
    position: absolute;
    z-index: 1000;
    max-height: 3200px;
    overflow: hidden scroll;
`;

const DropdownHeader = styled.div`
    color: rgb(112, 112, 112);
    font-size: 12px;
    padding: 8px;
    font-weight: bold;
    cursor: default;
    white-space: nowrap;
`;

const DropdownItem = styled.li`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: rgb(112, 112, 112);
    height: 32px;
    cursor: pointer;
    padding: 0px 4px 0px 8px;

    &.alone {
        border-top: 1px solid rgb(204, 204, 204);
    }

    &:hover {
      background: #f1f1f1;
    }

    &::before {
      content: " ";
      height: 100%;
      width: 6px;
      background: transparent;
      display: block;
      position: absolute;
      right: -7px;
    }
`;

const ItemLabel = styled.span`
    white-space: nowrap;
    overflow: hidden;
    color: rgb(112, 112, 112);
    display: inline-block;
    flex: 1 1 0%;
    font-size: 14px;
    line-height: 16px;
    margin-right: 4px;
`;

const initialPosition = { left: 0, top: 0, direction: 'right' };

const Block = forwardRef(({
    date,
    hour,
    type,
    background,
    color,
    children,
    hasAlert,
    hasIncident,
    timeline,
    containerClass,
    isExempt,
    schedule,
    routeCode,
    sentToHome,
    wrapperId,
    hasInvoice,
    hasCallOutOrNcns,
    isTimeOff,
    hasAnySinglePunch,
    refuseRescue,
    isDeleted,
    isRestore,
    onClickHandler,
    category,
    driver,
    selectedRows,
    setSelectedRows,
    prefferStatus,
    shift,
    handleDelete,
    menuVisible, 
    setMenuVisible,
    handleOpenDelete
}, ref) => {
    const [open, setOpen] = useState(false);
    const [position, setPosition] = useState(initialPosition);
    const [view, setView] = useState(timeline && timeline.length > 0 ? 'timeline' : 'default');

    const container = containerClass ? document.getElementsByClassName(containerClass)[0] : document.getElementById('root');

    const popupRef = useRef(false);
    const data = (shift === undefined) ? driver : shift;
    const isSpecialOrDeletedShift = (!data || data?.isTrain ||
        data?.isRescuer || data?.isSecondShift || data?.isDuty || data?.isTimeOff ||
        type == 'UNAVAILABLE');
    const options = [
        { value: 'delete-shift', label: 'Delete Shift', className: 'alone' },
        { value: 'drop-route-to-open', label: 'Delete Shift/Move Route to Open', className: '' },
    ];
        
    const handleOpenPopup = (e, driver) => {
        e.stopPropagation();
        if (e.shiftKey && !isSpecialOrDeletedShift) {
            setSelectedRows((prevSelectedRows) => {
                const isSelected = prevSelectedRows.some((row) => row.id === data.id);
                if (isSelected) {
                    return prevSelectedRows.filter((row) => row.id !== data.id);
                } else {
                    return [...prevSelectedRows, data];
                }
            });
            return null;
        } else if (e.shiftKey && selectedRows?.length > 0 && isSpecialOrDeletedShift) {
            return;
        }
        setOpen(true);
        if (selectedRows?.length > 0) {
            setSelectedRows([])
            setOpen(false);
        }
        if (onClickHandler) onClickHandler();
    }

    useEffect(() => {
        if (!children) return;

        if (open) {
            const popupPosition = solvePosition(popupRef.current, 'popup', container, containerClass);
            setPosition(popupPosition);
        }

        if (wrapperId) {
            const wrapper = document.getElementById(wrapperId);

            if (!wrapper)
                return;

            wrapper.classList[open ? 'add' : 'remove']('opened-children');
        }

    }, [children, container, containerClass, open]);

    color = color || '#ffffff';

    const shiftAlertType = solveAlertTypeShift(sentToHome, hasIncident, refuseRescue, type, category);

    const PortalComponent = children && open
        ? ReactDOM.createPortal(
            <ShiftPopupOverlay>
                <Popup
                    className={`popup ${position.direction}`}
                    hasContainer={containerClass ? true : false}
                    left={position.left}
                    top={position.top}
                >
                    <span className='popup-arrow' />
                    {children({ open: open, setOpen: setOpen })}
                </Popup>
            </ShiftPopupOverlay>,
            container
        ) : null;

    const disableMultiSelect = useMemo(() => {
        if (selectedRows?.length > 0 && isSpecialOrDeletedShift) {
            return 'none'
        } else {
            return ''
        }
    }, [selectedRows])

    const handleClickOutside = (event) => {
        setMenuVisible && setMenuVisible(null);
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleRightClick = (event) => {
        event.preventDefault();
        if (setMenuVisible) {
            const boundingRect = popupRef.current.getBoundingClientRect();
            setPosition({ top: boundingRect.bottom, left: boundingRect.left });
            setMenuVisible(data?.id);
        }
    };

    const onClickDeleteHandler = (e, option, data) => {
        e.stopPropagation();
        if (option.value === 'delete-shift') {
            handleDelete(data);
            setMenuVisible(null);
        } else {
            handleOpenDelete(data);
            setMenuVisible(null);
        }
    }

    const PortalDropdown = menuVisible && menuVisible === data?.id && (
        ReactDOM.createPortal(
            <DropdownWrapper style={{ top: `${position.top}px`, left: `${position.left}px` }}>
                <DropdownHeader>Delete/Drop Options</DropdownHeader>
                {options.map((option, index) => (
                    <DropdownItem
                        key={index}
                        value={option.value}
                        className={option.className}
                        onClick={(e) => {
                            onClickDeleteHandler(e, option, data);
                        }}
                    >
                        <ItemLabel>{option.label}</ItemLabel>
                    </DropdownItem>
                ))}
            </DropdownWrapper>,
            document.body
        )
    );

    return (
        <div>
            <DateText>{date}</DateText>
            <div style={{
                position: 'relative', paddingLeft: (hasAlert || shiftAlertType) ? 6 : 0,
                boxShadow: selectedRows?.some(row => row.id === data?.id) ?
                    'rgba(189,55,164,0.5) 0px 0px 0px 4px' : 'none', userSelect: 'none',
                opacity: disableMultiSelect ? '0.5' : '1'
            }}
            ref={popupRef} onClick={(e) => { handleOpenPopup(e, driver); }} onContextMenu={handleRightClick}>
                {PortalDropdown}
                {hasAlert && <AlertType hasAlert={hasAlert} />}
                {!hasAlert && shiftAlertType && <AlertTypeShift bg={background} type={shiftAlertType} />}
                <Box color={background} style={{ paddingLeft: (hasAlert || shiftAlertType) ? 12 : 4 }} className={open ? 'opened' : ''} timeline={view === 'timeline'} hasSchedule={!!(schedule)} isDeleted={isDeleted} hasAlert={hasAlert} isRestore={isRestore}>
                    {view === 'default' &&
                        <div className='info' style={{ overflow: 'hidden', width: '100%' }}>
                            {(hasAnySinglePunch || (!sentToHome && !hasCallOutOrNcns && !isTimeOff && type != 'UNAVAILABLE')) && <Text color={color}>
                                {prefferStatus == 2 && <svg width="8" height="8" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector" d="M8.871 0.003C7.704 0.003 6.75 0.585 6.129 1.491C6.066 1.584 5.931 1.584 5.868 1.491C5.25 0.582 4.293 0.003 3.126 0.003C1.542 0.003 0 1.395 0 3.282C0 5.364 1.527 7.131 2.94 8.334C3.753 9.021 4.632 9.633 5.559 10.155C5.643 10.203 5.727 10.248 5.814 10.293C5.874 10.323 5.937 10.338 6 10.338C6.063 10.338 6.129 10.323 6.186 10.293C6.27 10.248 6.354 10.203 6.438 10.155C6.597 10.065 6.819 9.936 7.089 9.768C7.779 9.336 8.436 8.856 9.06 8.331C10.473 7.131 12 5.361 12 3.279C12 1.392 10.455 0 8.871 0V0.003Z" fill={color} />
                                </svg>}
                                {prefferStatus == 3 && <svg width="8px" height="8px" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M-618-1464H782v3600H-618zM0 0h24v24H0z"></path><path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zM4 12c0-4.4 3.6-8 8-8 1.8 0 3.5.6 4.9 1.7L5.7 16.9C4.6 15.5 4 13.8 4 12zm8 8c-1.8 0-3.5-.6-4.9-1.7L18.3 7.1C19.4 8.5 20 10.2 20 12c0 4.4-3.6 8-8 8z"></path></svg>}
                                {hour}</Text>}
                            {schedule && <Text color={color} className='small'>Schedule: {schedule}</Text>}
                            <Text color={color} className='small'>
                                {type == 'UNAVAILABLE' && prefferStatus == 2 && <svg width="8" height="8" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path id="Vector" d="M8.871 0.003C7.704 0.003 6.75 0.585 6.129 1.491C6.066 1.584 5.931 1.584 5.868 1.491C5.25 0.582 4.293 0.003 3.126 0.003C1.542 0.003 0 1.395 0 3.282C0 5.364 1.527 7.131 2.94 8.334C3.753 9.021 4.632 9.633 5.559 10.155C5.643 10.203 5.727 10.248 5.814 10.293C5.874 10.323 5.937 10.338 6 10.338C6.063 10.338 6.129 10.323 6.186 10.293C6.27 10.248 6.354 10.203 6.438 10.155C6.597 10.065 6.819 9.936 7.089 9.768C7.779 9.336 8.436 8.856 9.06 8.331C10.473 7.131 12 5.361 12 3.279C12 1.392 10.455 0 8.871 0V0.003Z" fill={color} />
                                </svg>}
                                {type == 'UNAVAILABLE' && prefferStatus == 3 && <svg width="8px" height="8px" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M-618-1464H782v3600H-618zM0 0h24v24H0z"></path><path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zM4 12c0-4.4 3.6-8 8-8 1.8 0 3.5.6 4.9 1.7L5.7 16.9C4.6 15.5 4 13.8 4 12zm8 8c-1.8 0-3.5-.6-4.9-1.7L18.3 7.1C19.4 8.5 20 10.2 20 12c0 4.4-3.6 8-8 8z"></path></svg>}
                                {type}
                            </Text>
                        </div>
                    }
                    {view === 'timeline' &&
                        <TimeLine>
                            {timeline.map((event, key) => (
                                <TimeLineTrack key={key} event={event} color={color} container={container} containerClass={containerClass} />
                            ))}
                        </TimeLine>
                    }
                    <div style={{ display: 'inline-flex' }}>
                        {routeCode && routeCode.map((route, i) =>
                            <span style={{
                                border: `1px solid ${color}`,
                                borderRadius: 2,
                                fontSize: 9,
                                color: color,
                                padding: 2,
                                marginRight: i == routeCode.length - 1 && isExempt ? 28 : 0,
                                marginLeft: 4,
                                position: 'relative',
                                top: -2.5
                            }} key={i}>{route}</span>
                        )}

                        {!sentToHome && hasInvoice && <span style={{
                            fontSize: 10,
                            color: color,
                            border: "1px solid #fff",
                            borderRadius: "50%",
                            padding: "2px 5px",
                            marginLeft: 8,
                            position: 'relative',
                            top: -2.5
                        }}>$</span>}

                        {isExempt && <span style={{
                            fontSize: 10,
                            color: color,
                            border: "1px solid #fff",
                            borderRadius: "50%",
                            padding: "2px 5px",
                            marginLeft: 8,
                            position: 'relative',
                            top: -2.5
                        }}>E</span>}

                        <Icon.ArrowDown color={`${color}4D`} size='16px' style={{ verticalAlign: 'middle', marginLeft: 4 }} />
                    </div>
                    {/*{view === 'timeline' ? <div className='hasTimeline'/> : null}*/}
                </Box>
                {PortalComponent}
            </div>
        </div>
    )
})

const TimeLineTrack = ({ event, color, container, containerClass }) => {
    const [open, setOpen] = useState(false);
    const [position, setPosition] = useState(initialPosition);

    const popupRef = useRef([]);

    useOutsideClick(popupRef, (e) => {
        setTimeout(function () { setOpen(false); }, 500);
    }, 'mouseup');

    useEffect(() => {
        if (open) {
            const popupPosition = solvePosition(popupRef.current, 'popupTimeLine', container, containerClass);
            setPosition(popupPosition);
        }

    }, [open])

    const popupContent = !event.eventTime ? <Text>No content to this event</Text> :
        (<>
            <ThirdTitle>{event.description}</ThirdTitle>
            {event.location.lat && <Spacer top={2}>
                <ListName>Lat/Lng</ListName>
                <ListContent><a href={"https://www.google.com/maps/search/?api=1&query=" + event.location.lat + "%2C" + event.location.long} target="_blank">{event.location.lat},{event.location.long}</a></ListContent>
            </Spacer>}
            {event.device.id && <Spacer top={2}>
                <ListName>Device</ListName>
                <ListContent>{event.device.id}</ListContent>
            </Spacer>}
            {event.vehicle.vin && <Spacer top={2}>
                <ListName>Vehicle</ListName>
                <ListContent>{event.vehicle.vin}</ListContent>
            </Spacer>}
        </>)
    const PopupComponent = open ? <Popup className={`popupTimeLine ${position.direction}`} left={position.left} top={position.top}><span className='popup-arrow'></span>{popupContent}</Popup> : null;

    return (
        <div className='event' ref={popupRef} onClick={(e) => { e.stopPropagation(); setOpen(true) }}>
            {solveIcon(event.type, color)}
            <span style={{ fontSize: 9, color: color }}>{event.eventTime}</span>
            {container && ReactDOM.createPortal(PopupComponent, container)}
        </div>
    )
}

const AlertType = ({ hasAlert }) => {
    const type = typeof (hasAlert);
    switch (type) {
        case "number":
            return (<AlertNumber><span>{hasAlert}</span></AlertNumber>)
            break;
        default: return (<Alert><Icon.Warning size='12px' color='#333333' /></Alert>)
    }
}

const AlertTypeShift = ({ bg, type }) => {
    switch (type) {
        case "NCNS":
            return (<CustomAlert bg={bg} title={type}><Icon.Times size='15px' color={bg} /></CustomAlert>)
            break;
        case "Call Out":
            return (<CustomAlert bg={bg} title={type}><Icon.Phone size='13px' color={bg} /></CustomAlert>)
            break;
        case "Late":
            return (<CustomAlert bg={bg} title={type}><Icon.Clock size='14px' color={bg} /></CustomAlert>)
            break;
        case "Accident/Injury":
            return (<CustomAlert bg={bg} backgroud={"#448a4c"} title={type}><img src={IconAccidentInjury} color={bg} style={{ height: "20px", width: "20px" }} /></CustomAlert>)
            break;
        case "Rescue":
            return (<CustomAlert bg={bg} title={type}>
                <Icon.Times style={{
                    transform: 'rotate(45deg)',
                    marginTop: -1,
                    stroke: 'red',
                    strokeWidth: 3
                }} size='16px' color={'red'} /></CustomAlert>)
            break;
        case "Sweep":
            return (<CustomAlert bg={bg} title={type}>
                <Icon.Broom style={{
                    transform: 'rotate(45deg)',
                    marginTop: -1,
                    strokeWidth: 3
                }} size='16px' /></CustomAlert>)
            break;
        case "sentToHome":
            return (<CustomAlert bg={bg} title={'Sent To Home'}><Icon.BackHome size='13px' color={bg} /></CustomAlert>)
            break;
        case "refuseRescue":
            return (<CustomAlert bg={bg} title={'Refuse Rescue'}><Icon.Times style={{ 'position': 'absolute', transform: 'rotate(45deg)', marginTop: -1, stroke: 'red', strokeWidth: 3 }} size='16px' color={'red'} /><Icon.NotAllowed style={{ 'position': 'absolute', strokeWidth: 3 }} size='22px' color={'blue'} /></CustomAlert>)
            break;
        case "Light Duty":
            return (<CustomAlert bg={bg} title={type}><Icon.Feather size='13px' color={bg} /></CustomAlert>)
            break;
        case "BackupShift":
            return (<CustomAlert bg={bg} title={type}><Icon.Replace size='13px' color={bg} /></CustomAlert>)
            break;
        default: return null;
    }
}

const solveAlertTypeShift = (sentToHome, hasIncident, refuseRescue, type, category) => {
    if (sentToHome)
        return 'sentToHome';

    if (refuseRescue)
        return 'refuseRescue';

    if (hasIncident)
        return hasIncident;

    if (category === 2) {
        return "BackupShift";
    }

    const allowedTypes = ['Rescue', 'Light Duty', 'Sweep'];
    if (allowedTypes.includes(type))
        return type;

    return false;

}

const solveIcon = (type, color) => {
    const commonStyle = { verticalAlign: 'text-bottom', paddingLeft: 7, paddingRight: 7 };

    let selectedIcon = Icon[type] ? Icon[type] : Icon.Info;

    return React.createElement(
        selectedIcon,
        {
            size: '16px',
            color: color || Theme.colors.info.text,
            style: commonStyle
        }
    )
};

export const solvePosition = (elReference, popupClass, container, containerClass) => {
    let posLeft, posTop, direction = 'right';
    let popupUpEl = document.getElementsByClassName(popupClass)[0];
    // let popup = popupUpEl.getBoundingClientRect();
    let elBound = elReference.getBoundingClientRect();

    const position = new PositionSelector(elBound, popupUpEl, containerClass);
    posLeft = position.posLeft;
    posTop = position.posTop;

    return { left: posLeft, top: posTop, direction }
}

class PositionSelector {
    constructor(elementRef, popup, containerClass) {
        this.elementRef = elementRef;
        this.popupElm = popup;
        this.popup = this.popupElm.getBoundingClientRect();
        const containerDom = containerClass ? document.getElementsByClassName(containerClass)[0] : document.getElementById('root');
        this.container = containerDom.getBoundingClientRect();
        this.useContainerToCalc = containerClass ? true : false;

        this.popupCurrentPosition = { Y: { value: 0, position: null }, X: { value: 0, position: null } }

        const positionY = this.findYOptions();
        const positionX = this.findXOptions();

        // console.log('Y',positionY);
        // console.log('X',positionX);

        const valueY = this.Y[positionY[0]]() - (this.useContainerToCalc ? this.container.top : 0);
        const valueX = this.X[positionX[0]]() - (this.useContainerToCalc ? this.container.left : 0);
        this.popupCurrentPosition.Y = { value: valueY, position: positionY[0] };
        this.popupCurrentPosition.X = { value: valueX, position: positionX[0] };

        this.exceptionHandler(positionY, positionX);

        // console.log(this.popupCurrentPosition);

        return { posLeft: this.popupCurrentPosition.X.value, posTop: this.popupCurrentPosition.Y.value }
    }

    findYOptions = () => {
        const remainSpace = this.findContainerLimits.Y();

        let ret = [];
        if (remainSpace.top >= 0 && remainSpace.bottom <= this.container.height) {
            const halfHeight = this.popup.height / 2;
            //console.log('aa',this.popup.height,remainTopSpace,remainBottomSpace)
            if (halfHeight <= remainSpace.top && halfHeight <= remainSpace.bottom) {
                ret.push('middle');
            }
            if (this.popup.height + this.elementRef.height <= remainSpace.top) {
                ret.push('refBottom');
            }
            if (this.popup.height + this.elementRef.height <= remainSpace.bottom) {
                ret.push('refTop');
            }
            if (this.popup.height <= remainSpace.bottom) {
                ret.push('bottom');
            }
            if (this.popup.height <= remainSpace.top) {
                ret.push('top');
            }
        }

        if (!ret.length)
            ret.push('adapt');

        return ret;
    }

    findXOptions = () => {
        const remainSpace = this.findContainerLimits.X();

        let ret = []

        if (remainSpace.left > 0 && remainSpace.left <= this.container.width) {
            const halfWidth = (this.popup.width / 2);
            if (this.popup.width <= remainSpace.right) {
                ret.push('right');
            }
            if (this.popup.width <= remainSpace.left) {
                ret.push('left');
            }
            if (this.popup.width - this.elementRef.width <= remainSpace.right) {
                ret.push('refLeft');
            }
            if (this.popup.width + this.elementRef.width <= remainSpace.left) {
                ret.push('refRight');
            }
            if (halfWidth <= remainSpace.left && halfWidth <= remainSpace.right) {
                ret.push('middle');
            }
        }

        if (!ret.length)
            ret.push('adapt');

        return ret;
    }

    exceptionHandler = (positionY, positionX) => {

        if (positionY[0] === 'adapt') {
            let diffY = window.innerHeight - this.popup.height;
            if (diffY < 0) {
                this.popupElm.style.height = window.innerHeight + 'px';
                this.popupElm.style.overflow = 'auto';
            } else
                this.popupCurrentPosition.Y = {
                    value: diffY / 2,
                    position: 'adapt'
                }

            return;
        }

        if (this.popupCurrentPosition.Y.position === 'middle' && this.popupCurrentPosition.X.position === 'refLeft') {
            const remainX = this.findContainerLimits.X();
            if (this.popup.width >= remainX.left && this.popup.width >= remainX.right) {
                const valueY = this.Y[positionY[0]]() - (this.useContainerToCalc ? this.container.top : 0);
                const valueX = this.X.adapt();
                this.popupCurrentPosition.Y = { value: valueY, position: positionY[0] };
                this.popupCurrentPosition.X = { value: valueX, position: 'adapt' };
            }
        }

        if (this.popupCurrentPosition.Y.position === 'top' && this.popupCurrentPosition.X.position === 'right') {
            const fixedPosition = 'refBottom';
            const valueY = this.Y[fixedPosition]() - (this.useContainerToCalc ? this.container.top : 0);
            this.popupCurrentPosition.Y = { value: valueY, position: fixedPosition };
        }

    }

    findContainerLimits = {
        X: () => {
            const currentElLeft = this.elementRef.left - (this.useContainerToCalc ? this.container.left : 0);

            let remainSpace = { left: 0, right: 0 };
            remainSpace.left = currentElLeft;
            remainSpace.right = this.container.width - (this.elementRef.width + currentElLeft);

            return remainSpace
        },
        Y: () => {
            const currentElTop = this.elementRef.top - (this.useContainerToCalc ? this.container.top : 0);

            let remainSpace = { top: 0, bottom: 0 };
            remainSpace.top = currentElTop;
            remainSpace.bottom = this.container.height - (this.elementRef.height + currentElTop);

            return remainSpace
        }
    }


    Y = {
        top: () => this.elementRef.top - this.popup.height,
        middle: () => this.elementRef.top + (this.elementRef.height / 2) - (this.popup.height / 2),
        bottom: () => this.elementRef.top + this.elementRef.height,
        refTop: () => this.elementRef.top,
        refBottom: () => (this.elementRef.top + this.elementRef.height) - this.popup.height,
        adapt: () => 0
    }

    X = {
        right: () => this.elementRef.left + this.elementRef.width,
        left: () => this.elementRef.left - this.popup.width,
        center: () => this.elementRef.left + (this.elementRef.width / 2) - (this.popup.width / 2),
        refLeft: () => this.elementRef.left,
        refRight: () => (this.elementRef.left + this.elementRef.width) - this.popup.width,
        adapt: () => 0
    }
}

export default Block;