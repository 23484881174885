const YES_NO = ["Yes","No"];

export const vehicle_status_lookup = ["Active", "Inactive", "Under Repair", "Grounded", "Being Serviced", "Body Shop", "Archived"];
export const employee_status_lookup = ["Terminated", "Offboarded", "Inactive", "Onboarding",'Active'];
export const device_type_lookup = ["Personal", "Corporate"];
export const chart_types = [
    { value: 'pie', name: 'Pie' },
    { value: 'bar', name: 'Bar' },
    { value: 'table', name: 'Table' },
    { value: 'number', name: 'Number' },
];
export const permission_level = ["ROLE_DISPATCHER", "ROLE_STATION_MANAGER", "ROLE_LEAD_DRIVER", "ROLE_OWNER",
    "ROLE_DELIVERY_ASSOCIATE", "ROLE_OPERATIONS_MANAGER", "ROLE_ASSISTANT_STATION_MANAGER",
    "ROLE_FLEET_MANAGER", "ROLE_HR_ADMINISTRATOR", "ROLE_CENTRAL_DISPATCHER", "ROLE_OPERATIONS_ACCOUNT_MANAGER",
    "ROLE_DISPATCHER_WITH_NO_OPEN_SHIFT_FEATURE"];

export const amz_weekly_tier = ["Poor", "Fair", "Great", "Fantastic"];
export const vehicle_task_amz_status = ["Active", "Out of Service", "Grounded", "Maintenance", "Body Shop"];
export const vehicle_task_priority = ["Critical", "Urgent", "Soon", "Can Wait", "Unknown"];
export const vehicle_task_status = ["complete", "open", "on_hold", "pending", "assigned"];


export default {
    amzweekly:{
        tier: amz_weekly_tier,
        status: employee_status_lookup
    },
    customerdeliveryfeedback:{
        tier: amz_weekly_tier
    },
    netradyneeoc:{
        status: employee_status_lookup
    },
    ementor:{
        status: employee_status_lookup
    },
    vehicletasktracker:{
        amz_fleet_tool_status: vehicle_task_amz_status,
        priority: vehicle_task_priority,
        van_status: vehicle_status_lookup,
        status: vehicle_task_status
    },
    vehicle: {
        status: vehicle_status_lookup,
        amazon_branded: YES_NO
    },
    employee: {
        status: employee_status_lookup,
        role_name: permission_level
    },
    shift: {
        device_type: device_type_lookup,
        rts_completed: YES_NO,
        loadout_completed: YES_NO,
        shift_is_backup: YES_NO,
        shift_is_light_duty: YES_NO,
        shift_has_bag: YES_NO,
        shift_is_open_shift: YES_NO
    }
};