import React, { useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import {
    Icon,
    Spacer,
    Theme,
    ThirdTitle,
    Text,
    InputColor,
    SubTitle,
    Input,
    Button,
    ButtonDropdown,
    TagInput,
    Title,
} from "@dspworkplace/ui";
import { Table, TableData, TableFooter, TableHeader, TableRow } from "../../../../../components/Table";
import { METRIC_COLUMNS } from "../../../schema";
import Skeleton from "../../../../../components/Skeleton";
import Empty from "../../../../../components/Empty";
import {
    AutomatedTipsPreviewStyle,
    CoachingPreviewStyle,
    getFieldsForMetric,
    LoadingRow,
    SaveGoalToCompetitionDialog,
} from "../../coaching";
import API from "./api";
import { reducer as competitionReducer } from "./reducer";
import { ButtonIcon, Card, CardDrag, Label, Link, Status, Tag } from "../../../../../components/UI";
import ScorecardContext from "../../../context";
import Loading, { LoadingView, LoadingWrapper } from "../../../../../components/Loading";
import useScorecardApi from "../../../../../api/scorecard";
import { useForm } from "react-hook-form";
import { showToast } from "../../../../../Utilities";
import { AddOrEdit } from "../../../../SchedulerLoadOut";
import { Action } from "../../../../Scheduler/Common/UI";
import apiFactory from "../../../../../api/factory";
import Modal from "../../../../../components/Modal";
import { SlideEditor } from "../../../../Announcements/Sections/Slides/Editor";
import { Dialog, dialogPromise } from "../../../../../components/Dialog";
import { confirm } from "../../../../../components/Confirm";
import { useSelectedStation } from "../../../../../components/StationSelector/StationPicker";
import DatePicker from "../../../../../components/Calendar/picker";
import StationSelector from "../../../../../components/StationSelector";
import moment from "moment";
import { MembersSelect } from "../../../../Dashboard/form/EditChartForm/MembersSelect";
import WithDragAndDrop, { DragItem } from "../../../../../components/dragHandler";
import styled from "styled-components";
import { exit } from "process";

export const STATUS = {
    PENDING: "pending",
    NEW_ROW: "new_row",
    READY: "ready",
};

const CustomStatus = styled(Status)`
    height: auto;
    display: flex;
    align-items: baseline;
    width: 90%;
    word-break: initial;
    line-height: 1.5;
`;

const CustomSpacer = styled(Spacer)`
position:relative;

&:before {
    content: "*";
    position:absolute
    left:6px;
    top:0;    
  }
`;

const useCompetitionState = () => {
    const [state, dispatch] = useReducer(competitionReducer, {
        status: STATUS.PENDING,
        competitions: [],
    });

    const actions = React.useCallback(() => {
        return {
            loadCompetitions: (competitions, metrics) => {
                dispatch({ type: "LOAD_COMPETITIONS", payload: { competitions, metrics } });
            },
            addCompetition: (competition) => {
                dispatch({ type: "ADD_COMPETITION", payload: { competition } });
            },
            setStatus: (status) => {
                dispatch({ type: "SET_STATUS", payload: { status } });
            },
        };
    }, []);

    return [state, { dispatch, ...actions() }];
};

const openHandleCompetition = ({ teams = [] }) => {
    return dialogPromise((success, cancel) => <HandleCompetition success={success} cancel={cancel} />);
};

const HandleCompetition = ({ teams = [], success, cancel }) => {
    const [teamsList, setTeamsList] = useState(teams);
    const [loading, setLoading] = useState(!!teams);
    const [station] = useSelectedStation();

    const handleSubmit = ({ data }) => {
        const period = data.period.split(" – ");
        data.startDate = period[0];
        data.endDate = period[1];
        success(data);
    };

    const fields = useMemo(() => {
        let fields = [];

        fields.push({
            field: Input,
            props: {
                name: "competitor",
                label: "Competition Name",
                Required: true,
            },
        });

        fields.push({
            field: DatePicker,
            props: {
                name: "period",
                label: "Period",
                help: "When the competition starts you no longer can't edit the period.",
                range: "week",
                disableDates: {
                    before: new Date(),
                },
                input: "[W]W/YYYY",
                Required: true,
            },
        });

        fields.push({
            field: TagInput,
            props: {
                name: "teams",
                label: "Teams",
                options: Object.keys(teamsList).map((k) => {
                    const e = teamsList[k];
                    return {
                        name: e?.user?.friendlyName ?? e.name,
                        value: e.id,
                        entity: e,
                    };
                }),
                Required: true,
            },
        });

        return fields;
    }, [teamsList]);

    useEffect(() => {
        API.getAllTeams({ stationId: station }).then((result) => {
            const data = result.data?.data?.teams;
            if (data) {
                const dataWithIndex = data.reduce((acc, t) => {
                    acc[t.id] = t;
                    return acc;
                }, {});
                setTeamsList(dataWithIndex);
            }
            setLoading(false);
        });
    }, []);

    return (
        <Dialog
            success={handleSubmit}
            cancel={cancel}
            modal={{
                removeHeader: false,
                closeOnOverlay: false,
                title: false ? "Edit Competition" : "Add Competition",
            }}
            align={{
                header: "left",
                content: "left",
                footer: "right",
            }}
            fields={[...fields]}
            buttons={[
                {
                    type: "primary",
                    text: "Save",
                },
            ]}
        >
            {loading && (
                <LoadingWrapper>
                    <Loading />
                </LoadingWrapper>
            )}
        </Dialog>
    );
};

const CompetitionApp = () => {
    const [station] = useSelectedStation();
    const [state, { loadCompetitions, addCompetition }] = useCompetitionState(station);
    const [filter, setFilter] = useState({ period: null });
    const [status, setStatus] = useState(STATUS.READY);

    const { metrics } = state;

    useEffect(() => {
        if (station) {
            if (status === STATUS.READY) {
                setStatus(STATUS.PENDING);
            }
            API.fetchCompetitions(station, filter).then((resolve) => {
                loadCompetitions(resolve?.data?.data?.competitors, resolve?.data?.data?.metrics);
                setStatus(STATUS.READY);
            });
        }
    }, [station, filter]);

    const metricsObj = React.useMemo(() => {
        if (!metrics) {
            return null;
        }
        return metrics.reduce((acc, metric) => {
            acc[metric.key] = metric;
            return acc;
        }, {});
    }, [metrics]);

    const handleAddCompetition = () => {
        let tieBreakers = [];
        for (let i = 0; i < 3; i++) {
            tieBreakers.push(tiersOptionsSchema[i]);
        }

        setStatus(STATUS.NEW_ROW);
        openHandleCompetition({ teams: [] }).then((resolve) => {
            if (!resolve) return false;

            setStatus(STATUS.NEW_ROW);
            API.createCompetition({
                station: station,
                competitionName: resolve.competitor,
                teamsId: resolve.teams,
                startDate: resolve.startDate,
                endDate: resolve.endDate,
                tieBreakers: JSON.stringify(tieBreakers),
            }).then(
                (result) => {
                    const newCompetition = result.data?.data?.lastInserted;

                    newCompetition.tieBreakers = tieBreakers;

                    addCompetition(newCompetition);
                    setStatus(STATUS.READY);
                },
                () => {
                    showToast({
                        type: "error",
                        title: "There was an error to save the new competition",
                        timeout: 5000,
                    });
                }
            );
        });
    };

    return (
        <Spacer top={4} bottom={8}>
            <Spacer top={5} bottom={5} inline>
                <StationSelector inline />
                <Spacer left={4} inline>
                    <DatePicker
                        name={"period"}
                        label={"Period"}
                        onChange={(date) => {
                            setFilter({ ...filter, period: date || null });
                        }}
                        size={"medium"}
                    />
                </Spacer>
            </Spacer>
            <Table>
                <TableHeader
                    headers={[{ label: "Competiton Name", width: "164px" }, ...Object.values(METRIC_COLUMNS)]}
                />
                {state.status === STATUS.PENDING || status === STATUS.PENDING || !metricsObj ? (
                    <LoadingRow>
                        <TableData width={"164px"}>
                            <Skeleton height={20} />
                        </TableData>
                    </LoadingRow>
                ) : !state.competitions?.length ? (
                    <>
                        <TableRow style={{ justifyContent: "center" }}>
                            <Empty />
                        </TableRow>
                        {status === STATUS.NEW_ROW && (
                            <LoadingRow>
                                <TableData width={"164px"}>
                                    <Skeleton height={20} />
                                </TableData>
                            </LoadingRow>
                        )}
                    </>
                ) : (
                    <>
                        <CompetitionContent competitions={state.competitions} metrics={metricsObj} />
                        {status === STATUS.NEW_ROW && (
                            <LoadingRow>
                                <TableData width={"164px"}>
                                    <Skeleton height={20} />
                                </TableData>
                            </LoadingRow>
                        )}
                    </>
                )}
                <TableFooter sticky>
                    <ThirdTitle>
                        {/*    {state.goals?.length !== undefined*/}
                        {/*        ? `${state.goals.length} goals`*/}
                        {/*        : <Skeleton width={40} height={16}/>}*/}
                    </ThirdTitle>
                    <Button size={"small"} type={"primary"} onClick={handleAddCompetition}>
                        Add Competition
                    </Button>
                </TableFooter>
            </Table>
        </Spacer>
    );
};

const CompetitionContent = ({ competitions, metrics }) => {
    return competitions.map((c) => {
        return <CompetitionContentRow key={c.id} competition={c} metrics={metrics} />;
    });
};

const CompetitionNameForm = ({ name, onSave }) => {
    const { register, handleSubmit } = useForm({ defaultValues: { name } });

    if (!onSave) onSave = () => {};

    const onSubmit = (data) => {
        onSave({ name: data.name });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Input name={"name"} type={"text"} ref={register} size={"small"} />
            <Button type={"primary"}>Save</Button>
        </form>
    );
};

const CompetitionContentRow = ({ competition, metrics }) => {
    const [status, setStatus] = useState("READY");
    const [station] = useSelectedStation();

    const createdDate = moment.utc(competition.createdAt.timestamp * 1000).format("M/D/YYYY");
    const currentDateFormat = moment.utc().format("M/D/YYYY");

    const showButtonsIcon = createdDate >= currentDateFormat ? true : false;

    const handleManageTeam = () => {
        const currentTeams = competition.teams;
        openManageTeamDialog({ currentTeams: competition.teams, competitionName: competition.name }).then((result) => {
            if (!result) {
                return false;
            }

            const { newTeams, newTeamsId } = result;

            const isEqual = Object.entries(competition.teams).toString() === Object.entries(newTeams).toString();
            if (isEqual) {
                return false;
            }

            setStatus("LOADING_TEAMS");
            competition.teams = newTeams;

            API.editCompetitionTeams({ id: competition.id, teams: newTeamsId }).then(
                () => {
                    setStatus("READY");
                },
                () => {
                    competition.teams = currentTeams;
                    showToast({
                        type: "error",
                        title: "There was an error to save the teams changes",
                        timeout: 5000,
                    });
                    setStatus("READY");
                }
            );
        });
    };

    const handleManageWeight = () => {
        openManageWeightDialog({ competitionName: competition.name, goals: competition.goals, metrics: metrics }).then(
            (resolve) => {
                if (!resolve) return;
                setStatus("LOADING_ROW");

                let metricsSaved = Object.keys(resolve);
                let weights = [];
                metricsSaved.forEach((m, k) => {
                    weights[k] = resolve[m];
                });
                API.editCompetitionWeights({ competitionId: competition.id, goals: weights }).then(
                    (resolve) => {
                        const newCompetition = resolve.data?.data?.competitors;
                        competition.goals = newCompetition.goals;
                        setStatus("READY");
                    },
                    (error) => {
                        setStatus("READY");
                        showToast({
                            type: "error",
                            title: "There was an error to save the new goals weights",
                            timeout: 5000,
                        });
                    }
                );
            }
        );
    };

    const handleAddMetric = () => {
        const currentMetrics = () => {
            return competition.goals.reduce((acc, goal) => {
                if (goal.isArchive) return acc;
                acc.push(goal.metric.key);
                return acc;
            }, []);
        };

        openManageMetricDialog({ metrics, currentMetricsKey: currentMetrics() }).then((resolveM) => {
            if (!resolveM) return false;

            const goals = competition.goals;
            const newGoal = {
                id: 0,
                metric: { key: resolveM.metric.key },
                metricWeight: 0.25,
                goal: Array.isArray(resolveM.goal) ? resolveM.goal[0] : resolveM.goal,
                options: resolveM.metric.options,
            };
            goals.push(newGoal);

            const m = metrics[newGoal.metric.key];

            if (goals.length <= 1) {
                setStatus("LOADING_ROW");
                API.addCompetitionMetric({
                    id: competition.id,
                    goal: { goal: newGoal.goal, metricWeight: 1 },
                    metric: m,
                    weightsUpdateArray: [],
                    stationId: station,
                }).then(
                    (data) => {
                        const comp = data.data?.data?.competitors;
                        const lastGoal = data.data?.data?.lastInserted;
                        if (goals) competition.goals = comp.goals;
                        if (lastGoal && lastGoal.id !== comp.goals[0].id) competition.goals.push(lastGoal);
                        setStatus("READY");
                    },
                    () => {
                        showToast({
                            type: "error",
                            title: "There was an error to create the new metric to the competition",
                            timeout: 5000,
                        });
                        setStatus("READY");
                    }
                );
            } else {
                openManageWeightDialog({
                    competitionName: competition.name,
                    goals: competition.goals,
                    metrics: metrics,
                }).then((resolve) => {
                    if (!resolve) {
                        goals.pop();
                        return false;
                    }
                    let metricsSaved = Object.keys(resolve);
                    let weights = [];
                    let newWeight = 0.25;
                    metricsSaved.forEach((m, k) => {
                        if (resolve[m]?.goalId > 0) {
                            weights[k] = resolve[m];
                        } else {
                            newWeight = resolve[m].value;
                        }
                    });

                    setStatus("LOADING_ROW");
                    API.addCompetitionMetric({
                        id: competition.id,
                        goal: { goal: newGoal.goal, metricWeight: newWeight },
                        metric: m,
                        weightsUpdateArray: weights,
                        stationId: station,
                    }).then(
                        (data) => {
                            const comp = data.data?.data?.competitors;
                            const lastGoal = data.data?.data?.lastInserted;
                            if (goals) competition.goals = comp.goals;
                            if (lastGoal && lastGoal.id !== comp.goals[0].id) competition.goals.push(lastGoal);
                            setStatus("READY");
                        },
                        () => {
                            showToast({
                                type: "error",
                                title: "There was an error to create the new metric to the competition",
                                timeout: 5000,
                            });
                            setStatus("READY");
                        }
                    );
                });
            }
        });
    };

    const handleDeleteCompetition = () => {
        confirm({
            icon: true,
            text: `Please confirm deletion of "${competition.name}"? <br/> NOTE: All metrics will be archived and you don't lose any results.`,
        }).then((resolve) => {
            if (!resolve) return false;

            setStatus("LOADING_ROW");
            competition.isArchive = true;
            API.deleteCompetition({ id: competition.id }).then(
                () => {
                    setStatus("READY");
                },
                () => {
                    competition.isArchive = false;
                    setStatus("READY");
                    showToast({
                        type: "error",
                        title: 'There was an error to archive the competition "' + competition.name + '"',
                        timeout: 5000,
                    });
                }
            );
        });
    };

    const manageWeight = React.useCallback(
        (goalId) => {
            const goals = competition.goals.filter((g) => g.id !== goalId);
            return openManageWeightDialog({ competitionName: competition.name, goals: goals, metrics: metrics });
        },
        [metrics, competition]
    );

    const handleTieBreaker = () => {
        openManageTieBreaker({ competitionName: competition.name, tiersOptions: competition.tieBreakers || [] }).then(
            (resolve) => {
                if (!resolve) return;

                setStatus("LOADING_ROW");
                const oldTiers = competition.tieBreakers;

                API.editCompetitionTieBreakers({ competitionId: competition.id, tieBreakers: resolve }).then(
                    () => {
                        competition.tieBreakers = resolve;
                        setStatus("READY");
                    },
                    (error) => {
                        competition.tieBreakers = oldTiers;
                        setStatus("READY");
                        showToast({
                            type: "error",
                            title: "There was an error to save the tie breakers",
                            timeout: 5000,
                        });
                    }
                );
            }
        );
    };

    const actionsOptions = React.useMemo(() => {
        return [
            {
                text: "Manage Teams",
                fn: handleManageTeam,
            },
            {
                text: "Manage Weights",
                fn: handleManageWeight,
            },
            {
                text: "Add Metric",
                fn: handleAddMetric,
            },
            {
                text: "Manage Tie Breakers",
                fn: handleTieBreaker,
            },
            {
                text: "Delete Competition",
                fn: handleDeleteCompetition,
            },
        ];
    }, []);

    const totalGoals = React.useCallback(() => {
        return competition.goals.reduce((acc, g) => acc + (g.isArchive ? 0 : 1), 0);
    }, [competition]);

    if (competition.isArchive) {
        return false;
    }

    return (
        <TableRow
            style={{
                flexDirection: "column",
                borderBottom: `1px solid ${Theme.colors.info.shadow}`,
                position: "relative",
            }}
        >
            {status === "LOADING_ROW" && (
                <div
                    style={{ width: "100%", position: "absolute", background: "#FFFFFF61", zIndex: 10, height: "100%" }}
                >
                    <Loading style={{ width: 30, height: "100%", margin: "auto", display: "block" }} />
                </div>
            )}
            <TableData
                width={"100%"}
                style={{
                    gap: 8,
                    paddingBottom: 0,
                }}
            >
                <div style={{ display: "flex" }}>
                    <div>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            {status === "LOADING_NAME" ? (
                                <Loading width={20} />
                            ) : status === "EDITING_NAME" ? (
                                <CompetitionNameForm
                                    name={competition.name}
                                    onSave={({ name }) => {
                                        if (name === competition.name) {
                                            setStatus("READY");
                                            return false;
                                        }
                                        const oldName = competition.name;
                                        setStatus("LOADING_NAME");
                                        competition.name = name;
                                        API.editCompetition({ id: competition.id, competition }).then(
                                            () => {
                                                setStatus("READY");
                                            },
                                            () => {
                                                competition.name = oldName;
                                                showToast({
                                                    type: "error",
                                                    title: "There was an error to change the competition name",
                                                    timeout: 5000,
                                                });
                                            }
                                        );
                                    }}
                                />
                            ) : (
                                <>
                                    <ThirdTitle>{competition.name}</ThirdTitle>
                                    <ButtonIcon
                                        icon={Icon.Edit}
                                        color={Theme.colors.secondary.text}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setStatus("EDITING_NAME")}
                                    />
                                    <Text style={{ display: "flex", marginLeft: 4 }}>
                                        <Icon.Calendar size={"16px"} />
                                        {moment.utc(competition.startDate.timestamp * 1000).format("M/D/YYYY")} to{" "}
                                        {moment.utc(competition.endDate.timestamp * 1000).format("M/D/YYYY")}
                                    </Text>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </TableData>
            <TableData
                width={"100%"}
                style={{
                    gap: 8,
                }}
            >
                {status === "LOADING_TEAMS" ? (
                    <Loading width={20} />
                ) : competition.teams?.length ? (
                    competition.teams.map((t) => {
                        return <Tag key={t.name}>{t.name}</Tag>;
                    })
                ) : (
                    <Text>No Teams registered to this competition</Text>
                )}
            </TableData>
            <TableData
                width={"100%"}
                style={{
                    gap: 8,
                }}
            >
                {status === "LOADING_TEAMS" ? (
                    <Loading width={20} />
                ) : (
                    <>
                        <Text>Tie Breakers: </Text>
                        {competition.tieBreakers?.length ? (
                            competition.tieBreakers.map((t) => {
                                return <Tag key={t.label}>{t.label}</Tag>;
                            })
                        ) : (
                            <Text>No Tie Breakers registered to this competition</Text>
                        )}
                    </>
                )}
            </TableData>
            <Table>
                {totalGoals() >= 1 ? (
                    competition.goals.map((goal) => {
                        const metric = metrics[goal.metric.key];
                        return (
                            <GoalView
                                key={goal.id}
                                goal={goal}
                                metric={metric}
                                manageWeight={manageWeight}
                                totalGoals={totalGoals}
                                showButtonsIcon={showButtonsIcon}
                            />
                        );
                    })
                ) : (
                    <TableRow style={{ position: "relative" }}>
                        <TableData width={"100%"}>
                            <Text style={{ textAlign: "center", display: "block", margin: "auto" }}>
                                No goals created for this competition
                            </Text>
                        </TableData>
                    </TableRow>
                )}
                <div style={{ display: "flex", justifyContent: "space-between", padding: 10 }}>
                    <div style={{ paddingLeft: 168 }}>
                        <ThirdTitle></ThirdTitle>
                    </div>
                    {showButtonsIcon && (
                        <ButtonDropdown
                            type={"primary"}
                            text={"Actions"}
                            size={"small"}
                            visibleOptionsQty={10}
                            divider={false}
                            options={actionsOptions}
                            openTo={"top"}
                        />
                    )}
                </div>
            </Table>
        </TableRow>
    );
};

const GoalView = ({ goal, metric, manageWeight, totalGoals, showButtonsIcon }) => {
    const [status, setStatus] = useState("READY");

    if (goal.isArchive) {
        return false;
    }

    const handleUpdateGoal = (newGoal) => {
        const currentGoal = goal.goal;
        setStatus("LOADING_GOAL");
        goal.goal = newGoal;
        API.editCompetitionGoal({ id: goal.id, goal: goal }).then(
            () => {
                setStatus("READY");
            },
            () => {
                showToast({
                    type: "error",
                    title: "There was an error to save the new Goal",
                    timeout: 5000,
                });
                goal.goal = currentGoal;
                setStatus("READY");
            }
        );
    };

    const handleRemoveGoal = () => {
        confirm({
            icon: true,
            text: `Are you sure you want to remove the goal from the metric "${metric.name}"?`,
        }).then((resolve) => {
            if (resolve) return false;

            const currentGoal = goal.goal;

            setStatus("LOADING_GOAL");
            goal.goal = null;
            API.editCompetitionGoal({ id: goal.id, goal }).then(
                () => {
                    setStatus("READY");
                },
                () => {
                    showToast({
                        type: "error",
                        title: "There was an error to delete the Goal",
                        timeout: 5000,
                    });
                    goal.goal = currentGoal;
                    setStatus("READY");
                }
            );
        });
    };

    const handleDeleteMetric = () => {
        confirm({
            icon: true,
            text: `Are you sure you want to delete the metric "${metric.name}" from this competition?`,
        }).then((resolve) => {
            if (!resolve) return false;

            setStatus("LOADING_ROW");
            if (totalGoals() <= 1) {
                API.removeCompetitionGoal({ id: goal.id }).then(
                    () => {
                        goal.isArchive = true;
                        setStatus("READY");
                    },
                    () => {
                        goal.isArchive = false;
                        showToast({
                            type: "error",
                            title: "There was an error to delete the Goal",
                            timeout: 5000,
                        });
                        setStatus("READY");
                    }
                );
            } else {
                manageWeight(goal.id).then((w) => {
                    if (!w) setStatus("READY");

                    let metricsSaved = Object.keys(w);
                    let weights = [];
                    metricsSaved.forEach((m, k) => {
                        weights[k] = w[m];
                    });

                    API.removeCompetitionGoal({ id: goal.id, weightsUpdate: weights }).then(
                        () => {
                            goal.isArchive = true;
                            setStatus("READY");
                        },
                        () => {
                            goal.isArchive = false;
                            showToast({
                                type: "error",
                                title: "There was an error to delete the Goal",
                                timeout: 5000,
                            });
                            setStatus("READY");
                        }
                    );
                });
            }
        });
    };

    const handleNewOptions = (newOptions) => {
        const isEqual = Object.entries(goal.options).toString() === Object.entries(newOptions).toString();
        const currentOptions = goal.options;
        if (!isEqual) {
            setStatus("LOADING_OPTIONS");
            goal.options = newOptions;
            API.editCompetitionGoal({ id: goal.id, goal: goal }).then(
                () => {
                    setStatus("READY");
                },
                (error) => {
                    showToast({
                        type: "error",
                        title: "There was an error to save the new options",
                        timeout: 5000,
                    });
                    goal.options = currentOptions;
                    setStatus("READY");
                }
            );
        }
    };

    const handleCoaching = ({ coaching, driverDescription }) => {
        setStatus("LOADING_COACHING");
        goal.coaching = coaching;
        goal.driverDescription = driverDescription;
        API.editCompetitionGoal({ id: goal.id, goal: goal }).then(
            () => {
                setStatus("READY");
            },
            (error) => {
                showToast({
                    type: "error",
                    title: "There was an error to save the new options",
                    timeout: 5000,
                });
                setStatus("READY");
            }
        );
    };

    return (
        <TableRow key={goal.id} style={{ position: "relative" }}>
            {status === "LOADING_ROW" && (
                <div
                    style={{ width: "100%", position: "absolute", background: "#FFFFFF61", zIndex: 10, height: "100%" }}
                >
                    <Loading style={{ width: 30, height: "100%", margin: "auto", display: "block" }} />
                </div>
            )}
            <TableData width={"164px"} />
            <TableData
                width={METRIC_COLUMNS.metric.width}
                style={{
                    flexDirection: "column",
                    alignItems: "baseline",
                    gap: 4,
                    wordBreak: "break-word",
                }}
            >
                <div style={{ display: "flex" }}>
                    {showButtonsIcon && (
                        <ButtonIcon
                            title={"Remove Metric"}
                            icon={Icon.Times}
                            color={Theme.colors.error.text}
                            style={{ cursor: "pointer" }}
                            onClick={handleDeleteMetric}
                        />
                    )}

                    <div>
                        <strong>{metric.name}</strong>
                        <br />
                        <small>
                            [{metric.sourceAbbr}] {metric.timeframe}
                        </small>
                        <br />
                        <small>Weight: {goal.metricWeight}</small>
                    </div>
                </div>
            </TableData>
            <TableData width={METRIC_COLUMNS.goal.width} style={{ alignItems: "baseline" }}>
                {status === "LOADING_GOAL" ? (
                    <Loading width={20} />
                ) : (
                    <>
                        <TeamGoalController
                            goal={goal}
                            onSave={handleUpdateGoal}
                            onRemove={handleRemoveGoal}
                            showButtonsIcon={showButtonsIcon}
                        />
                    </>
                )}
            </TableData>
            <TableData
                width={METRIC_COLUMNS.automatedTips.width}
                style={{ position: "relative", alignItems: "baseline" }}
            >
                {status === "LOADING_OPTIONS" && (
                    <div
                        style={{
                            width: "100%",
                            position: "absolute",
                            background: "#ffffffa8",
                            zIndex: 10,
                            height: "100%",
                        }}
                    >
                        <Loading style={{ width: 30, height: "100%", margin: "auto", display: "block" }} />
                    </div>
                )}
                <AutomatedTipsController
                    metric={metric}
                    goalOptions={goal.options.list ?? goal.options}
                    onChange={handleNewOptions}
                />
            </TableData>
            <TableData width={"100%"} style={{ minWidth: 0, alignItems: "baseline" }}>
                {status === "LOADING_COACHING" ? (
                    <Loading width={20} />
                ) : (
                    <MetricCoachingController
                        metric={metric}
                        coaching={goal.coaching}
                        driverDescription={goal.driverDescription}
                        onSaveCoaching={handleCoaching}
                    />
                )}
            </TableData>
        </TableRow>
    );
};

const AutomatedTipsController = ({ metric, goalOptions, onChange }) => {
    const newKey = "__new";
    const [list, setList] = useState(() => {
        return goalOptions ?? metric?.options?.list ?? {};
    });
    const listRef = useRef();

    const { register, getValues, watch, setValue, handleSubmit, triggerValidation } = useForm();
    const fields = getFieldsForMetric(metric);

    const onSubmit = async (data) => {
        delete data[newKey];

        const newList = Object.keys(list).reduce((all, next) => {
            let key = watch(next).key ?? next;

            let label = key;
            switch (metric.metricType) {
                case "LOWEST_PERCENT":
                case "HIGHEST_PERCENT":
                    label = `${key}%`;
                    break;
            }

            all[key] = {
                ...list[next],
                ...data[next],
                color: watch(next)?.color?.[0] ?? list[next].color,
                label,
            };
            return all;
        }, {});

        onChange(newList);
    };

    const unsetKey = (key) => {
        let copy = { ...list };
        delete copy[key];
        setList(copy);
    };

    const setKey = async (evt) => {
        evt.preventDefault();

        const valid = await triggerValidation(`${newKey}.key`);

        if (!valid) return;

        let copy = { ...list };
        let values = getValues();
        const key = Array.isArray(values[`${newKey}.key`]) ? values[`${newKey}.key`][0] : values[`${newKey}.key`];

        if (key === undefined) return;

        const defaultColor = Theme.colors.info.shadow;

        copy[key] = {
            color: values[`${newKey}.color`][0] ?? defaultColor,
            desc: values[`${newKey}.desc`] ?? "",
        };

        setValue(key, copy[key]);
        setValue(newKey, {
            key: "",
            color: defaultColor,
            desc: "",
        });
        setList(copy);
    };

    useEffect(() => {
        listRef.current = list;
    }, []);

    return (
        <AutomatedTipsPreviewStyle>
            <AddOrEdit
                collection={[list]}
                modalProps={{
                    title: "Edit Tips",
                    closeOnOverlay: false,
                    width: "740px",
                }}
                collectionRender={([collection], { show }) => (
                    <>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "start",
                                gap: 8,
                            }}
                            onClick={show}
                        >
                            {Object.entries(collection).map(([key, value]) => (
                                <CustomStatus
                                    key={key}
                                    small={false}
                                    style={{
                                        borderColor: value.color,
                                        color: value.color,
                                        backgroundColor: `${value.color}22`,
                                    }}
                                >
                                    <strong>{value.label}</strong>
                                    <CustomSpacer inline left={3} right={2}>
                                        {value.desc}
                                    </CustomSpacer>
                                </CustomStatus>
                            ))}
                            <Tag
                                inline
                                style={{
                                    border: 0,
                                }}
                            >
                                <Icon.Edit
                                    size={"16px"}
                                    color={Theme.colors.info.border}
                                    style={{ verticalAlign: "text-bottom" }}
                                />
                                Edit Tips
                            </Tag>
                        </div>
                    </>
                )}
            >
                {({ hide }) => (
                    <>
                        <form
                            onSubmit={(evt) => {
                                evt.preventDefault();
                                handleSubmit(onSubmit)().then(hide);
                            }}
                        >
                            <Table style={{ width: "100%" }}>
                                <TableHeader
                                    headers={[
                                        { label: "Color", width: "64px" },
                                        { label: "Up To", width: "164px" },
                                        { label: "Message", width: "324px" },
                                        { label: "", width: "auto" },
                                    ]}
                                />
                                {Object.entries(list).map(([key, value]) => (
                                    <TableRow key={key}>
                                        <TableData width={"64px"} style={{ position: "relative", zIndex: 1 }}>
                                            <InputColor ref={register} name={`${key}.color`} value={value.color} />
                                        </TableData>
                                        <TableData width={"164px"}>
                                            {fields.map(({ field, props, validation }) =>
                                                React.createElement(field, {
                                                    ...props,
                                                    ref: register({
                                                        ...validation,
                                                    }),
                                                    name: `${key}.key`,
                                                    key: props.name,
                                                    label: false,
                                                    size: "small",
                                                    defaultValue: key,
                                                    disabled: metric && metric.key === "HoursWorked",
                                                })
                                            )}
                                        </TableData>
                                        <TableData width={"324px"}>
                                            <Input ref={register} name={`${key}.desc`} defaultValue={value.desc} />
                                        </TableData>
                                        {metric && metric.key !== "HoursWorked" && (
                                            <TableData width={"auto"} style={{ marginLeft: "auto" }}>
                                                <ButtonIcon
                                                    title={"Remove tip"}
                                                    icon={Icon.Times}
                                                    color={Theme.colors.error.text}
                                                    onClick={() => unsetKey(key)}
                                                />
                                            </TableData>
                                        )}
                                    </TableRow>
                                ))}
                                {metric && metric.key !== "HoursWorked" && (
                                    <TableFooter
                                        style={{
                                            justifyContent: "left",
                                            alignItems: "start",
                                            padding: 0,
                                        }}
                                    >
                                        <TableData width={"64px"} style={{ position: "relative", zIndex: 1 }}>
                                            <InputColor ref={register} name={`${newKey}.color`} />
                                        </TableData>
                                        <TableData width={"164px"}>
                                            {fields.map(({ field, props, validation }) =>
                                                React.createElement(field, {
                                                    ...props,
                                                    ref: register({
                                                        ...validation,
                                                    }),
                                                    name: `${newKey}.key`,
                                                    key: props.name,
                                                    label: false,
                                                    size: "small",
                                                    help: "Only one tip per value",
                                                    Required: false,
                                                })
                                            )}
                                        </TableData>
                                        <TableData width={"324px"}>
                                            <Input ref={register} name={`${newKey}.desc`} />
                                        </TableData>
                                        <TableData width={"auto"} style={{ marginLeft: "auto" }}>
                                            <Button type={"default"} onClick={setKey}>
                                                Add
                                            </Button>
                                        </TableData>
                                    </TableFooter>
                                )}
                            </Table>

                            <Spacer top={5} style={{ textAlign: "right" }}>
                                <Button type={"primary"}>Save</Button>
                            </Spacer>
                        </form>
                    </>
                )}
            </AddOrEdit>
        </AutomatedTipsPreviewStyle>
    );
};

const TeamGoalController = ({ goal, onSave = () => {}, onRemove = () => {}, showButtonsIcon = false }) => {
    const { register, getValues } = useForm({
        defaultValues: {
            goal: goal?.goal,
        },
    });
    const inputRef = useRef();

    const fields = getFieldsForMetric(goal.metric.key);

    const handleSave = (hide) => {
        const newGoal = getValues("goal");
        if (newGoal !== goal.goal) {
            onSave(newGoal);
        }
        hide();
    };

    const handleRemove = () => {
        onRemove(goal.id);
    };

    return (
        <AddOrEdit
            mode={"inline"}
            enabled={!(goal && goal.metric.key === "HoursWorked")}
            collection={goal?.goal ? [goal?.goal] : []}
            onOpen={() => {
                setTimeout(() => {
                    const input = inputRef.current.parentNode.querySelector("input");
                    input.style.minWidth = 0;
                    input.focus();
                }, 10);
            }}
            collectionRender={(collection, { show }) => (
                <Link
                    style={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                    }}
                >
                    <span>{collection.join(", ")}</span>
                    <Spacer left={1} />

                    {goal && goal.metric.key !== "HoursWorked" && showButtonsIcon && (
                        <>
                            <ButtonIcon title={"Edit"} icon={Icon.Edit} size="20px" onClick={show} />
                            <ButtonIcon
                                title={"Remove"}
                                icon={Icon.Times}
                                size="20px"
                                color={Theme.colors.error.border}
                                onClick={handleRemove}
                            />
                        </>
                    )}
                </Link>
            )}
        >
            {({ hide }) => (
                <>
                    {fields.map(({ field, props }) =>
                        React.createElement(field, {
                            ...props,
                            key: props.name,
                            ref: (elm) => {
                                inputRef.current = elm;
                                register(elm);
                            },
                            label: "",
                            size: "extraSmall",
                        })
                    )}
                    <Spacer left={1} inline>
                        <Action type="primary" onClick={() => handleSave(hide)}>
                            <Icon.Check color={"white"} size={"20px"} />
                        </Action>
                        <Action type="cancel" onClick={hide}>
                            <Icon.Times color={Theme.colors.info.border} size={"20px"} />
                        </Action>
                    </Spacer>
                </>
            )}
        </AddOrEdit>
    );
};

const MetricCoachingController = ({ metric, coaching, driverDescription, onSaveCoaching }) => {
    const [visible, setVisible] = useState(false);

    const { register, getValues } = useForm();

    const defaultCoaching = coaching || metric.coaching;

    const handleSave = () => {
        const coaching = getValues("coaching");
        const driverDescription = getValues("driverDescription");
        onSaveCoaching({ coaching, driverDescription });
        setVisible(false);
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 4,
                    width: "100%",
                    cursor: "pointer",
                }}
                onClick={() => setVisible(true)}
            >
                <CoachingPreviewStyle>
                    <Link>{driverDescription ?? metric.description}</Link>
                </CoachingPreviewStyle>
                <ButtonIcon
                    icon={Icon.Edit}
                    style={{
                        flexShrink: 0,
                    }}
                />
            </div>

            {visible ? (
                <Modal
                    visible={true}
                    setVisible={() => setVisible(false)}
                    drawer={"right"}
                    closeOnOverlay={false}
                    title={metric.name}
                >
                    <Input
                        ref={register}
                        name={"driverDescription"}
                        label={"Definition"}
                        size={"big"}
                        as={"textarea"}
                        style={{
                            height: 80,
                            fontFamily: "inherit",
                        }}
                        help={"Short definition for the driver"}
                        defaultValue={driverDescription ?? metric.description}
                    />
                    <Spacer bottom={3} />
                    <Label>Coaching Content</Label>
                    <SlideEditor ref={register} name={"coaching"} defaultValue={defaultCoaching} showModalSection={"scorecardCoaching"} />
                    <div style={{ textAlign: "right" }}>
                        <Button type="primary" onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                </Modal>
            ) : null}
        </>
    );
};

const openManageTeamDialog = ({ competitionName, currentTeams }) => {
    return dialogPromise((success, cancel) => (
        <ManageTeamDialog
            success={success}
            cancel={cancel}
            currentTeams={currentTeams}
            competitionName={competitionName}
        />
    ));
};

const ManageTeamDialog = ({ competitionName, currentTeams = [], teams = [], success, cancel }) => {
    const [teamsList, setTeamsList] = useState(teams);
    const [loading, setLoading] = useState(!!teams);
    const [station] = useSelectedStation();

    const handleSubmit = ({ data }) => {
        let newsTeams = data.teams.reduce((acc, teamId) => {
            acc.push(teamsList[teamId]);
            return acc;
        }, []);
        success({ newTeams: newsTeams, newTeamsId: data.teams });
    };

    const fields = useMemo(() => {
        let fields = [];
        fields.push({
            field: TagInput,
            props: {
                name: "teams",
                label: "Team",
                options: Object.keys(teamsList).map((id) => {
                    const e = teamsList[id];
                    return {
                        name: e?.user?.friendlyName ?? e.name,
                        value: e.id,
                        entity: e,
                        selected: currentTeams.find((c) => c.id === e.id),
                    };
                }),
            },
        });

        return fields;
    }, [teamsList, currentTeams]);

    useEffect(() => {
        if (teamsList.length < 1) {
            API.getAllTeams({ stationId: station }).then((result) => {
                const data = result.data?.data?.teams;
                if (data) {
                    const dataWithIndex = data.reduce((acc, t) => {
                        acc[t.id] = t;
                        return acc;
                    }, {});
                    setTeamsList(dataWithIndex);
                }
                setLoading(false);
            });
        }
    }, []);

    return (
        <Dialog
            success={handleSubmit}
            cancel={cancel}
            modal={{
                removeHeader: false,
                closeOnOverlay: false,
                title: "Add team to the competition " + competitionName ?? "",
            }}
            align={{
                header: "left",
                content: "left",
                footer: "right",
            }}
            fields={[...fields]}
            buttons={[
                {
                    type: "primary",
                    text: "Save",
                },
            ]}
        >
            {loading && (
                <LoadingWrapper>
                    <Loading />
                </LoadingWrapper>
            )}
        </Dialog>
    );
};

const openManageWeightDialog = ({ competitionName, goals, metrics }) => {
    return dialogPromise((success, cancel) => (
        <ManageWeightDialog
            success={success}
            cancel={cancel}
            goals={goals}
            metrics={metrics}
            competitionName={competitionName}
        />
    ));
};

const openManageTieBreaker = ({ competitionName, tiersOptions = [] }) => {
    return dialogPromise((success, cancel) => (
        <ManageTieBreaker
            success={success}
            cancel={cancel}
            tiersOptions={tiersOptions}
            competitionName={competitionName}
        />
    ));
};

const tiersOptionsSchema = [
    {
        label: "FICO",
        source: "ReportScorecard",
        fieldGet: "getFico",
        fieldName: "fico",
    },
    {
        label: "DCR",
        source: "ReportScorecard",
        fieldGet: "getDeliveryCompletionRate",
        fieldName: "deliveryCompletionRate",
    },
    {
        label: "DAR",
        source: "ReportScorecard",
        fieldGet: "getDeliveryAndReceived",
        fieldName: "deliveryAndReceived",
    },
    {
        label: "Green Zone",
        source: "ReportNetradyne",
        fieldGet: "getDriverScore",
        fieldName: "driverScore",
    },
];

const CustomTagContainer = styled.form`
    > div {
        width: 100%;
        > div {
            width: 100%;
        }
    }
`;

const ManageTieBreaker = WithDragAndDrop(({ competitionName, tiersOptions = [], success, cancel }) => {
    const [breakers, setBreakers] = useState(tiersOptions);
    const [options, setOptions] = useState(() => {
        if (tiersOptions.length > 0) {
            const tiersId = tiersOptions.map((opt) => opt.source + opt.label);
            return tiersOptionsSchema.filter((opt) => !tiersId.includes(opt.source + opt.label));
        }
        return tiersOptionsSchema;
    });

    const moveMember = React.useCallback(
        (dragIndex, hoverIndex) => {
            if (!breakers) return;
            const selectedBreakersCopy = [...breakers];
            const DraggedItem = selectedBreakersCopy[dragIndex];

            selectedBreakersCopy.splice(dragIndex, 1);
            selectedBreakersCopy.splice(hoverIndex, 0, DraggedItem);

            setBreakers(selectedBreakersCopy);
        },
        [breakers]
    );

    return (
        <Modal
            width={"340px"}
            removeHeader={false}
            closeOnOverlay={false}
            title={`Managing competition "${competitionName}" tie breakers`}
            visible={true}
            setVisible={cancel}
        >
            <Text style={{ color: "#707070", fontSize: 16, fontFamily: "Circe-Rounded" }}>Current Tie Breakers</Text>
            <Spacer top={2} />
            <Card className={"memberSelect"} style={{ height: "auto", boxShadow: "1px 2px 6px #33333326" }}>
                <div style={{ display: "grid", rowGap: 12 }}>
                    {breakers.map((t, k) => {
                        const key = t.source + "_" + t.fieldName;
                        return (
                            <DragItem id={key} key={key} type={"tier"} index={k} onMove={moveMember}>
                                {({ previewRef, dragRef }) => (
                                    <div ref={previewRef}>
                                        <CardDrag>
                                            <Spacer inline right={4}>
                                                <div ref={dragRef}>
                                                    <Icon.Move className={"dragger"} size={"16px"} />
                                                </div>
                                            </Spacer>
                                            <div>
                                                <Text>{t.label}</Text>
                                                <div
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setOptions([...options, t]);
                                                        setBreakers((prevState) => {
                                                            prevState.splice(k, 1);
                                                            return [...prevState];
                                                        });
                                                    }}
                                                    style={{
                                                        position: "absolute",
                                                        right: -8,
                                                        top: -8,
                                                        background: Theme.colors.error.bg,
                                                        cursor: "pointer",
                                                        borderRadius: "100%",
                                                    }}
                                                >
                                                    <Icon.Times
                                                        size={"16px"}
                                                        color={Theme.colors.error.text}
                                                        style={{ position: "relative", top: 1 }}
                                                    />
                                                </div>
                                            </div>
                                        </CardDrag>
                                    </div>
                                )}
                            </DragItem>
                        );
                    })}
                </div>
            </Card>
            <Spacer top={4} />
            <CustomTagContainer>
                <TagInput
                    label={"Available Tie Breakers"}
                    name={"newBreakerSelect"}
                    options={options.map((opt, key) => ({ name: opt.label, value: key }))}
                    onChange={(opt) => {
                        if (breakers.length >= 3) {
                            showToast({
                                type: "warning",
                                title: "Only 3 tie breakers are allowed",
                                timeout: 3000,
                            });
                            return;
                        }

                        opt = opt[0];
                        const breaker = options[opt.value];
                        setBreakers([...breakers, breaker]);
                        setOptions((prevState) => {
                            prevState.splice(opt.value, 1);
                            return [...prevState];
                        });
                    }}
                    singleOption={true}
                />
            </CustomTagContainer>
            <Spacer top={4} />
            <Button
                type={"primary"}
                Type={"submit"}
                style={{ display: "block", marginLeft: "auto" }}
                onClick={() => {
                    if (breakers.length !== 3) {
                        showToast({
                            type: "warning",
                            title: "You need to select 3 breakers",
                            timeout: 3000,
                        });
                        return;
                    }

                    success(breakers);
                }}
            >
                Save
            </Button>
        </Modal>
    );
});

const MAX_WEIGHT = 1;
const ManageWeightDialog = ({ goals, defaultWeight, competitionName, metrics, success, cancel }) => {
    const { register, handleSubmit, getValues, errors, reset } = useForm({ defaultValues: {}, mode: "onChange" });

    goals = goals.filter((g) => !g.isArchive);

    const formRef = useRef();

    const [individualWeight, setIndividualWeight] = useState(defaultWeight);
    const [totalWeight, setTotalWeight] = useState(() => {
        let first = 0;
        let last = goals.length - 1;
        let total = 0;
        while (last >= first) {
            total += goals[first].metricWeight;

            if (first !== last) {
                total += goals[last].metricWeight;
            }

            first++;
            last--;
        }
        return total;
    });
    const missingWeight = parseFloat(MAX_WEIGHT - totalWeight).toFixed(3);

    const onSubmit = (data) => {
        if (!data) return false;

        if (missingWeight > 0 || missingWeight < 0) {
            return false;
        }
        success(data);
    };

    const handleInputBlur = (event, oldValue) => {
        const newValue = event?.target?.value ? parseFloat(event.target.value) : 0;
        oldValue = event.target.getAttribute("oldValue") ?? oldValue;
        event.target.setAttribute("oldValue", newValue ?? 0);
        setTotalWeight((prevState) => parseFloat(prevState - oldValue + newValue, 2));
    };

    const fields = useMemo(() => {
        let fields = [];
        for (let i = 0; i < goals.length; i++) {
            const value = individualWeight ?? goals[i].metricWeight;
            fields.push({
                field: Input,
                name: goals[i].metric.key,
                props: {
                    type: "number",
                    name: `${goals[i].metric.key}['value']`,
                    label: metrics[goals[i].metric.key].name,
                    defaultValue: value,
                    required: true,
                    oldValue: value,
                    step: ".001",
                    onChange: (e) => {
                        handleInputBlur(e, value);
                    },
                },
            });

            fields.push({
                field: "input",
                props: {
                    type: "hidden",
                    name: `${goals[i].metric.key}['goalId']`,
                    defaultValue: goals[i].id,
                },
            });
        }

        return fields;
    }, [goals, individualWeight]);

    const values = getValues();

    return (
        <Modal
            width={"340px"}
            removeHeader={false}
            closeOnOverlay={true}
            title={`Managing competition ${competitionName} metric weights`}
            visible={true}
            setVisible={cancel}
        >
            {goals.length > 0 && (
                <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                    {fields && fields.length
                        ? fields.map((item, k) => {
                              return item.props && item.props.Displayed == false ? (
                                  <></>
                              ) : (
                                  <Spacer key={item.props.name} top={k > 0 ? 3 : 0} style={{ ...item.props.spanStyle }}>
                                      {React.createElement(item.field, {
                                          ...item.props,
                                          ref: register({
                                              required: item.props.Required,
                                          }),
                                          valid: values[item.props.name],
                                          error: errors[item.props.name] && "Invalid",
                                      })}
                                  </Spacer>
                              );
                          })
                        : null}
                    <Spacer top={4}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Text style={{ fontWeight: "bold" }}>Total Weight: {totalWeight.toFixed(2)}</Text>
                            <Text style={{ color: missingWeight !== 0 ? "red" : "green" }}>
                                {missingWeight < 0 && `Over ${missingWeight * -1}`}
                                {missingWeight > 0 && `Missing ${missingWeight}`}
                            </Text>
                        </div>
                    </Spacer>
                    <Spacer top={5} inline={true} style={{ display: "flex", justifyContent: "end" }}>
                        <Button
                            onClick={() => {
                                {
                                    const newWeight = parseFloat(MAX_WEIGHT / goals.length).toFixed(3);
                                    const resetArray = fields.reduce((acc, f) => {
                                        if (!f.name) return acc;

                                        acc[f.name] = { value: newWeight };
                                        return acc;
                                    }, {});

                                    const inputs = formRef.current.querySelectorAll(`input[type="number"]`);
                                    for (let i = 0; i < inputs.length; i++) {
                                        inputs[i].setAttribute("oldValue", newWeight);
                                    }

                                    reset(resetArray);
                                    setIndividualWeight(newWeight);
                                    setTotalWeight(newWeight * goals.length);
                                }
                            }}
                            Type={"button"}
                        >
                            Equalize Weights
                        </Button>
                        <Spacer left={3} inline={true}>
                            <Button type={"primary"}>Save</Button>
                        </Spacer>
                    </Spacer>
                </form>
            )}
            {goals.length < 1 && <Text>You need to add some metrics before manage weights</Text>}
        </Modal>
    );
};

const openManageMetricDialog = ({ metrics, currentMetricsKey = [] }) => {
    return dialogPromise((success, cancel) => (
        <ManageMetricCompetition
            success={success}
            cancel={cancel}
            metrics={metrics}
            currentMetrics={currentMetricsKey}
        />
    ));
};

const ManageMetricCompetition = ({ metrics, goal, currentMetrics = [], success, cancel }) => {
    const [metric, setMetric] = useState(goal?.metric);

    const handleSubmit = ({ data }) => {
        data.metric = metrics[data.metricKey];
        success(data);
    };

    const fields = useMemo(() => {
        let fields = [];
        // todo create fields resolver

        fields.push({
            field: TagInput,
            props: {
                name: "metricKey",
                label: "Metric",
                singleOption: true,
                options: Object.keys(metrics)
                    .filter((k) => {
                        const metric = metrics[k];
                        return !currentMetrics.includes(metric.key);
                    })
                    .map((mk) => {
                        const m = metrics[mk];
                        return {
                            name: m.sourceAbbr + " - " + m.name,
                            value: m.key,
                            metric: m,
                        };
                    }),
                Required: true,
                onChange: ([value]) => setMetric(value?.metric),
            },
        });

        fields = fields.concat(getFieldsForMetric(metric));

        return fields;
    }, [metric, metrics]);

    return (
        <Dialog
            success={handleSubmit}
            cancel={cancel}
            modal={{
                removeHeader: false,
                closeOnOverlay: false,
                title: "Add Metric To Competition",
            }}
            align={{
                header: "left",
                content: "left",
                footer: "right",
            }}
            fields={[...fields]}
            buttons={[
                {
                    type: "primary",
                    text: "Save",
                },
            ]}
        ></Dialog>
    );
};

export { CompetitionApp };
