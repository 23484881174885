import {ContainerItemsType} from "./store";
import uniqueInteger from 'unique-integer';

export const getID = (): number => {
    let id = uniqueInteger();
    if (id < 0) {
        return -1 * id;
    }
    return id;
}

export const PASS_QUESTION = 1;
export const VEHICLE_IMAGE = 2;
export const MULTIPLE_CHOICES = 3;
export const ENTRY_TEXT = 4;
export const SCANNER = 5;
export const MILEAGE = 6;
export const FUEL_LEVEL = 7;
export const INSTRUCTION = 8;
export const PACKAGES_MISSING = 9;
export const PACKAGES_RETURNING = 10;
export const CUSTOM_METER_ENTRY = 11;
export const AI_ASSISTED_IMAGE_INSPECTION = 12;

export const packagesMissingSample = () => {
    return {
        id: getID(),
        type: PACKAGES_MISSING,
        content: {
            name: 'Enter the missing packages',
            description: '',
            isPassRequired: true,
            isRangeRequired: false,
            startRange: 0,
            endRange: 60,
            isPhotoRequired: false,
            isCommentRequired: false,
            notifyIfChosen: false,
            notifyOutOfRange: false,
            disabledQuestion: false,
        }
    }
}

export const packagesReturningSample = () => {
    return {
        id: getID(),
        type: PACKAGES_RETURNING,
        content: {
            name: 'Enter the returning packages',
            description: '',
            isPassRequired: true,
            isRangeRequired: false,
            startRange: 0,
            endRange: 60,
            isPhotoRequired: false,
            isCommentRequired: false,
            notifyIfChosen: false,
            notifyOutOfRange: false,
            disabledQuestion: false,
        }
    }
}

export const ynQuestionSample = () => {
    return {
        id: getID(),
        type: PASS_QUESTION,
        content: {
            name: '?',
            description: '',
            passLabel: 'Yes',
            failLabel: 'No',
            additionalFailQuestion: ['log'],
            isPassRequired: true,
            isPhotoRequired: false,
            isCommentRequired: false,
            extraLabel: '',
            enableExtraLabel: false,
            notifyIfChosen: true,
            notifyIfExtraChosen: false,
            messageIfNotChosen: '',
            messageIfExtraChosen: '',
            disabledQuestion: false,
        }
    }
}

export const scannerSample = () => {
    return {
        id: getID(),
        type: SCANNER,
        content: {
            name: 'Scanner',
            description: 'Description',
            type: 'QR_CODE',
            length: 17,
            isPassRequired: true,
            notifyIfSuccess: false,
            notifyIfFailure: false,
            messageIfFailure: '',
            disabledQuestion: false,
        }
    }
}

export const vehicleImageSample = () => {
    return {
        id: getID(),
        type: VEHICLE_IMAGE,
        content: {
            name: 'Take a photo of vehicle',
            description: '',
            isPassRequired: true,
            notifyIfChosen: false,
            disabledQuestion: false,
        }
    }
}

export const aiAssistedImageInspectionSample = () => {
    return {
        id: getID(),
        type: AI_ASSISTED_IMAGE_INSPECTION,
        content: {
            name: 'AI Vehicle Photos',
            description: 'Please take the following photos of your vehicle using the sample photo as your guideline',
            isPassRequired: true,
            notifyIfChosen: false,
            disabledQuestion: false,
            submitToAi: true,
            aiCategoryNotification: [
                {
                    label: "send_alert_on_inspection_failure",
                    description: "Send alert on inspection failure",
                    notification: true
                },
                {
                    label: "New damage detected",
                    description: "do not alert on 'New damage detected' images",
                    notification: false
                },
                {
                    label: "Potential new damage detected",
                    description: "do not alert on 'Potential new damage detected' images",
                    notification: false
                },          
                {
                    label: "Incorrect photo",
                    description: "do not alert on 'Incorrect photo' images",
                    notification: false
                },
                {
                    label: "Bad angle",
                    description: "do not alert on 'Bad angle' images",
                    notification: false
                }
            ]
        }
    }
}

export const mileageSample = () => {
    return {
        id: getID(),
        type: MILEAGE,
        content: {
            name: 'Enter the mileage for your vehicle',
            description: '',
            isPassRequired: true,
            isRangeRequired: true,
            startRange: 0,
            endRange: 300000,
            isPhotoRequired: false,
            isCommentRequired: false,
            notifyIfChosen: false,
            notifyOutOfRange: false,
            disabledQuestion: false,
        }
    }
}

export const customMileageSample = () => {
    return {
        id: getID(),
        type: CUSTOM_METER_ENTRY,
        content: {
            name: 'Enter custom mileage verification for your vehicle',
            description: '',
            loadOutMilesCheck: 5,
            rtsMilesCheck: 5,
            isPhotoRequired: false,
            notifyIfChosen: false,
            disabledQuestion: false,
        }
    }
}

export const entryTextSample = () => {
    return {
        id: getID(),
        type: ENTRY_TEXT,
        content: {
            name: 'Please type',
            description: '',
            isPassRequired: false,
            isSignature: false,
            notifyIfChosen: false,
            isPhotoRequired: false,
            disabledQuestion: false,
        }
    }
}

export const entryInstructionSample = () => {
    return {
        id: getID(),
        type: INSTRUCTION,
        content: {
            name: 'Text',
            description: 'Description',
            disabledQuestion: false,
        }
    }
}

export const multipleChoicesSample = () => {
    return {
        id: getID(),
        type: MULTIPLE_CHOICES,
        content: {
            name: 'List',
            description: 'The following list contains: ',
            notify: false,
            choices: [
                {name: 'Item #1', isPassRequired: false, notifyIfChosen: false, notifyIfNotChosen: false},
                {name: 'Item #2', isPassRequired: false, notifyIfChosen: false, notifyIfNotChosen: false},
                {name: 'Item #3', isPassRequired: false, notifyIfChosen: false, notifyIfNotChosen: false},
            ],
            disabledQuestion: false,
        }
    }
}

export const fuelLevelSample = () => {
    return {
        id: getID(),
        type: FUEL_LEVEL,
        content: {
            name: 'Fuel Level',
            description: 'The following level is divided by 12.5% increments.',
            minFuelLevel: 0,
            choices: [
                {name: '0', notifyIfChosen: true},
                {name: '12.5%', notifyIfChosen: true},
                {name: '25%', notifyIfChosen: true},
                {name: '37.5%', notifyIfChosen: true},
                {name: '50%', notifyIfChosen: false},
                {name: '62.5%', notifyIfChosen: false},
                {name: '75%', notifyIfChosen: false},
                {name: '87.5%', notifyIfChosen: false},
                {name: '100%', notifyIfChosen: false},
            ],
            disabledQuestion: false,
        }
    }
}

export const LoadOutTemplate: Array<ContainerItemsType> = [{
    "id": 11,
    "name": "Getting started",
    "items": [{
        "id": 899490661,
        "type": 1,
        "content": {
            "name": "Have you successfully punched into your payroll app?",
            "failLabel": "No",
            "passLabel": "Yes",
            "description": "If you are unable to clock in, please see your manager.",
            "isPassRequired": true,
            "notifyIfChosen": false,
            "enableExtraLabel": false,
            "notifyIfNotChosen": false,
            "messageIfNotChosen": "",
            "requireReasonIfFailChosen": true,
            "requireReasonIfExtraChosen": true,
            "disabledQuestion": false
        }
    }, {
        "id": 2035582910,
        "type": 1,
        "content": {
            "name": "Do you have an assigned van?",
            "failLabel": "No",
            "passLabel": "Yes",
            "description": "",
            "isPassRequired": true,
            "notifyIfChosen": false,
            "enableExtraLabel": false,
            "notifyIfNotChosen": false,
            "messageIfNotChosen": "",
            "requireReasonIfFailChosen": true,
            "requireReasonIfExtraChosen": true,
            "disabledQuestion": false
        }
    }, {
        "id": 946271599,
        "type": 5,
        "content": {
            "name": "Scan VIN",
            "type": "QR_CODE",
            "length": 17,
            "description": "Please scan your vehicle's QR or bar code.",
            "isPassRequired": true,
            "notifyIfFailure": false,
            "notifyIfSuccess": false,
            "messageIfFailure": "",
            "disabledQuestion": false
        }
    }]
}, {
    "id": 241760753,
    "name": "Check your van",
    "items": [{
        "id": 2059047444,
        "type": 7,
        "content": {
            "name": "Fuel Level",
            "choices": [{"name": "0", "notifyIfChosen": true}, {
                "name": "12.5%",
                "notifyIfChosen": true
            }, {"name": "25%", "notifyIfChosen": true}, {"name": "37.5%", "notifyIfChosen": true}, {
                "name": "50%",
                "notifyIfChosen": true
            }, {"name": "62.5%", "notifyIfChosen": true}, {"name": "75%", "notifyIfChosen": true}, {
                "name": "87.5%",
                "notifyIfChosen": false
            }, {"name": "100%", "notifyIfChosen": false}],
            "description": "Please use the slider to indicate the van's current fuel level.",
            "minFuelLevel": 0,
            "disabledQuestion": false
        }
    }, {
        "id": 744355145,
        "type": 6,
        "content": {
            "name": "Enter the mileage for your vehicle.",
            "description": "",
            "isPassRequired": true,
            "notifyIfChosen": false,
            "isRangeRequired": false,
            "notifyOutOfRange": false,
            "disabledQuestion": false
        }
    }, {
        "id": 739631791,
        "type": 3,
        "content": {
            "name": "Van supplies checklist",
            "notify": false,
            "choices": [{
                "name": "Battery pack",
                "isPassRequired": false,
                "notifyIfChosen": false,
                "notifyIfNotChosen": true
            }, {
                "name": "Charging cord",
                "isPassRequired": false,
                "notifyIfChosen": false,
                "notifyIfNotChosen": true
            }, {
                "name": "Gas card",
                "isPassRequired": false,
                "notifyIfChosen": false,
                "notifyIfNotChosen": true
            }, {
                "name": "Phone mount",
                "isPassRequired": false,
                "notifyIfChosen": false,
                "notifyIfNotChosen": true
            }, {
                "name": "Spare tire",
                "isPassRequired": false,
                "notifyIfChosen": false,
                "notifyIfNotChosen": true
            }, {
                "name": "Mobile device fully charged",
                "isPassRequired": false,
                "notifyIfChosen": false,
                "notifyIfNotChosen": true
            }],
            "description": "Please confirm your van has the following list of supplies:",
            "disabledQuestion": false
        }
    }]
}, {
    "id": 1062803004,
    "name": "Station Procedure",
    "items": [{
        "id": 1508372256,
        "type": 1,
        "content": {
            "name": "Have you successfully completed the Flex pre-trip DVIC process?",
            "failLabel": "No",
            "passLabel": "Yes",
            "description": "",
            "isPassRequired": true,
            "notifyIfChosen": false,
            "enableExtraLabel": false,
            "notifyIfNotChosen": false,
            "messageIfNotChosen": "",
            "requireReasonIfFailChosen": true,
            "requireReasonIfExtraChosen": true,
            "disabledQuestion": false
        }
    }, {
        "id": 335990216,
        "type": 8,
        "content": {
            "name": "Station safety reminders",
            "description": "When entering the station, please remember to leave your:\n   1. Windows down\n   2. Headlights on\n   3. Flashers on\n   4. Radio off\n  \nWhen you park, be sure to turn OFF the van and leave keys IN the ignition",
            "disabledQuestion": false
        }
    }, {
        "id": 263697181,
        "type": 8,
        "content": {
            "name": "In the station",
            "description": "1. Once you are in the station and after you have located your packages, please log into Flex.\n\n2. In the Flex app, please click the \"Start travel\" button at the bottom of the screen before you leave the station.\n\n3. Please, remember to buckle up!",
            "disabledQuestion": false
        }
    }]
}]
export const RTSTemplate: Array<ContainerItemsType> = [
    {
        "id": 517977280,
        "name": "RTS - At the Station",
        "items": [
            {
                "id": 262144017,
                "type": 3,
                "content": {
                    "name": "Station procedures",
                    "notify": false,
                    "choices": [{
                        "name": "Windows down",
                        "isPassRequired": false,
                        "notifyIfNotChosen": false,
                        "notifyIfChosen": false,
                    }, {
                        "name": "Headlights on",
                        "isPassRequired": false,
                        "notifyIfNotChosen": false,
                        "notifyIfChosen": false,
                    }, {
                        "name": "Flashers on",
                        "isPassRequired": false,
                        "notifyIfNotChosen": false,
                        "notifyIfChosen": false,
                    }, {
                        "name": "Turn off van when parked",
                        "isPassRequired": false,
                        "notifyIfNotChosen": false,
                        "notifyIfChosen": false,
                    }, {
                        "name": "Leave the key in ignition",
                        "isPassRequired": false,
                        "notifyIfNotChosen": false,
                        "notifyIfChosen": false,
                    }, {
                        "name": "Return empty totes to the station designated location",
                        "isPassRequired": false,
                        "notifyIfNotChosen": false,
                        "notifyIfChosen": false,
                    }, {
                        "name": "Check in with Amazon RTS personnel to report routes completion",
                        "isPassRequired": false,
                        "notifyIfNotChosen": false,
                        "notifyIfChosen": false,
                    }, {
                        "name": "Return any remaining and/or report any missing packages",
                        "isPassRequired": false,
                        "notifyIfNotChosen": false,
                        "notifyIfChosen": false,
                    }],
                    "description": "When entering the station, please remember:",
                    "disabledQuestion": false
                }
            }, {
                "id": 149195914,
                "type": 10,
                "content": {
                    "name": "How many packages are you returning?",
                    "description": "",
                    "isPassRequired": true,
                    "notifyIfChosen": false,
                    "disabledQuestion": false
                }
            }, {
                "id": 1580001361,
                "type": 9,
                "content": {
                    "name": "How many packages are missing?",
                    "description": "",
                    "isPassRequired": true,
                    "notifyIfChosen": false,
                    "disabledQuestion": false
                }
            }, {
                "id": 9729227,
                "type": 8,
                "content": {
                    "name": "Change your Flex status",
                    "description": "Please, select \"Go Off Duty\" and \"Log Out\" of the Flex app.",
                    "disabledQuestion": false
                }
            }]
    }, {
        "id": 2007155474,
        "name": "In the Parking Lot",
        "items": [{
            "id": 690736227,
            "type": 1,
            "content": {
                "name": "Have you returned to the parking lot?",
                "failLabel": "No",
                "passLabel": "Yes",
                "description": "",
                "isPassRequired": true,
                "notifyIfChosen": false,
                "enableExtraLabel": false,
                "notifyIfNotChosen": false,
                "messageIfNotChosen": "",
                "requireReasonIfFailChosen": true,
                "requireReasonIfExtraChosen": true,
                "disabledQuestion": false
            }
        }, {
            "id": 1298858114,
            "type": 6,
            "content": {
                "name": "Please enter the current mileage for your vehicle",
                "description": "",
                "isPassRequired": true,
                "notifyIfChosen": false,
                "isRangeRequired": false,
                "notifyOutOfRange": false,
                "disabledQuestion": false
            }
        }, {
            "id": 350973985,
            "type": 7,
            "content": {
                "name": "Fuel Level",
                "choices": [
                    {
                        "name": "0", "notifyIfChosen": true
                    },
                    {
                        "name": "12.5%",
                        "notifyIfChosen": true
                    },
                    {
                        "name": "25%", "notifyIfChosen": true
                    },
                    {
                        "name": "37.5%", "notifyIfChosen": true
                    }, {
                        "name": "50%",
                        "notifyIfChosen": true
                    },
                    {
                        "name": "62.5%", "notifyIfChosen": true
                    },
                    {
                        "name": "75%", "notifyIfChosen": true
                    }, {
                        "name": "87.5%",
                        "notifyIfChosen": false
                    },
                    {
                        "name": "100%", "notifyIfChosen": false
                    }],
                "description": "Please use the slider to indicate the van's current fuel level.",
                "minFuelLevel": 0,
                "disabledQuestion": false
            }
        }, {
            "id": 721600184,
            "type": 1,
            "content": {
                "name": "Have you successfully completed the Flex post-trip DVIC process?",
                "failLabel": "No",
                "passLabel": "Yes",
                "description": "",
                "isPassRequired": true,
                "notifyIfChosen": false,
                "enableExtraLabel": false,
                "notifyIfNotChosen": false,
                "messageIfNotChosen": "",
                "requireReasonIfFailChosen": true,
                "requireReasonIfExtraChosen": true,
                "disabledQuestion": false
            }
        }, {
            "id": 1958336892,
            "type": 8,
            "content": {
                "name": "Return Items",
                "description": "Please return keys, route bag, and corporate device to the station.",
                "disabledQuestion": false
            }
        }, {
            "id": 1860800248,
            "type": 1,
            "content": {
                "name": "Have you successfully punched out of your payroll app?",
                "failLabel": "No",
                "passLabel": "Yes",
                "description": "",
                "isPassRequired": true,
                "notifyIfChosen": true,
                "enableExtraLabel": false,
                "notifyIfNotChosen": false,
                "messageIfNotChosen": "",
                "requireReasonIfFailChosen": true,
                "requireReasonIfExtraChosen": true,
                "disabledQuestion": false
            }
        }]
    }];

export const LoadOutIntroduction = " To move through the Load Out process please complete each of the " +
    "tasks below. You cannot skip any tasks. Your Load Out process has " +
    "the following tasks:";

export const RTSIntroduction = "Tap each step to complete. You cannot skip any step. Your return to " +
    "station process have the following tasks:";