import React, {useEffect, useState, useContext} from "react";
import {Button, CheckBox, CustomDropdown, Icon, Input, Spacer, Text, Theme, ThirdTitle, Title} from "@dspworkplace/ui";
import {DropUploader} from "../../../components/DropUploader";
import StationSelector, {useSelectedStation} from "../../../components/StationSelector";
import {Table, TableButton, TableData, TableFooter, TableHeader, TableRow} from "../../../components/Table";
import {dialogPromise} from "../../../components/Dialog";
import {Link, Status} from "../../../components/UI";
import {getCompanyId, upload, useAuth} from "../../../Auth";
import toast from "../../../components/Toasts/toast";
import {fullDateFromUTC} from "../../../components/TimeFormat";
import {default as Skeleton} from "../../../components/Skeleton";
import Empty from '../../../components/Empty';
import Tooltip from "../../../components/Tooltip";
import Modal from "../../../components/Modal";
import Loading from "../../../components/Loading";
import {useIntersection} from "../../../hooks/useIntersect";
import {useForm} from "react-hook-form";
import moment from "moment";
import {
    DOCUMENT_ERROR_KEY,
    DOCUMENT_STATUS,
    DOCUMENT_STATUS_CONFIG,
    DOCUMENT_STATUS_MAP,
    ReportSourceDocumentSchemaInclude
} from "../schema";
import DatePicker from "../../../components/Calendar/picker";
import ScorecardContext, {ScorecardProvider} from "../context";
import styled from 'styled-components';
import ES from "../../../EventSource";
import { mimeTypes, patternGetNameExtension } from "../../../patterns";

const Question = styled.div`
    display: inline-block;
    font-family: ${Theme.font.main};
    background: ${Theme.colors.info.bg};
    border: 1px solid ${Theme.colors.info.border};
    color: ${Theme.colors.info.text};
    font-weight: bold;
    font-size: ${Theme.font.extraSmall.size};
    line-height: ${Theme.font.small.lineHeight};
    width: 16px;
    height: 16px;
    border-radius: 20px;
    text-align: center;
    cursor: help;
`;

export default function (props)
{
    return <ScorecardProvider>
        <ScorecardDocuments {...props}/>
    </ScorecardProvider>
}

const categoryOptions = [
    {
        name: "Scorecard Weekly PDF",
        value: "Scorecard"
    },
    {
        name: "Mentor Daily CSV",
        value: "Mentor"
    },
    {
        name: "Netradyne Daily CSV",
        value: "Netradyne"
    },
    {
        name: "Engine Off Compliance XLSX",
        value: "Engine Off Compliance"
    },
    {
        name: "Customer Delivery Feedback (CDF) PDF",
        value: "Customer Delivery Feedback"
    },
    {
        name: "Proper Parking Sequence (PPS) PDF",
        value: "Proper Parking Sequence"
    },
    {
        name: "Proper Parking Sequence (PPS) CSV",
        value: "ProperParkingSequenceCsv"
    }
];
const soldierCategoryOptions = [
    {
        name: "Scorecard Weekly PDF",
        value: "Scorecard"
    },
    {
        name: "Scorecard Weekly CSV",
        value: "ScorecardCsv"
    },
    {
        name: "Mentor Daily CSV",
        value: "Mentor"
    },
    {
        name: "Netradyne Daily CSV",
        value: "Netradyne"
    },
    {
        name: "Engine Off Compliance XLSX",
        value: "Engine Off Compliance"
    },
    {
        name: "Customer Delivery Feedback (CDF) PDF",
        value: "Customer Delivery Feedback"
    },
    {
        name: "Proper Parking Sequence (PPS) PDF",
        value: "Proper Parking Sequence",
    },
    {
        name: "Proper Parking Sequence (PPS) CSV",
        value: "ProperParkingSequenceCsv"
    }
];

let loadTimeout;

function ScorecardDocuments()
{
    const {api} = useAuth();
    const [station,] = useSelectedStation();
    const [state, dispatch] = useContext(ScorecardContext);
    const [status, setStatus] = useState(DOCUMENT_STATUS.FETCHING);
    const {register, getValues, handleSubmit} = useForm();
    const LoaderRef = React.useRef(null);

    
    const entry = useIntersection(LoaderRef, {
        threshold: 0.10
    })
    const isLoadingVisible = !!entry?.isIntersecting

    useEffect(() => {
        if (isLoadingVisible && state.allDocuments && state.allDocuments.length > 0 && state.documents.length < state.allDocuments.length) {
            state.documents = state.allDocuments.slice(0, state.documents.length+20);
        }
    }, [isLoadingVisible])


    
    const fetchDocuments = () => {
        setStatus(DOCUMENT_STATUS.FETCHING);

        const {category, search} = getValues();

        api.post('/api/lazy/manage/data', {
            actions: {
                response: {
                    ReportSourceDocument: {
                        findBy: {
                            get: 'documents',
                            criteria: {
                                company: getCompanyId(),
                                station: station,
                                isArchive: false,
                                category: category?.length ? category : ((getCompanyId() == 85)?soldierCategoryOptions.map(o => o.value):categoryOptions.map(o => o.value)),
                            },
                            matching: search ? {
                                metadata: {},
                                "me.key": "reference",
                                "me.value": {
                                    contains: search
                                }
                            } : {},
                            orderBy: {
                                createdAt: 'DESC'
                            },
                            includes: ReportSourceDocumentSchemaInclude,
                        }
                    }
                }
            }
        }).then(async (response) => {
            if (response?.data?.data?.documents) {
                state.documents = response.data.data.documents.slice(0, 20);
                state.allDocuments = response.data.data.documents;
            }
        }).then(
            response => setStatus(DOCUMENT_STATUS.READY)
        );
    };

    const createDocuments = uploads => {
        return api.post('/api/lazy/manage/data', {
            actions: {
                response: {
                    ReportSourceDocument: {
                        custom: {
                            functionName: "createReportSourceDocument",
                            get: "createReportSourceDocument",
                            criteria: {
                                company: getCompanyId(),
                                station: station,
                                documents: uploads.reduce((all, next) => {
                                    all[next.name] = {
                                        name: next.name,
                                        url: next.ObjectURL,
                                        category: next?.otherRequestedDate?.category || 'Scorecard',
                                        date: next?.otherRequestedDate?.date || "",
                                        password: next?.otherRequestedDate?.password || "",
                                        send_invitation: next?.otherRequestedDate?.send_invitation || "",
                                        send_notification: next?.otherRequestedDate?.send_notification || "",
                                        notification_message: next?.otherRequestedDate?.notification_message || "",
                                        source: 'manual',
                                    };
                                    return all;
                                }, {}),
                            },
                        }
                    },
                },
            }
        }).then(
            result => result && toast({
                type: 'success',
                title: `${uploads.length} ${uploads.length > 1 ? 'documents' : 'document'} uploaded`,
                timeout: 4000
            }) && result
        );
    }

    const reprocessDocument = document => {
        toast({
            type: 'info',
            title: 'Moving document to be reprocessed...',
            timeout: 4000
        });

        return api.post('/api/lazy/manage/data', {
            actions: {
                update: {
                    ReportSourceDocument: {
                        [document.name]: {
                            findBy: {
                                id: document.id,
                            },
                            updateRecord: {
                                status: 1 // moving it back to new triggers listener to queue again
                            }
                        }
                    }
                }
            }
        }).then(
            response => {
                toast({
                    type: 'success',
                    title: `Document ${document.name} is back in the queue`,
                    timeout: 4000
                });

                state.documents = (state.documents.map(d => {
                    if (d.id === document.id)
                        d.status = 2;

                    return d;
                }));
                state.allDocuments = (state.allDocuments.map(d => {
                    if (d.id === document.id)
                        d.status = 2;

                    return d;
                }));
            }
        );
    }

    const handleUpload = data => {
        setStatus(DOCUMENT_STATUS.FETCHING);

        toast({
            type: 'info',
            timeout: 8000,
            title: 'Uploading your documents...'
        });

        let formData = new FormData();
        formData.append('category', data.category);
        if(data.date){
            formData.append("date", data.date);
        }
        if(data.password){
            formData.append("password", data.password);
        }
        if(data.send_invitation){
            formData.append("send_invitation", data.send_invitation);
        }
        if(data.send_notification){
            formData.append("send_notification", data.send_notification);
        }
        if(data.notification_message){
            formData.append("notification_message", data.notification_message);
        }
        Array.from(data.documents)
            .forEach(file => formData.append('image[]', file));

        return upload(formData).catch(err => console.log(err));
    }

    const showUploadDialog = () => {
        return dialogPromise((success, cancel)=>
            <UploadDocumentDialog
                success={success}
                cancel={cancel}
            />
        ).then(
            result => result && handleUpload(result)
        ).then(
            result => {
                if (result === false) {
                    setStatus(DOCUMENT_STATUS.READY);
                    return;
                }

                if (!result) {
                    setStatus(DOCUMENT_STATUS.READY);
                    toast({
                        type: 'error',
                        title: 'Oops...',
                        content: 'Something happen while uploading your document. Please try again later.',
                        useIcon: true,
                        useClose: true,
                        timeout: 8000
                    });
                    return;
                }

                let uploads = result?.data;

                if (!Array.isArray(uploads))
                    uploads = [uploads];

                return createDocuments(uploads);
            }
        ).then(
            result => {
                if(result){
                    dispatch({
                        type: 'NEW_DOCUMENT',
                        payload: result.data.data.createReportSourceDocument
                    });
                }
            }
        ).then(
            response => setStatus(DOCUMENT_STATUS.READY)
        );
    }

    const loadDocuments = () => {
        setStatus(DOCUMENT_STATUS.FETCHING);
        clearTimeout(loadTimeout);
        loadTimeout = setTimeout(() => {
            fetchDocuments();
        }, 1000);
    }

    useEffect(() => {
        loadDocuments();
    }, [station]);

    useEffect(() => {
        const es = new ES("document", true);

        es.onmessage = data => {
            dispatch({
                type: "UPDATE_DOCUMENT_STATUS",
                payload: data.data,
            });
        }
    }, [station]);

    return <section>
        <Title>Scorecard Documents</Title>
        <Spacer bottom={3}/>
        <Text>Store all your scorecards in one place. Upload new scorecards for up-to-date reports.</Text>
        <Spacer bottom={5}/>
        <form onSubmit={handleSubmit(loadDocuments)}>
            <StationSelector/>
            <Spacer inline right={5}/>
            <CustomDropdown
                ref={register}
                name='category'
                label='Category'
                options={((getCompanyId() == 85)?soldierCategoryOptions:categoryOptions)}
                multiple={true}
                onChange={() => handleSubmit(loadDocuments)()}
            />
            <Spacer inline right={5}/>
            <Input
                ref={register}
                name='search'
                label='Reference'
                onChange={() => handleSubmit(loadDocuments)()}
            >
                <Icon.Search/>
            </Input>
        </form>
        <Spacer bottom={3}/>
        <Table>
            <TableHeader
                headers={[
                    {label: 'Category', width: '10%'},
                    {label: 'Name', width: '33%'},
                    {label: 'Ref.', width: '10%'},
                    {label: 'Source', width: '8%'},
                    {label: 'Status', width: '14%'},
                    {label: 'Created', width: '18%'},
                    {label: '', width: '7%'},
                ]}
            />
            {status === DOCUMENT_STATUS.FETCHING && (
                <TableRow>
                    <TableData width='100%'>
                        <Skeleton width={400} height={20}/>
                    </TableData>
                </TableRow>
            )}
            {status === DOCUMENT_STATUS.READY && state.documents.length === 0 && (
                <TableRow>
                    <TableData width='100%' center>
                        <div style={{textAlign: 'center'}}>
                            <Empty/>
                            <ThirdTitle>Upload your first document</ThirdTitle>
                            <Spacer top={3} bottom={5}>
                                <Button type='primary' onClick={showUploadDialog} size='small'>Upload New Document</Button>
                            </Spacer>
                        </div>
                    </TableData>
                </TableRow>
            )}
            {state.documents.map((document, k) =>
                <TableRow key={k}>
                    <TableData width='10%'>
                        {document.category}
                    </TableData>
                    <TableData width='33%'>
                        {document.name}
                    </TableData>
                    <TableData width='10%'>
                        {document.metadata?.reference
                            ? document.metadata?.reference
                            : document.metadata?.week && document.metadata?.year
                                ? `W${document.metadata?.week}/${document.metadata?.year}`
                                : '–'}
                    </TableData>
                    <TableData width='8%'>
                        {document.source}
                    </TableData>
                    <TableData width='14%'>
                        <Tooltip content={DOCUMENT_ERROR_KEY[document.status]}>
                            <Status
                                current={document.status}
                                config={DOCUMENT_STATUS_CONFIG}
                            >
                                {DOCUMENT_STATUS_MAP[document.status]}
                            </Status>
                        </Tooltip>
                        {document.status >= 5 && document?.metadata?.errorLogFile
                            ? (
                                <Spacer left={2} inline>
                                    { moment.utc(document.createdAt.timestamp * 1000).local().diff(moment('24-08-2023', 'DD-MM-YYYY').local(), "seconds") < 0 && document.source == 'RouteAssist' ?
                                        <Tooltip content={'In order to protect your data, we have removed the file storage links to this file. This will not affect the data we have already imported.'}>
                                            <Icon.Download color={Theme.colors.neutrals.silver} size="16px" />
                                        </Tooltip>
                                        :
                                        <Tooltip content={'Download report'}>
                                            <Link target='_blank' href={document.metadata.errorLogFile}>
                                                <Icon.Download color={Theme.colors.warnings.text} size="16px" />
                                            </Link>
                                        </Tooltip>
                                    }
                                </Spacer>
                            ) : null}
                    </TableData>
                    <TableData width='18%'>
                        {fullDateFromUTC(document.createdAt.timestamp * 1000)}
                    </TableData>
                    <TableData width='7%' style={{justifyContent: 'flex-end'}}>
                        { moment.utc(document.createdAt.timestamp * 1000).local().diff(moment('24-08-2023', 'DD-MM-YYYY').local(), "seconds") < 0 && document.source == 'RouteAssist' ?
                            <Tooltip content={'In order to protect your data, we have removed the file storage links to this file. This will not affect the data we have already imported.'}>
                                <Icon.Download color={Theme.colors.neutrals.silver} size="16px" />
                            </Tooltip>
                            :
                            <TableButton as='a' target='_blank' href={document.url}>
                                <Icon.Download size='16px'/>
                            </TableButton>
                        }
                        <Spacer right={3} inline/>
                        <Tooltip content='Reprocess' horizontalPosition='left' verticalPosition='center'>
                        { moment.utc(document.createdAt.timestamp * 1000).local().diff(moment('24-08-2023', 'DD-MM-YYYY').local(), "seconds") < 0 && document.source == 'RouteAssist' ?
                            <Tooltip content={'In order to protect your data, we have removed the file storage links to this file. This will not affect the data we have already imported.'}>
                                <Icon.Refresh color={Theme.colors.neutrals.silver} size="20px" />
                            </Tooltip>
                            :
                            <TableButton onClick={() => reprocessDocument(document)}>
                                <Icon.Refresh size='20px'/>
                            </TableButton>
                        }
                        </Tooltip>
                    </TableData>
                </TableRow>
            )}
            <TableRow ref={LoaderRef}>
                {status === DOCUMENT_STATUS.READY && state.allDocuments && state.allDocuments.length > 0 && state.documents.length < state.allDocuments.length && <Loading style={{ width: '40px', margin: '12px auto', display: 'block' }} />}
            </TableRow>
            <TableFooter sticky>
                {status === DOCUMENT_STATUS.FETCHING && (
                    <Skeleton width={200} height={24}/>
                )}
                {status !== DOCUMENT_STATUS.FETCHING && (
                    <ThirdTitle>
                        Total: {state.documents.length} of {state.allDocuments.length} document{state.allDocuments.length > 1 && 's'}
                    </ThirdTitle>
                )}
                <Button
                    type='primary'
                    onClick={showUploadDialog}
                    size='small'
                >Upload New Document</Button>
            </TableFooter>
        </Table>
    </section>
}

const UploadDocumentDialog = ({
    success = ()=>{},
    cancel = ()=>{},
}) => {
    const {register, errors, watch, handleSubmit} = useForm({
        defaultValues: {
            category: 'Scorecard',
        }
    });
    const [station] = useSelectedStation();
    const {category, date, password} = watch();
    const [sendNotification, setSendNotification] = useState(false);
    const [isNotValid, setIsNotValid] = useState(false);

    useEffect(() => {
        setSendNotification(false);
        setIsNotValid(false);
    }, [category]);

    const onSubmit = async formData => {

        let isUpload = false;
        let categoryExtension = (category === 'Scorecard' || category === 'Customer Delivery Feedback' || category === 'Proper Parking Sequence') ? 'pdf' : (category === 'Engine Off Compliance') ? 'xlsx' : "csv";

        for(let i = 0; i < formData.documents.length; i++) {
            let file = formData.documents[i];
            let extension = file.name.match(patternGetNameExtension)[1];
            if(categoryExtension === extension) {
                if (Array.isArray(mimeTypes[extension])) {
                    if (mimeTypes[extension].includes(file.type)) {
                        isUpload = true;
                    }
                } else {
                    isUpload = mimeTypes[extension] === file.type || false;
                }
            }
        }
        
        if(isUpload === true) {
            setIsNotValid(false);
            success(formData);
        } else {
            setIsNotValid(true);
        }
    };
    return (
        <Modal
            title={'Upload New Document'}
            width="400px"
            visible={true}
            setVisible={cancel}
        >
            <form as="form" name='document'>
                <CustomDropdown
                    ref={register}
                    name='category'
                    label='Document Type'
                    options={(getCompanyId() == 85)?[
                        {
                            name: "Scorecard Weekly PDF",
                            value: "Scorecard"
                        },
                        {
                            name: "Scorecard Weekly CSV",
                            value: "ScorecardCsv"
                        },
                        {
                            name: "Mentor Daily CSV",
                            value: "Mentor"
                        },
                        {
                            name: "Netradyne Daily CSV",
                            value: "Netradyne"
                        },
                        {
                            name: "Engine Off Compliance XLSX",
                            value: "Engine Off Compliance"
                        },
                        {
                            name: "Customer Delivery Feedback (CDF) PDF",
                            value: "Customer Delivery Feedback"
                        },
                        {
                            name: "Proper Parking Sequence (PPS) PDF",
                            value: "Proper Parking Sequence"
                        },
                        {
                            name: "Proper Parking Sequence (PPS) CSV",
                            value: "ProperParkingSequenceCsv"
                        }
                    ]:[
                        {
                            name: "Scorecard Weekly PDF",
                            value: "Scorecard"
                        },
                        {
                            name: "Mentor Daily CSV",
                            value: "Mentor"
                        },
                        {
                            name: "Netradyne Daily CSV",
                            value: "Netradyne"
                        },
                        {
                            name: "Engine Off Compliance XLSX",
                            value: "Engine Off Compliance"
                        },
                        {
                            name: "Customer Delivery Feedback (CDF) PDF",
                            value: "Customer Delivery Feedback"
                        },
                        {
                            name: "Proper Parking Sequence (PPS) PDF",
                            value: "Proper Parking Sequence"
                        },
                        {
                            name: "Proper Parking Sequence (PPS) CSV",
                            value: "ProperParkingSequenceCsv"
                        }
                    ]}
                />
                <Spacer bottom={3} />
                <DropUploader
                    name='documents'
                    label={category === 'Scorecard' ? 'Documents' : 'Document'}
                    multiple={category === 'Scorecard'}
                    ref={register({
                        required: 'Please select file'
                    })}
                    accept={category === 'Scorecard' || category === 'Customer Delivery Feedback' || category === 'Proper Parking Sequence' ? 'application/pdf' : category === 'Engine Off Compliance' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : "text/csv"}
                    Required
                    error={errors.document && errors.document.message}
                    valid={!errors.document && document && document.length > 0}
                />
                <Spacer inline left={2}>
                    <Tooltip
                        verticalPosition={'center'}
                        horizontalPosition={'right'}
                        content={<Text>{category === 'Scorecard' || category === 'Customer Delivery Feedback' ? 'Only PDF documents allowed.' :  category === 'Engine Off Compliance' ?  "Only XSLX document allowed." : "Only CSV document allowed."}</Text>}
                    >
                        <Question>?</Question>
                    </Tooltip>
                </Spacer>
                <Text style={{ color: "red" }}>{isNotValid && (category === 'Scorecard' || category === 'Customer Delivery Feedback' || category === 'Proper Parking Sequence') ? 'Only PDF documents allowed.' : (isNotValid && category === 'Engine Off Compliance') ?  "Only XSLX document allowed." : isNotValid && "Only CSV document allowed."}</Text>
                {(category === 'Scorecard' || category === 'Engine Off Compliance' || category === 'Proper Parking Sequence') && (
                    <>
                        <Spacer top={5} />
                        <CheckBox
                            name='send_notification'
                            ref={register}
                            options={[
                                {value:'true',label:'Send Workbot notification to listed drivers upon successful import?'},
                            ]}
                            onClick={(e) => {console.log(e.target.checked); setSendNotification(e.target.checked)}}
                        />
                        {(sendNotification == true) && 
                        (<><Spacer top={5} />
                            <Input
                                name='notification_message'
                                label='Message'
                                size='medium'
                                ref={register({ required: true })}
                                style={{ height: 76 }}
                                as='textarea'
                                defaultValue={'Hey [DriverName], we just updated your Amazon scores. Check out your Scorecard for the latest!'}
                            />
                            <Spacer inline left={2}>
                                <Tooltip
                                    verticalPosition={'center'}
                                    horizontalPosition={'right'}
                                    content={<Text>{'[DriverName] as Scorecard driver name'}</Text>}
                                >
                                    <Question>?</Question>
                                </Tooltip>
                            </Spacer>
                        </>)}
                    </>
                )}

               {(category === 'Mentor' || category === 'Netradyne')
                    ? (
                        <Spacer top={3}>
                            <DatePicker
                                ref={register({ required: 'Please select report date.' })}
                                size='small'
                                name='date'
                                label='Date'
                                Required
                                error={errors.date && errors.date.message}
                                valid={!errors.date && date && date.length > 0}
                            />
                        </Spacer>
                    ) : null}
                <Spacer bottom={5} />
                <Button type='primary' onClick={handleSubmit(onSubmit)}>Save</Button>
            </form>
        </Modal>
    );
 }