import React from "react";
import {Body, Content, Navigator} from "../../components/Menus/Navigator";
import {Switch,Route,useHistory} from "react-router-dom";
import { STATUS } from "./reducer";
import Loading from "../../components/Loading";
import { useDashboardState } from './useDashboardState';
import PanelsList from './components/panelsList';
import DashboardEmptyContent from './components/dashboardEmptyContent';
import DashboardContent from './components/dashboardContent';
import WithDragAndDrop from "../../components/dragHandler";

const CHART_OPTIONS = {createChart:'Create Widget',chartLibrary:'Widget Library'};
//const REPORT_OPTIONS = {createChart:'Create Report',chartLibrary:'Report Library'};

const DashboardPageRoot = WithDragAndDrop(() => {
    let history = useHistory();
    const [{ data, slug, status, initPanelsList }, { dispatch, updatePanel, deletePanel, addPanel, ...actions }] = useDashboardState(history,'dashboard','widget');

    const handleUpdatePanel = async (panel,params) => {
        const result = await updatePanel(panel,params);

        if(result){
            const route = encodeURI(result.name).replace('/','__');
            history.push(`/dashboard/${slug}/${route}`);
        }
    };

    const handleDeletePanel = async (i) => {
        if(await deletePanel(data[i])){
            const panelToRedirect = data[i - 1] || data[i];

            if(panelToRedirect){
                const route = encodeURI(panelToRedirect.name).replace('/','__');
                history.push(`/dashboard/${slug}/${route}`);
            } else {
                history.push(`/dashboard/${slug}`);
            }
        }
    };

    const handleAddPanel = async (params) => {
        const result = await addPanel(params);
        if(result){
            const route = encodeURI(result.name).replace('/','__');
            history.push(`/dashboard/${slug}/${route}`);
        }
    };


    if(status === STATUS.PENDING) return <Loading style={{height: 80,margin:'50px auto',display:'block'}} />

    return (
        <Body>
            <Navigator>
                <PanelsList
                    title={'Widgets'}
                    slug={slug}
                    panels={data}
                    onAddPanel={handleAddPanel}
                    onDeletePanel={handleDeletePanel}
                    onUpdatePanel={handleUpdatePanel}
                    dispatch={dispatch}
                    updateWidgetList={initPanelsList}
                />
            </Navigator>
            <Content style={{padding:0}} className={'customDashBoardContent'}>
                <Switch>
                    <Route exact path="/dashboard" children={<DashboardEmptyContent onAddPanel={handleAddPanel} />} />
                    <Route path={`/dashboard/${slug}/:slug`} children={(
                        <DashboardContent
                            data={data}
                            dispatch={dispatch}
                            onAddChart={actions.addChart}
                            onCreateChart={actions.createChart}
                            onUpdateCardConfig={actions.updateCardConfig}
                            onEditChart={actions.updateTempChart}
                            onDeleteChart={actions.deleteChart}
                            options={CHART_OPTIONS}
                        />
                    )} />
                </Switch>
            </Content>
        </Body>
    )
});

export default DashboardPageRoot
