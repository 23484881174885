import axios from "axios";
import {getCompanyId,getUserId} from "../../Auth";
import {engine} from "../../Utilities/index";

const CancelToken = axios.CancelToken;
let canceler;


const API = {
    current: undefined,
    call(params, endpoint = '/api/lazy/manage/data') {
        API.current = engine().post(
            endpoint,
            params,
            {
                cancelToken: new CancelToken(function executor(c){
                    canceler = c;
                })
            }
        ).finally(
            () => API.current = undefined
        );

        return API.current;
    },
    cancel() {
        if ( API.current )
            return canceler();
        else
            return false;
    },
    fetchPanels({pageReference}){
      return API.call({
        actions: {
            response: {
                DashPanel: {
                    findBy: {
                        get: "panel",
                        criteria: {
                            company: getCompanyId(),
                            user: getUserId(),
                            pageReference:pageReference
                        },
                        orderBy: {
                            panelOrder: 'DESC'
                        },
                        includes: {
                            "0":"id",
                            "1":"name",
                            "charts":{
                                "0":"id",
                                "1":"title",
                                "2":"chartPosition",
                                "3":"chartQuery",
                                "4":"chartConfig",
                                "5":"cardConfig",
                                "6":"chartDetailQuery",
                                "7":"chartSchema",
                                "8":"isScorecardAMZ"
                            }
                        }
                    }
                }
            }
        }
      })
    },
    deletePanel({id}){
        return API.call({
            actions: {
                delete: {
                    DashPanel: {
                        DashPanel1: {
                            findOneBy: {
                                id:id,
                                company:getCompanyId()
                            },
                            isHardDelete:true
                        }
                    }
                }
            }
        });
    },
    addPanel(params = {}){
        params.company = getCompanyId();
        params.user = getUserId();
        if(!params.panelOrder) params.panelOrder = 1;

        if(params.id){
            return API.call({
                actions: {
                    update: {
                        DashPanel: {
                            DashPanel1: {
                                findBy: {
                                    id:params.id
                                },
                                updateRecord: {
                                    name: params.name
                                }
                            }
                        }
                    },
                    response: {
                        findLastInserted: {
                            DashPanel1: {
                                "0":"id",
                                "1":"name"
                            }
                        }
                    }
                }
            });
        }

        return API.call({
            actions: {
                create: {
                    DashPanel: {
                        DashPanel1: {
                            ...params
                        }
                    }
                },
                response: {
                    findLastInserted: {
                        DashPanel1: {
                            "0":"id",
                            "1":"name"
                        }
                    }
                }
            }
        });
    },
    addChart(params = {}){
        params.company = getCompanyId();
        params.user = getUserId();

        delete params.id

        if(!params.chartDetailQuery){
            params.chartDetailQuery = {}
        }

        if(typeof params.cardConfig === 'object') params.cardConfig = JSON.stringify(params.cardConfig);
        if(typeof params.chartConfig === 'object') params.chartConfig = JSON.stringify(params.chartConfig);
        if(typeof params.chartQuery === 'object') params.chartQuery = JSON.stringify(params.chartQuery);
        if(typeof params.chartDetailQuery === 'object') params.chartDetailQuery = JSON.stringify(params.chartDetailQuery);

        return API.call({
            actions: {
                create: {
                    DashChart: {
                        DashChart1: {
                            ...params
                        }
                    }
                },
                response: {
                    findLastInserted: {
                        DashChart1: {
                            "0":"id"
                        }
                    }
                }
            }
        });
    },
    updateCardConfig({id,config}){
        return API.call({
            actions: {
                update: {
                    DashChart: {
                        DashChart1: {
                            findBy: {
                                id:id,
                                company:getCompanyId()
                            },
                            updateRecord: {
                                cardConfig: JSON.stringify(config)
                            }
                        }
                    }
                }
            }
        });
    },
    updateChart({id,query,chartDetailQuery,chartConfig,title}){
        return API.call({
            actions: {
                update: {
                    DashChart: {
                        DashChart1: {
                            findBy: {
                                id:id,
                                company:getCompanyId()
                            },
                            updateRecord: {
                                chartQuery: JSON.stringify(query),
                                chartDetailQuery: JSON.stringify(chartDetailQuery),
                                chartConfig:JSON.stringify(chartConfig),
                                title:title
                            }
                        }
                    }
                },
                response: {
                    findLastInserted: {
                        DashChart1: {
                            "0":"id",
                            "1":"title",
                            "2":"chartPosition",
                            "3":"chartQuery",
                            "4":"chartConfig",
                            "5":"cardConfig",
                            "6":"chartSchema",
                        }
                    }
                }
            }
        });
    },
    deleteChart({id}){
        return API.call({
            actions: {
                delete: {
                    DashChart: {
                        DashChart1: {
                            findOneBy: {
                                id:id,
                                company:getCompanyId()
                            },
                            isHardDelete:true
                        }
                    }
                }
            }
        });
    },
    getChartLibraryCategories(){
        return API.call({
            actions: {
                response: {
                    DashChartLibraryCategory: {
                        findAll: {
                            get: "categories",
                            includes: {
                                "0":"id",
                                "1":"name",
                                "charts":{
                                    "1":"title",
                                    "2":"chartPosition",
                                    "3":"chartQuery",
                                    "4":"chartConfig",
                                    "5":"cardConfig",
                                    "6":"chartDetailQuery",
                                    "7":"isScorecardAMZ"
                                }
                            }
                        }
                    }
                }
            }
        })
    },
    getAttendanceSetting(){
        return API.call({
            actions: {
                response: {
                    AttendanceSettings: {
                        findOneBy: {
                            criteria: {
                                company: getCompanyId(),
                                isArchive: false
                            },
                            get: "attendanceSettings",
                            includes: {
                                "0": "id",
                                "1": "minLevel1",
                                "2": "maxLevel1",
                                "3": "colorLevel1",
                                "4": "minLevel2",
                                "5": "maxLevel2",
                                "6": "colorLevel2",
                                "7": "minLevel3",
                                "8": "maxLevel3",
                                "9": "colorLevel3",
                                "10": "reportDays"
                            }
                        }
                    }
                }
            }
        })
    }
}

export default API;